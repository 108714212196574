import { UploadFile } from 'antd/es/upload/interface';
import { getImageLink, identity } from 'utils';

export const getAngelInvestorFile = (csvFileLink?: string) => {
  let defaultFileList: UploadFile[] = [];
  if (identity.isTruthyString(csvFileLink)) {
    const url = getImageLink(csvFileLink!);
    const fileName = identity.isTruthyString(url)
      ? url.split('/').at(-1)!.replace('.pdf', '')
      : 'Acceptance letter';
    defaultFileList = [{
      uid: '-1',
      name: fileName,
      status: 'done',
      url,
      thumbUrl: url,
    }];
  }

  return defaultFileList;
};

export default null;
