import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import {
  getOffset,
  getRowKey,
  isEitherAdmin,
  isAngelInvestor,
  useTableHook,
  identity,
} from 'utils';
import { AIWalletOverviewProps } from 'types/ai_wallet';
import { composeAngelInvestorDetailsUrl } from 'routes/routeComposers';
import AngelInvestorWallet from 'components/components/AngelInvestorWallet';
import {
  Breadcrumb,
  RenderIf,
  Table,
} from '../components';
import { aiWalletOverviewColumns } from '../utils/constants/columns';

import './AIWalletOverview.scss';

const AIWalletOverview = (props: AIWalletOverviewProps) => {
  const {
    count,
    isLoading,
    angelInvestorId,
    userType,
    wallet,
    walletTransactions,
    isRowTableLayout,
    actions: { getAIWallet, setTableLayout },
  } = props;

  const params: { id?: string } = useParams();
  const { id } = params;

  const {
    state,
    setLimit,
    setCurrentPage,
  } = useTableHook();
  const { limit, currentPage } = state;

  useEffect(() => {
    if (!isRowTableLayout) {
      setTableLayout({ isRowTableLayout: true });
    }
  }, [isRowTableLayout]);

  useEffect(() => {
    if (!isRowTableLayout) {
      setTableLayout({ isRowTableLayout: true });
    }
  }, [isRowTableLayout]);

  useEffect(() => {
    if (isRowTableLayout) {
      if (isAngelInvestor(userType)) {
        getAIWallet({ angel_investor_id: angelInvestorId, limit, offset: getOffset(limit, currentPage) });
      } else if (isEitherAdmin(userType)) {
        getAIWallet({ angel_investor_id: id, limit, offset: getOffset(limit, currentPage) });
      }
    }
  }, [angelInvestorId, limit, currentPage, isRowTableLayout]);

  return (
    <div className="content-container">
      <div className="wallet-container">
        <RenderIf condition={isEitherAdmin(userType) && identity.isTruthyString(id)}>
          <Breadcrumb navigateTo={composeAngelInvestorDetailsUrl(id!)} routeName="Angel Investor Details" />
        </RenderIf>
        <AngelInvestorWallet wallet={wallet} angelInvestorId={id} />
        <div className="table">
          <Table
            isLoading={isLoading}
            dataSource={walletTransactions}
            columns={aiWalletOverviewColumns}
            pagination={false}
            setLimit={setLimit}
            setCurrentPage={setCurrentPage}
            showCustomPagination
            limit={limit}
            total={count}
            currentPage={currentPage}
            rowKey={getRowKey(walletTransactions, 'transaction', 'id')}
          />
        </div>
      </div>
    </div>
  );
}

export default AIWalletOverview;
