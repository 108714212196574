import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { selectServiceRequestDocuments, selectServiceRequestId } from 'redux/selectors/request';
import { getServiceRequestDocuments } from 'redux/actions/request';
import { StateType } from 'types';

const mapStateToProps = (state: StateType) => ({
  serviceRequestId: selectServiceRequestId(state),
  requestDocuments: selectServiceRequestDocuments(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getServiceRequestDocuments,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
