import { IdPayload } from '../../../types/reduxTypes/ActionTypes';
import {
  SetEventFilterPayload,
  SetEventsPayload,
  SetAngelInvestorMemberByIdPayload,
} from '../../../types/reduxTypes/ActionTypes/AngelInvestorMemberActionTypes';

export const types = {
  GET_PARTNER_BY_ID: 'GET_PARTNER_BY_ID',
  GET_ANGEL_INVESTOR_MEMBER_BY_ID: 'GET_ANGEL_INVESTOR_MEMBER_BY_ID',
  SET_ANGEL_INVESTOR_MEMBER_BY_ID: 'SET_ANGEL_INVESTOR_MEMBER_BY_ID',
  GET_PAST_EVENTS: 'GET_PAST_EVENTS',
  SET_PAST_EVENTS: 'SET_PAST_EVENTS',
  GET_UPCOMING_EVENTS: 'GET_UPCOMING_EVENTS',
  SET_UPCOMING_EVENTS: 'SET_UPCOMING_EVENTS',
  SET_EVENT_FILTER_TYPE: 'SET_EVENT_FILTER_TYPE',
  RESET_ANGEL_INVESTOR_MEMBER_DETAILS: 'RESET_ANGEL_INVESTOR_MEMBER_DETAILS',
};

export const resetAngelInvestorMemberDetails = (payload: object = {}) => ({
  type: types.RESET_ANGEL_INVESTOR_MEMBER_DETAILS,
  payload,
});

export const setEventFilterType = (payload: SetEventFilterPayload) => ({
  type: types.SET_EVENT_FILTER_TYPE,
  payload,
});

export const getAngelInvestorMemberById = (payload: IdPayload) => ({
  type: types.GET_ANGEL_INVESTOR_MEMBER_BY_ID,
  payload,
});

export const setAngelInvestorMemberById = (payload: SetAngelInvestorMemberByIdPayload) => ({
  type: types.SET_ANGEL_INVESTOR_MEMBER_BY_ID,
  payload,
});

export const getPastEvents = (payload: IdPayload) => ({
  type: types.GET_PAST_EVENTS,
  payload,
});

export const setPastEvents = (payload: SetEventsPayload) => ({
  type: types.SET_PAST_EVENTS,
  payload,
});

export const getUpcomingEvents = (payload: IdPayload) => ({
  type: types.GET_UPCOMING_EVENTS,
  payload,
});

export const setUpcomingEvents = (payload: SetEventsPayload) => ({
  type: types.SET_UPCOMING_EVENTS,
  payload,
});

export const getPartnerById = (payload: IdPayload) => ({
  type: types.GET_PARTNER_BY_ID,
  payload,
});
