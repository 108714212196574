import React, { useEffect, useMemo } from 'react';
import { Button } from 'antd';

import { CloseIcon } from 'assets/icons';
import { StartupType } from 'types/startups';
import { CountryType } from 'packages/country_repository';
import { PeopleDropdownFiltersProps } from 'types/people';
import { Input, RenderIf } from 'components/components';
import { identity, isAngelInvestor, isStartup } from 'utils';
import { usePeopleFiltersHook, genderValues } from '../../utils';

const PeopleDropdownFilters = (props: PeopleDropdownFiltersProps) => {
  const {
    setShowDropdown,
    nationalities,
    skills,
    roles,
    startups,
    activeFilters,
    userType,
  } = props;
  const {
    getStartups,
    getNationalities,
    getPeopleSkills,
    getPeopleRoles,
    setPeopleFilters,
  } = props.actions;

  const {
    state,
    initialState,
    setFilters,
    resetFilters,
    setNationalities,
    setGender,
    setRoles,
    setSkills,
    setStartups,
  } = usePeopleFiltersHook();

  useEffect(() => setFilters(activeFilters), [activeFilters]);

  const {
    startups: startupsState,
    nationalities: nationalitiesState,
    roles: rolesState,
    skills: skillsState,
    gender,
  } = state;

  const onClose = () => {
    setFilters(activeFilters);
    setShowDropdown(false);
  }

  const onClearAll = () => {
    resetFilters();
    setPeopleFilters({ data: initialState });
    onClose();
  }

  const onFilter = () => {
    setPeopleFilters({ data: state });
    onClose();
  }

  useEffect(() => {
    if (identity.isEmptyArray(startups)) {
      getStartups();
    }

    if (identity.isEmptyArray(nationalities)) {
      getNationalities();
    }

    if (identity.isEmptyArray(skills)) {
      getPeopleSkills();
    }

    if (identity.isEmptyArray(roles)) {
      getPeopleRoles();
    }
  }, []);

  const startupsOption = useMemo(() => startups.map(
    ({ name: label, id: value }: StartupType) => ({ label, value }),
  ), [startups]);

  const nationalitiesOptions = useMemo(() => nationalities.map(
    ({ id: value, name: label }: CountryType) => ({ label, value }),
  ), [nationalities]);

  return (
    <div className="filters-dropdown">
      <div className="filters-dropdown__title-container">
        <h3 className="filters-dropdown__title">Filters</h3>
        <Button className="filters-buttons--button" onClick={onClose}><CloseIcon /></Button>
      </div>
      <RenderIf condition={!isStartup(userType) && !isAngelInvestor(userType)}>
        <Input label="Startups" layout="vertical">
          <Input.Select
            showSearch
            options={startupsOption}
            value={startupsState}
            mode="multiple"
            placeholder="Select startups"
            maxTagCount="responsive"
            onChange={(data) => setStartups({ data })}
          />
        </Input>
        <div className="filters-dropdown--divider" />
      </RenderIf>
      <Input label="Nationalities" layout="vertical">
        <Input.Select
          showSearch
          options={nationalitiesOptions}
          value={nationalitiesState}
          mode="multiple"
          placeholder="Select nationality"
          maxTagCount="responsive"
          onChange={(data) => setNationalities({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <Input label="Roles" layout="vertical">
        <Input.Select
          showSearch
          options={roles}
          value={rolesState}
          mode="multiple"
          placeholder="Select role"
          maxTagCount="responsive"
          onChange={(data) => setRoles({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <RenderIf condition={!isAngelInvestor(userType)}>
        <Input label="Skills" layout="vertical">
          <Input.Select
            showSearch
            options={skills}
            value={skillsState}
            mode="multiple"
            placeholder="Select skill"
            maxTagCount="responsive"
            onChange={(data) => setSkills({ data })}
          />
        </Input>
      </RenderIf>
      <div className="filters-dropdown--divider" />
      <Input label="Gender" layout="vertical">
        <Input.Radio
          values={genderValues}
          value={gender}
          onChange={({ target: { value: data } }) => setGender({ data })}
        />
      </Input>
      <div className="filters-dropdown--divider" />
      <div className="filters-buttons">
        <Button className="secondary-button" onClick={onClearAll}>Clear all</Button>
        <Button className="primary-button" onClick={onFilter}>Apply</Button>
      </div>
    </div>
  );
}

export default PeopleDropdownFilters;
