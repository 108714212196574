import { all, spawn } from 'redux-saga/effects';
import { Saga } from 'redux-saga';

import authSagas from './auth';
import cohortSagas from './cohort';
import workSpaceSagas from './workspace';
import startupsSagas from './startups';
import angelInvestorsSagas from './angelInvestors';
import settingsOverviewSagas from './settingsOverview';
import memberDetailsSagas from './memberDetails';
import peopleSagas from './people';
import requestSagas from './request';
import serviceProvidersSagas from './serviceProviders';
import walletSagas from './wallet';
import aiWalletSagas from './ai_wallet';
import servicesSaga from './services';
import eventsSaga from './events';
import partnersSaga from './partners';
import dashboardSaga from './dashboard';
import notificationsSaga from './notifications';
import performanceSaga from './performance';
import userSagas from './users';
import angelInvestorMemberDetailsSagas from './angelInvestorMemberDetails';

const sagas: Saga[] = [
  authSagas,
  cohortSagas,
  workSpaceSagas,
  startupsSagas,
  angelInvestorsSagas,
  settingsOverviewSagas,
  memberDetailsSagas,
  peopleSagas,
  requestSagas,
  serviceProvidersSagas,
  walletSagas,
  aiWalletSagas,
  servicesSaga,
  eventsSaga,
  partnersSaga,
  dashboardSaga,
  notificationsSaga,
  performanceSaga,
  userSagas,
  angelInvestorMemberDetailsSagas,
];

export default function * rootSagas() {
  yield all(sagas.map(spawn));
}
