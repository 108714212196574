import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Button, Form } from 'antd';
import { RcFile } from 'antd/lib/upload';

import {
  identity,
  isAngelInvestor,
  DRAWERS,
  isEitherAdmin,
} from 'utils';
import {
  TitleWithActions,
  Input,
  Breadcrumb,
  EditProfileImage,
} from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { EditAngelInvestorProps } from 'types/angelInvestors';
import routes from 'routes';
import { composeAngelInvestorDetailsUrl } from 'routes/routeComposers';
import { getAngelInvestorFile, useEditAngelInvestorHook } from './utils';
import { ViewUploadButton } from './components';

import './EditAngelInvestor.scss';

const EditAngelInvestor = (props: EditAngelInvestorProps) => {
  const {
    selectedAngelInvestor,
    userAngelInvestorId,
    userType,
  } = props;
  const {
    getAngelInvestorById,
    updateAngelInvestorById,
    setDrawerBody,
    setIsDrawerOpen,
  } = props.actions;

  const [form] = Form.useForm();

  const params: { id?: string } = useParams();
  const { id } = params;
  const angelInvestorId = useMemo(
    () => (identity.isTruthyNumber(userAngelInvestorId) ? userAngelInvestorId : parseInt(id!, 10)),
    [id],
  );

  const { state, setAll, setField } = useEditAngelInvestorHook();

  const {
    currentADGMLicense,
    aDGMApprovedStatus,
    incentiveAgreementApprovedStatus,
    currentIncentiveAgreement,
    acceptanceLetter,
    acceptanceLetterApprovedStatus,
    status,
    imageLink,
    about,
    location,
    website,
  } = state;

  const waitingForApprovalIA = useMemo(() => [
    identity.isZero(incentiveAgreementApprovedStatus),
    identity.isTruthyString(currentIncentiveAgreement?.file),
  ].every(Boolean), [currentIncentiveAgreement, incentiveAgreementApprovedStatus]);

  const waitingForApprovalADGM = useMemo(() => [
    identity.isZero(aDGMApprovedStatus),
    identity.isTruthyString(currentADGMLicense?.aDGMLicenseUrl),
  ].every(Boolean), [aDGMApprovedStatus, currentADGMLicense]);

  const waitingForApprovalAL = useMemo(() => [
    identity.isZero(status.id),
    identity.isEmptyString(acceptanceLetter),
  ].some(Boolean), [status, acceptanceLetter]);

  useEffect(() => {
    getAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: false });
  }, [id]);

  useEffect(() => {
    setAll({ angelInvestor: selectedAngelInvestor });
  }, [selectedAngelInvestor]);

  useEffect(() => {
    form.setFieldsValue(state);
  }, [state]);

  const handleSubmit = () => {
    updateAngelInvestorById({ id: angelInvestorId, angelInvestor: state });
  }

  const beforeLogoUpload = (file: RcFile, field: 'imageLink') => {
    setField({ field, value: file as Blob });
    return false;
  }

  const beforeAcceptanceLetterUpload = (file: RcFile) => {
    setField({ field: 'acceptanceLetter', value: file as Blob });
    return false;
  }

  const handleOpenDrawer = (drawerBody: string) => {
    setIsDrawerOpen({ isDrawerOpen: true });
    setDrawerBody({ drawerBody });
  }

  return (
    <div className="edit-angelinvestor__container content-container">
      <Breadcrumb navigateTo={isAngelInvestor(userType) ? routes.companyProfileUrl : composeAngelInvestorDetailsUrl(angelInvestorId)} routeName="Angel investor Details" />
      <TitleWithActions title={selectedAngelInvestor?.name} />
      <Form
        onFinish={handleSubmit}
        onFinishFailed={scrollToFirstError}
        validateTrigger="onSubmit"
        form={form}
      >
        <div className="edit-angelinvestor__fields">
          <div className="edit-angelinvestor__section">
            <EditProfileImage imageLink={imageLink} beforeLogoUpload={beforeLogoUpload} />
          </div>
          <div className="edit-angelinvestor__section">
            <Input.Heading title="Angel investor Details" />
            <Input label="About">
              <Input.InputField.TextArea
                rows={3}
                value={about}
                required
                onChange={(e) => setField({ field: 'about', value: e.target.value })}
              />
            </Input>
            <Input label="Location">
              <Input.InputField
                type="text"
                value={location}
                required
                onChange={(e) => setField({ field: 'location', value: e.target.value })}
              />
            </Input>
            <Input label="Website">
              <Input.InputField
                type="text"
                value={website}
                onChange={(e) => setField({ field: 'website', value: e.target.value })}
              />
            </Input>
          </div>
          <div className="edit-angelinvestor__section">
            <Input.Heading title="Documents" />
            <Input label="Acceptance Letter">
              {waitingForApprovalAL || (isEitherAdmin(userType) && acceptanceLetterApprovedStatus !== 1)
                ? (
                  <p className="message">
                    {waitingForApprovalAL
                      ? 'Waiting for Acceptance Letter to be approved.'
                      : 'Waiting for angel investor to upload acceptance letter.'}
                  </p>
                )
                : (
                  <Input.DraggerUpload
                    defaultFileList={identity.isTruthyString(acceptanceLetter)
                      ? [...getAngelInvestorFile(acceptanceLetter!)] : []}
                    previewType="image"
                    beforeUpload={beforeAcceptanceLetterUpload}
                    accept=".pdf"
                    isRoundableButton
                    name={identity.isTruthyString(acceptanceLetter) ? 'acceptanceLetter' : ''}
                    allowChange={identity.isZero(acceptanceLetterApprovedStatus)
                        && identity.isTruthyString(acceptanceLetter)}
                  />
                )}
            </Input>
            <Input label="ADGM License">
              {waitingForApprovalADGM
                ? <p className="message">Waiting for ADGM license to be approved.</p>
                : (
                  <ViewUploadButton
                    userType={userType}
                    name="ADGM License"
                    onClick={() => handleOpenDrawer(DRAWERS.aIAdgmLicenseDetails)}
                    condition={aDGMApprovedStatus === 1 && identity.isTruthyString(currentADGMLicense?.aDGMLicenseUrl)}
                    status={aDGMApprovedStatus}
                  />
                )}
            </Input>
            <Input label="Incentive Agreement">
              {waitingForApprovalIA
                ? <p className="message">Waiting for Incentive Agreement to be approved.</p>
                : (
                  <ViewUploadButton
                    userType={userType}
                    name="Incentive Agreement"
                    onClick={() => handleOpenDrawer(DRAWERS.incentiveAgreementDetails)}
                    condition={incentiveAgreementApprovedStatus === 1
                      && identity.isTruthyString(currentIncentiveAgreement?.file)}
                    status={incentiveAgreementApprovedStatus}
                  />
                )}
            </Input>
          </div>
          <Button type="primary" htmlType="submit" className="primary-button">
            Update Angel investor
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditAngelInvestor;
