import React, { useEffect } from 'react'
import { Form } from 'antd';
import { RcFile } from 'antd/lib/upload';
import moment from 'moment';

import DrawerButton from 'components/components/Drawer/components/DrawerButton';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { arrayToString } from 'utils/helpers/stringFormatters';
import { CreateTeamMemberPayload, CreateTeamMemberType } from 'packages/people_repository';
import { AddTeamMemberFieldsCreatorParams } from 'types/people';
import { AddAIMemberDrawerProps } from 'types/teamMembers';
import { getLabelValueObject, identity } from 'utils';
import { formatDateDashYMDTimeHMS } from '../utils/constants/formatters';
import { FormBuilder } from '../components';
import { addMemberFields } from './utils/constants';

const AddAIMemberDrawer = (props: AddAIMemberDrawerProps) => {
  const [form] = Form.useForm();
  const { actions, angelInvestorId, nationalities } = props;

  useEffect(() => {
    if (identity.isEmptyArray(nationalities)) {
      actions.getNationalities();
    }
  }, [])

  const beforeUpload = (field: string, file: RcFile) => {
    form.setFieldValue(field, file as Blob);
    return false;
  }

  const nationalityOptions = nationalities?.map(
    (country) => getLabelValueObject(country.name, country.id),
  );

  const teamMemberFieldsCreateData: AddTeamMemberFieldsCreatorParams = {
    beforeHeadshotUpload: (file: RcFile) => beforeUpload('headshot', file),
    beforeEmirateIdUpload: (file: RcFile) => beforeUpload('emirates', file),
    beforeEmployeeContractUpload: (file: RcFile) => beforeUpload('employmentContract', file),
    beforeWorkPermitUpload: (file: RcFile) => beforeUpload('workPermitName', file),
    beforePassportUpload: (file: RcFile) => beforeUpload('passport', file),
    beforeVisaUpload: (file: RcFile) => beforeUpload('visa', file),
    nationalityOptions,
  };

  const onSubmit = (data: CreateTeamMemberType) => {
    const formData: CreateTeamMemberPayload = {
      ...data,
      dateOfBirth: moment(data?.dateOfBirth).utc().format(formatDateDashYMDTimeHMS),
      angelInvestorId,
      skills: arrayToString(data?.skills),
      hobbies: arrayToString(data?.hobbies),
      jobFunctions: arrayToString(data?.jobFunctions),
      permissions: arrayToString(data?.permissions),
    }
    actions.addAIMember(formData);
  }

  return (
    <Form
      className="form-standalone"
      onFinish={onSubmit}
      form={form}
      onFinishFailed={scrollToFirstError}
    >
      <FormBuilder
        fields={addMemberFields(teamMemberFieldsCreateData)}
        onSubmit={onSubmit}
        formFragment
      />
      <DrawerButton>Add member</DrawerButton>
    </Form>
  )
}

export default AddAIMemberDrawer;
