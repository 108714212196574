import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom';

import { isAngelInvestorDataMissing } from 'utils/helpers/checkAngelInvestorStatus';
import routes from 'routes';
import { AngelInvestorType } from 'types/auth';
import { AngelInvestorRouteProps } from 'types/routes/PrivateRouteProps';
import { NOTICE_LEVEL_TO_TEMPLATE_MAP } from 'utils/constants/noticeModal';
import ANGEL_INVESTOR_STATUS from 'utils/constants/angelInvestorStatusType';
import { identity } from 'utils';

const AngelInvestorRoute = (props: AngelInvestorRouteProps) => {
  const {
    user,
    component,
    exact,
    path,
    angelInvestor,
    isBlockedByNotice,
    noticeModalTemplate,
    actions,
    notice,
  } = props;

  useEffect(() => {
    if (!isBlockedByNotice || path === routes.createPerformanceReportUrl) {
      return;
    }

    if (!identity.isObjWithChildren(noticeModalTemplate)) {
      const noticeDetailsTemplate = {
        ...NOTICE_LEVEL_TO_TEMPLATE_MAP[notice?.level!],
        text: notice?.text,
      };
      actions.setNoticeModalTemplate({ data:  noticeDetailsTemplate });
    }

    actions.setIsNoticeModalOpen({ isOpen: true });
  }, [notice, path])

  if (isAngelInvestorDataMissing(angelInvestor!)) {
    return <Redirect to={routes.addMissingDetailsUrl} />
  }

  if ((user as AngelInvestorType).status === ANGEL_INVESTOR_STATUS.rejected) {
    return <Redirect to={routes.startupRejectedUrl} /> // have to change angelInvestorRejectedUrl
  }

  return <Route path={path} exact={exact} component={component} />
}

export default AngelInvestorRoute;
