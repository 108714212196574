import {
  ResetFormPayload,
  SetDestroyDrawerOnClosePayload,
  SetDrawerBodyPayload,
  SetIsDrawerFilterTableOpenPayload,
  SetIsDrawerOpenPayload,
  SetTableLayoutPayload,
  ServicesSpCountPayload,
} from 'types/reduxTypes/ActionTypes/WorkspaceTypes';
import {
  DataPayload,
  GetAllQueryPayload,
  IsLoadingPayload,
  IsOpenPayload,
} from 'types/reduxTypes/ActionTypes';
import { CountryType } from 'packages/country_repository';
import { StartupType } from 'types/startups';
import { NoticeModalConfig } from 'types/componentTypes';
import { GetSearchType, SearchType } from 'packages/search_repository/types';
import { PartnerType } from 'types/partners';
import { ParamsPayload } from 'packages/http_client';
import { ServiceProviderType } from 'packages/service_repository';

export const types = {
  SET_TABLE_LAYOUT: 'SET_TABLE_LAYOUT',
  SET_SEARCH_RESULT: 'SET_SEARCH_RESULT',
  GET_SEARCH_RESULT: 'GET_SEARCH_RESULT',
  SET_IS_DRAWER_LOADING: 'SET_IS_DRAWER_LOADING',
  SET_IS_DRAWER_OPEN: 'SET_IS_DRAWER_OPEN',
  SET_DESTROY_DRAWER_ON_CLOSE: 'SET_DESTROY_DRAWER_ON_CLOSE',
  SET_IS_DRAWER_FILTER_TABLE_OPEN: 'SET_IS_DRAWER_FILTER_TABLE_OPEN',
  SET_DRAWER_BODY: 'SET_DRAWER_BODY',
  RESET_FORM: 'RESET_FORM',
  GET_NATIONALITIES: 'GET_NATIONALITIES',
  SET_NATIONALITIES: 'SET_NATIONALITIES',
  GET_ALL_STARTUPS: 'GET_ALL_STARTUPS',
  SET_ALL_STARTUPS: 'SET_ALL_STARTUPS',
  SET_IS_NOTICE_MODAL_OPEN: 'SET_IS_NOTICE_MODAL_OPEN',
  SET_NOTICE_MODAL_TEMPLATE: 'SET_NOTICE_MODAL_TEMPLATE',
  GET_WORKSPACE_SERVICE_PROVIDERS: 'GET_WORKSPACE_SERVICE_PROVIDERS',
  GET_WORKSPACE_PARTNERS: 'GET_PARTNERS',
  SET_WORKSPACE_SERVICE_PROVIDERS: 'SET_WORKSPACE_SERVICE_PROVIDERS',
  SET_WORKSPACE_PARTNERS: 'SET_PARTNERS',
  RESET_WORKSPACE: 'RESET_WORKSPACE',
  GET_WORKSPACE_SERVICES_SP_COUNT: 'GET_WORKSPACE_SERVICES_SP_COUNT',
  SET_WORKSPACE_SERVICES_SP_COUNT: 'SET_WORKSPACE_SERVICES_SP_COUNT',
  SET_ALL_ANGEL_INVESTORS: 'SET_ALL_ANGEL_INVESTORS',
  GET_ALL_ANGEL_INVESTORS: 'GET_ALL_ANGEL_INVESTORS',
};

export const getServicesSPCount = (payload: object = {}) => ({
  type: types.GET_WORKSPACE_SERVICES_SP_COUNT,
  payload,
});

export const setServicesSPCount = (payload: ServicesSpCountPayload) => ({
  type: types.SET_WORKSPACE_SERVICES_SP_COUNT,
  payload,
});

export const resetWorkspace = (payload: object = {}) => ({
  type: types.RESET_WORKSPACE,
  payload,
});

export const setTableLayout = (payload: SetTableLayoutPayload) => ({
  type: types.SET_TABLE_LAYOUT,
  payload,
});

export const setNavigationSearchResults = (payload: DataPayload<SearchType[]>) => ({
  type: types.SET_SEARCH_RESULT,
  payload,
});

export const getSearchResult = (payload: GetSearchType) => ({
  type: types.GET_SEARCH_RESULT,
  payload,
});

export const setIsDrawerLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_IS_DRAWER_LOADING,
  payload,
});

export const setIsDrawerOpen = (payload: SetIsDrawerOpenPayload) => ({
  type: types.SET_IS_DRAWER_OPEN,
  payload,
});

export const setDestroyDrawerOnClose = (payload: SetDestroyDrawerOnClosePayload) => ({
  type: types.SET_DESTROY_DRAWER_ON_CLOSE,
  payload,
});

export const setIsDrawerFilterTableOpen = (payload: SetIsDrawerFilterTableOpenPayload) => ({
  type: types.SET_IS_DRAWER_FILTER_TABLE_OPEN,
  payload,
});

export const setDrawerBody = (payload: SetDrawerBodyPayload) => ({
  type: types.SET_DRAWER_BODY,
  payload,
});

export const resetForm = (payload: ResetFormPayload) => ({
  type: types.RESET_FORM,
  payload,
});

export const getNationalities = (payload: object = {}) => ({
  type: types.GET_NATIONALITIES,
  payload,
});

export const setNationalities = (payload: DataPayload<CountryType>) => ({
  type: types.SET_NATIONALITIES,
  payload,
});

/*
* NOTE: this will get the whole list of startups from the database, needed for the filtering.
* We cannot use the startups saved in the Startups reducer,
* because the list is limited using the offset and limit parameter, making the list incomplete.
* This will allow us to limit the calling of the api, because we will check to see if the list is full in the
* workspace reducer and if it is then the call will not be executed again. this means that the call will only be executed
* the first time the action is called.
*/
export const getStartups = (payload: object = {}) => ({
  type: types.GET_ALL_STARTUPS,
  payload,
});

export const setStartups = (payload: DataPayload<StartupType[]>) => ({
  type: types.SET_ALL_STARTUPS,
  payload,
});

export const getAngelInvestors = (payload: object = {}) => ({
  type: types.GET_ALL_ANGEL_INVESTORS,
  payload,
});

export const setAngelInvestors = (payload: DataPayload<StartupType[]>) => ({
  type: types.SET_ALL_ANGEL_INVESTORS,
  payload,
});

export const setIsNoticeModalOpen = (payload: IsOpenPayload) => ({
  type: types.SET_IS_NOTICE_MODAL_OPEN,
  payload,
});

export const setNoticeModalTemplate = (payload: DataPayload<NoticeModalConfig>) => ({
  type: types.SET_NOTICE_MODAL_TEMPLATE,
  payload,
});

export const getWorkspaceServiceProviders = (payload: GetAllQueryPayload<ParamsPayload> = { query: {} }) => ({
  type: types.GET_WORKSPACE_SERVICE_PROVIDERS,
  payload,
});

export const getWorkspacePartners = (payload: object = {}) => ({
  type: types.GET_WORKSPACE_PARTNERS,
  payload,
});

export const setWorkspaceServiceProviders = (payload: DataPayload<ServiceProviderType[]>) => ({
  type: types.SET_WORKSPACE_SERVICE_PROVIDERS,
  payload,
});

export const setWorkspacePartners = (payload: DataPayload<PartnerType[]>) => ({
  type: types.SET_WORKSPACE_PARTNERS,
  payload,
});
