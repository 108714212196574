import { CamelCaseStatusType, StatusType } from 'types/requests';

export const REQUEST_STATUS: {[key in CamelCaseStatusType]: StatusType} = {
  new: 'new',
  quoted: 'quoted',
  quotedStartup: 'quoted-startup',
  quotedAngelInvestor: 'quoted-angel-investor',
  inProgress: 'in progress',
  pendingHUB71: 'pending HUB71',
  delivered: 'delivered',
  rejected: 'rejected',
  pendingQuotation: 'pending quotation',
  unsent: 'unsent',
};

export default REQUEST_STATUS;
