import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { getIncentiveAgreementById } from 'redux/actions/angelInvestors';
import { selectIncentiveAgreement, selectAngelInvestorId, selectIncentiveAgreementStatus } from 'redux/selectors/angelInvestors';

const mapStateToProps = (state: StateType) => ({
  incentiveAgreement: selectIncentiveAgreement(state),
  angelInvestorID: selectAngelInvestorId(state),
  incentiveAgreementStatus: selectIncentiveAgreementStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getIncentiveAgreementById }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
