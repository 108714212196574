import httpClient, {
  ApiResponseType,
  CreateResourceSuccessResponseType,
  FORM_DATA_CONFIG,
} from '../../http_client';
import {
  GetIncentiveAgreement,
  GetIncentiveAgreementResponse,
  UpdateCreateIncentiveAgreementType,
  UpdateIncentiveAgreementResponse,
} from '../types';
import { convertIncentiveAgreementBody, ANGELINVESTOR_INCENTIVE_AGREEMENT } from '../utils';

const getAngelInvestorIncentiveAgreementByIdApi = (id: number) => {
  const url = `${ANGELINVESTOR_INCENTIVE_AGREEMENT}/${id}`;
  return httpClient.get<ApiResponseType<GetIncentiveAgreement>>(url);
}

const createAngelInvestorIncentiveAgreementApi = (data: UpdateCreateIncentiveAgreementType) => {
  const url = ANGELINVESTOR_INCENTIVE_AGREEMENT;
  const body = convertIncentiveAgreementBody(data);
  return httpClient.post<ApiResponseType<CreateResourceSuccessResponseType>>(url, body, FORM_DATA_CONFIG);
}

const updateAngelInvestorIncentiveAgreementApi = (data: UpdateCreateIncentiveAgreementType, id: number) => {
  const url = `${ANGELINVESTOR_INCENTIVE_AGREEMENT}/${id}`;
  const body = convertIncentiveAgreementBody(data);
  return httpClient.put<ApiResponseType<UpdateIncentiveAgreementResponse>>(url, body, FORM_DATA_CONFIG);
}

export const getAngelInvestorIncentiveAgreementById = async (id: number): Promise<GetIncentiveAgreementResponse> => {
  const response = await getAngelInvestorIncentiveAgreementByIdApi(id);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.result };
}

export const createAngelInvestorIncentiveAgreement = async (data: UpdateCreateIncentiveAgreementType):
  Promise<ApiResponseType<CreateResourceSuccessResponseType>> => {
  const response = await createAngelInvestorIncentiveAgreementApi(data);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const updateAngelInvestorIncentiveAgreement = async (payload: UpdateCreateIncentiveAgreementType, id: number):
  Promise<ApiResponseType<CreateResourceSuccessResponseType>> => {
  const response = await updateAngelInvestorIncentiveAgreementApi(payload, id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}
