// import { formatUrl } from 'packages/http_client/utils/converters';
import { identity } from 'utils';
import { angelInvestorDef } from 'redux/utils';
import {
  ADGMLicenseType,
  CreateADGMLicenseApiRequestPayload,
  CreateADGMLicensePayload,
  CreateIncentiveAgreementBodyType,
  CreateAngelInvestorPayload,
  CreateAngelInvestorRequestPayload,
  EditAngelInvestorPayload,
  GetInvestmentStagesApiResponseType,
  GetStagesApiResponseType,
  GetAngelInvestorsApiResponseType,
  InvestmentStageResponseType,
  ResponseAngelInvestorType,
  StageResponseType,
  StageType,
  AngelInvestorQuery,
  AngelInvestorType,
  UpdateAdgmLicenseByAngelInvestorIdRequestPayload,
  UpdateCreateIncentiveAgreementType,
  UpdateIncentiveAgreementBodyType,
  UpdateAngelInvestorRequestPayload,
} from '../types';
import { queryBuilder } from '../../http_client';

export const convertIncentiveAgreementBody = (data: UpdateCreateIncentiveAgreementType)
  : CreateIncentiveAgreementBodyType | UpdateIncentiveAgreementBodyType => ({
  _angel_investor_id: data.angelInvestorID,
  _start_date: data.startDate,
  _end_date: data.endDate,
  _file: data.file as Blob,
  _wallet_amount: data.walletAmount,
});
export const convertGetStagesResponse = (stage: StageResponseType): StageType => ({
  id: stage.id,
  name: stage.name,
  createdAt: stage.createdAt,
});

export const convertGetInvestmentStagesResponse = (stage: InvestmentStageResponseType): StageType => ({
  id: stage.id,
  name: stage.invStageName || stage.name,
});

export const convertCreateAngelInvestorRequestPayload = (
  payload: CreateAngelInvestorPayload,
): CreateAngelInvestorRequestPayload => ({
  _name: payload.name,
  _location: payload?.location,
  _logo: payload.logo,
  _website: payload.website,
  _stage_id: payload.stage,
  _investment_stage_id: payload.investmentStage,
  _email: payload.email,
  _first_name: payload.firstName,
  _last_name: payload.lastName,
  _job_title: payload.jobTitle,
  _description: payload.description,
  _acceptance_letter: payload.acceptanceLetter,
});

export const convertCreateADMGLicenseRequestPayload = (payload: CreateADGMLicensePayload):
CreateADGMLicenseApiRequestPayload => ({
  _adgm_license_no: payload.aDGMLicenseNo,
  _angel_investor_id: payload.angelInvestorId,
  _adgm_license_url: payload.aDGMLicenseUrl,
  _legal_signatory_name: payload?.legalSignatoryName!,
  _legal_adgm_name: payload?.legalADGMName!,
  _legal_signatory_email: payload?.legalSignatoryEmail!,
  _legal_signatory_title: payload?.legalSignatoryTitle!,
  _issue_date: payload?.issueDate!,
  _expiry_date: payload?.expiryDate!,
  _address: payload?.address!,

});

export const convertGetStagesListResponse = (response?: GetStagesApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result.map((stage) => convertGetStagesResponse(stage));
  }
}

export const convertGetInvestmentStagesListResponse = (response?: GetInvestmentStagesApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result.map((stage) => convertGetInvestmentStagesResponse(stage));
  }
}

export const convertCreateAngelInvestorApiId = (data?: number[]) => (identity.isFunction(data) ? data![0] : undefined);

export const convertGetAngelInvestorResponse = (angelInvestor?: ResponseAngelInvestorType): AngelInvestorType => {
  if (identity.isObjWithChildren(angelInvestor)) {
    return {
      id: angelInvestor?.id!,
      name: angelInvestor?.name!,
      website: angelInvestor?.website!,
      location: angelInvestor?.location!,
      about: angelInvestor?.description!,
      imageLink: angelInvestor?.logo!,
      walletID: angelInvestor?.walletID!,
      wallet: angelInvestor?.wallet!,
      status: angelInvestor?.status!,
      contactEmail: angelInvestor?.contactEmail!,
      aDGMApprovedStatus: angelInvestor?.aDGMApprovedStatus!,
      acceptanceLetterApprovedStatus: angelInvestor?.acceptanceLetterApprovedStatus!,
      incentiveAgreementApprovedStatus: angelInvestor?.incentiveAgreementApprovedStatus!,
      currentADGMLicense: angelInvestor?.currentADGMLicense!,
      currentADGMLicenseID: angelInvestor?.currentADGMLicenseID!,
      currentIncentiveAgreement: angelInvestor?.currentIncentiveAgreement,
      currentIncentiveAgreementID: angelInvestor?.currentIncentiveAgreementID!,
      acceptanceLetter: angelInvestor?.acceptanceLetter!,
      contactFirstName: angelInvestor?.contactFirstName!,
      contactLastName: angelInvestor?.contactLastName!,
      moUEndDate: angelInvestor?.moUEndDate!,
      moUSigned: angelInvestor?.moUSigned!,
      moUStartDate: angelInvestor?.moUStartDate!,
      contactPhone: angelInvestor?.contactEmail!,
      contactRole: angelInvestor?.contactRole!,
    };
  }
  return angelInvestorDef;
}

export const convertGetAdGMLicenseResponse = (adgmLicense?: ADGMLicenseType): ADGMLicenseType => ({
  id: adgmLicense?.id!,
  aDGMLicenseNo: adgmLicense?.aDGMLicenseNo!,
  aDGMLicenseUrl: adgmLicense?.aDGMLicenseUrl!,
  angelInvestorID: adgmLicense?.angelInvestorID!,
  legalSignatoryName: adgmLicense?.legalSignatoryName!,
  legalSignatoryTitle: adgmLicense?.legalSignatoryTitle!,
  legalSignatoryEmail: adgmLicense?.legalSignatoryEmail!,
  legalADGMName: adgmLicense?.legalADGMName!,
  expiryDate: adgmLicense?.expiryDate!,
  issueDate: adgmLicense?.issueDate!,
  address: adgmLicense?.address!,
});

export const convertGetAngelInvestorListResponse = (
  response: GetAngelInvestorsApiResponseType | undefined,
): AngelInvestorType[] => {
  const data: AngelInvestorType[] = [];
  if (identity.isFullArray(response?.result)) {
    response?.result!.forEach((angelInvestor: ResponseAngelInvestorType) => {
      const mappedAngelInvestor = convertGetAngelInvestorResponse(angelInvestor);
      if (identity.isObjWithChildren(mappedAngelInvestor)) {
        data.push(mappedAngelInvestor!);
      }
    });
  }

  return data;
}

export const convertEditAngelInvestorRequestPayload = (payload: Partial<EditAngelInvestorPayload>)
  : UpdateAngelInvestorRequestPayload => ({
  _name: payload?.name!,
  _website: payload?.website!,
  _description: payload?.about!,
  _stage_id: payload?.stage!,
  _logo: payload?.imageLink!,
  _location: payload.location!,
  _acceptance_letter_approved_status: payload.acceptanceLetterApprovedStatus,
  _adgm_approved_status: payload.aDGMApprovedStatus,
  _incentive_agreement_approved_status: payload.incentiveAgreementApprovedStatus,
  _acceptance_letter: payload.acceptanceLetter,
});

export const convertEditAdgmLicenseByAngelInvestorIdRequestPayload = (
  payload: ADGMLicenseType,
): UpdateAdgmLicenseByAngelInvestorIdRequestPayload => ({
  _adgm_license_no: payload?.aDGMLicenseNo!,
  _legal_signatory_name: payload?.legalSignatoryName!,
  _legal_adgm_name: payload?.legalADGMName!,
  _legal_signatory_email: payload?.legalSignatoryEmail!,
  _legal_signatory_title: payload?.legalSignatoryTitle!,
  _issue_date: payload?.issueDate!,
  _expiry_date: payload?.expiryDate!,
  _address: payload?.address!,
  _adgm_license_url: payload?.aDGMLicenseUrl!,
});

export const formatQuery = (query: AngelInvestorQuery) => {
  const {
    isPreload, limit, offset, distinct, statusID, partnerId, serviceProviderId,
  } = query;
  const {
    locations, stage, capitalRaised, incentiveAmount, fieldSelection, searchString,
  } = query.filters || {};
  const queryRelatedTable = [];
  const joinTables = [];
  const params = queryBuilder({ stage_id: identity.isPositiveNumber(stage) ? stage : undefined, status_id: statusID });
  if (identity.isTruthyString(searchString)) {
    params.searchQuery(searchString!);
  }
  if (identity.isTruthyString(capitalRaised)) {
    queryRelatedTable.push({ tableName: 'wallet_amount_utilizations', field: 'total_wallet_amount', value: capitalRaised });
  }
  if (identity.isTruthyString(incentiveAmount)) {
    queryRelatedTable.push({ tableName: 'wallet_amount_utilizations', field: 'consumed_amount', value: incentiveAmount });
  }
  if (identity.isTruthyNumber(partnerId)) {
    queryRelatedTable.push({ tableName: 'partner_requests', field: 'partner_id', value: partnerId!.toString() });
    joinTables.push({ tableName: 'partner_requests', onId: 'angelInvestors.id__partner_requests.angelInvestor_id' });
  } else if (identity.isTruthyNumber(serviceProviderId)) {
    queryRelatedTable.push({ tableName: 'service_requests', field: 'service_provider_id', value: serviceProviderId!.toString() });
    joinTables.push({ tableName: 'service_requests', onId: 'angelInvestors.id__service_requests.angelInvestor_id' });
  }
  if (isPreload) {
    params.preload();
  }
  if (distinct || identity.isTruthyNumber(query?.serviceProviderId) || identity.isTruthyNumber(query?.partnerId)) {
    params.distinct();
  }
  if (identity.isTruthyString(incentiveAmount) || identity.isTruthyString(capitalRaised)) {
    joinTables.push({ tableName: 'wallet_amount_utilizations', onId: 'wallet_id' });
  }

  params
    .limit(limit)
    .offset(offset)
    .select(fieldSelection)
    .in({ location: locations })
    .queryRelatedTable(queryRelatedTable)
    .join(joinTables);
  return params.toObject();
}
