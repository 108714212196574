import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectDashboardRequests } from 'redux/selectors/dashboard';
import {
  selectRequests,
  selectRequestOverviewFilters,
} from 'redux/selectors/request';
import { getRequests } from 'redux/actions/request';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  requests: selectDashboardRequests(state),
  userRequests: selectRequests(state),
  userType: selectUserType(state),
  filters: selectRequestOverviewFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getRequests,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
