import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';
import { selectUserAngelInvestorId } from 'redux/selectors/auth';
import { StateType } from 'types';
import { addAIMember } from 'redux/actions/people';
import { selectNationalities } from 'redux/selectors/workspace';
import { getNationalities } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  angelInvestorId: selectUserAngelInvestorId(state),
  nationalities: selectNationalities(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    addAIMember,
    getNationalities,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
