import { createSelector } from 'reselect';

import { StateType } from '../../../types';
import { AIWalletStateType } from '../../../types/reduxTypes/StateTypes';

const selectAIWalletState = (state: StateType) => state.aiWalletReducer;

export const selectAIWallet = createSelector(
  selectAIWalletState,
  (state: AIWalletStateType) => state.wallet,
);

export const selectAIWalletTransactions = createSelector(
  selectAIWalletState,
  (state: AIWalletStateType) => state.walletTransactions,
);

export const selectIsLoading = createSelector(
  selectAIWalletState,
  (state: AIWalletStateType) => state.isLoading,
);

export const selectAIWalletCount = createSelector(
  selectAIWalletState,
  (state: AIWalletStateType) => state.count,
);
