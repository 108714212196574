import { StatusType } from 'types/requests';
import { UserRoleType } from 'types/auth';
import { RadioValueType } from 'types/componentTypes';
import {
  isStartup,
  isEitherAdmin,
  identity,
  isPartner,
  isAngelInvestor,
} from 'utils';
import REQUEST_STATUS from 'utils/constants/requestStatusTypes';

export const requestOverviewTabs = (userType: UserRoleType, unsentCount: number): (StatusType | 'all')[] => {
  let tabs: (StatusType | 'all')[] = [
    'all',
    REQUEST_STATUS.new,
    REQUEST_STATUS.quotedStartup,
    REQUEST_STATUS.quotedAngelInvestor,
    REQUEST_STATUS.pendingHUB71,
    REQUEST_STATUS.inProgress,
    REQUEST_STATUS.delivered,
    REQUEST_STATUS.rejected,
  ];
  if ((isStartup(userType))) {
    tabs = tabs.filter((obj: string) => obj !== REQUEST_STATUS.quotedAngelInvestor);
  }

  if ((isAngelInvestor(userType))) {
    tabs = tabs.filter((obj: string) => obj !== REQUEST_STATUS.quotedStartup);
  }

  if ((isStartup(userType) || isAngelInvestor(userType)) && identity.isTruthyNumber(unsentCount)) {
    tabs.splice(0, 0, REQUEST_STATUS.unsent);
  }
  if (isStartup(userType) || isAngelInvestor(userType) || isEitherAdmin(userType)) {
    const deleteCount: number = (isStartup(userType) || isAngelInvestor(userType))
      && identity.isTruthyNumber(unsentCount) ? 0 : 1;
    const start: number = (isStartup(userType) || isAngelInvestor(userType))
      && identity.isTruthyNumber(unsentCount) ? 2 : 1;
    tabs.splice(start, deleteCount, REQUEST_STATUS.pendingQuotation);
  }

  if (isPartner(userType)) {
    tabs.splice(2, 1);
  }

  return tabs;
}

export const selectUsersValues: RadioValueType[] = [
  { key: 'startup', value: 'startup', title: 'Startup' },
  { key: 'angelinvestor', value: 'angel-investor', title: 'Angel investor' },
];

export const requestTypeValues: RadioValueType[] = [
  { key: 'all', value: 'all', title: 'All requests' },
  { key: 'service', value: 'service', title: 'Service requests' },
  { key: 'partner', value: 'partner', title: 'Partner requests' },
];

export const requestTypeValuesForAI: RadioValueType[] = [
  { key: 'all', value: 'all', title: 'All requests' },
  { key: 'service', value: 'service', title: 'Service requests' },
];
