import { StartupFiltersType } from 'types/startups';
import { AngelInvestorFiltersType } from 'packages/angel_investors_repository';
import identity from 'utils/identity';
import { PeopleFilters } from 'types/people';
import { RequestFiltersState } from 'types/requests';
import { EventsFilters } from 'types/events';
import { ServicesFiltersType } from '../../types/services'

export const checkStartupFiltersActive = (data: StartupFiltersType): boolean => (
  identity.isFullArray(data.industries)
  || identity.isFullArray(data.locations)
  || identity.isFullArray(data.cohorts)
  || identity.isPositiveNumber(data.stage)
  || identity.isTruthyString(data.capitalRaised)
  || identity.isTruthyString(data.incentiveAmount)
  || identity.isTruthyString(data.searchString)
);

export const checkAngelInvestorFiltersActive = (data: AngelInvestorFiltersType): boolean => (
  identity.isFullArray(data.locations)
  || identity.isPositiveNumber(data.stage)
  || identity.isTruthyString(data.capitalRaised)
  || identity.isTruthyString(data.incentiveAmount)
  || identity.isTruthyString(data.searchString)
);

export const checkPeopleFiltersActive = (data: PeopleFilters): boolean => (
  identity.isFullArray(data.startups)
  || identity.isFullArray(data.nationalities)
  || identity.isFullArray(data.roles)
  || identity.isFullArray(data.skills)
  || identity.isPositiveNumber(data.gender)
  || identity.isPositiveNumber(data.goldenVisaEligibility)
  || identity.isTruthyString(data.searchString)
);

export const checkRequestsFiltersActive = (data: RequestFiltersState): boolean => (
  identity.isFullArray(data.serviceProviderId)
  || identity.isFullArray(data.startupId)
  || identity.isFullArray(data.partnerId)
  || identity.isFullArray(data.category)
  || identity.isPositiveNumber(data.to)
  || identity.isPositiveNumber(data.from)
  || data.type !== 'all'
);

export const checkEventFiltersActive = (data: EventsFilters): boolean => (
  identity.isFullArray(data.categories)
  || identity.isFullArray(data.type)
  || identity.isFullArray(data.frequency)
  || identity.isTruthyString(data.startingTime)
  || identity.isTruthyString(data.endingTime)
);

export const checkServicesFiltersActive = (data: ServicesFiltersType): boolean => (
  identity.isFullArray(data.serviceProviderId)
  || identity.isFullArray(data.categoryId)
  || identity.isFullArray(data.priceTypeId)
  || identity.isPositiveNumber(data.from)
  || identity.isPositiveNumber(data.to)
);
