import { PeopleStateType } from 'types/reduxTypes/StateTypes';
import {
  PartnersType,
  GenderType,
  LocationType,
  PeopleFilters,
} from 'types/people';
import { defaultMember, defaultAngelInvestorMember } from 'redux/utils/defaultStates/MemberDefaultState';

export const defaultGender: GenderType = 'Male';

export const defaultLocation: LocationType = {
  city: '',
  country: '',
};

export const defaultPartners: PartnersType = {
  id: -1,
  firstName: '',
  lastName: '',
  imageLink: '',
  role: '',
  nationality: '',
  dateOfBirth: '',
  email: '',
  phoneNumber: '',
  company: '',
  skills: [],
  mobileNumber: '',
  gender: defaultGender,
  numberOfKids: 0,
  capacity: '',
  status: '',
  emiratesId: '',
  location: defaultLocation,
  about: '',
  personType: 'partners',
  specialTags: '',
  areasOfExpertise: '',
  linkToConnect: '',
  jobFunctions: [],
  hobbies: [],
}

export const peopleFiltersDefault: PeopleFilters = {
  angelInvestors: [],
  startups: [],
  nationalities: [],
  roles: [],
  skills: [],
  gender: 0,
  goldenVisaEligibility: 0,
  searchString: '',
}

export const foundersDefaultState: PeopleStateType = {
  filtersActive: false,
  filters: peopleFiltersDefault,
  count: {
    founders: 0,
    teamMembers: 0,
    all: 0,
  },
  skills: [],
  roles: [],
  allMembers: [],
  founders: [],
  teamMembers: [],
  aITeamMembers: [],
  involvementTypes: [],
  selectedPeople: {
    founders: defaultMember,
    teamMembers: defaultMember,
    aITeamMembers: defaultAngelInvestorMember,
    partners: defaultPartners,
  },
  activeTab: 'all',
  isLoading: false,
};
