import { AIWalletType } from 'types/ai_wallet';

export const fill = [
  { id: 'gradientA', match: { id: 'consumedAmount' } },
  { id: 'gradientB', match: { id: 'balance' } },
  { id: 'gradientC', match: { id: 'blockedAmount' } },
];

export const defs = [
  {
    id: 'gradientA',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: 'rgba(234, 242, 254, 0.4)' },
      { offset: 100, color: '#EAF2FE' },
    ],
    color: 'rgba(234, 242, 254, 0.4)',
  },
  {
    id: 'gradientB',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: 'rgba(234, 254, 249, 0.4)' },
      { offset: 100, color: '#EAFEF9' },
    ],
    color: 'rgba(234, 254, 249, 0.4)',
  },
  {
    id: 'gradientC',
    type: 'linearGradient',
    colors: [
      { offset: 0, color: 'rgba(254, 234, 234, 0.4)' },
      { offset: 100, color: '#FEEAEA' },
    ],
    color: 'rgba(254, 234, 234, 0.4)',
  },
];

export const margin = { top: 30, bottom: 30 };

export const getStartAngle = (wallet: AIWalletType) => {
  const consumed = parseFloat(wallet.consumedAmount);
  const balance = parseFloat(wallet.balance);
  const blocked = parseFloat(wallet.blockedAmount);

  const aiWalletGraph = consumed + balance + blocked;
  return 180 * (balance / aiWalletGraph);
}
