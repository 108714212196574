import React from 'react';
import { identity, DRAWERS } from 'utils';
import { getAngelInvestorFile } from 'components/EditAngelInvestor/utils';
import { AboutAIProps } from 'types/componentTypes/AboutProps';
import { RenderIf, Input } from 'components/components';
import { ViewUploadButton } from 'components/EditAngelInvestor/components';

import './About.scss';

const AboutAI = (props: AboutAIProps) => {
  const {
    title,
    description,
    isAngelInvestor,
    angelInvestorDetails,
  } = props;

  const handleOpenDrawer = (drawerBody: string) => {
    props.actions.setIsDrawerOpen({ isDrawerOpen: true });
    props.actions.setDrawerBody({ drawerBody });
  }

  return (
    <div className="about">
      <div className="about__container">
        <RenderIf condition={identity.isTruthyString(title)}>
          <div className="about__header">
            <h3 className="about__title">{title}</h3>
          </div>
        </RenderIf>
        <div className="about__card">
          { description || 'No description or details specified' }
          { props.children }
        </div>
        <RenderIf condition={isAngelInvestor}>
          <div className="about__details">
            <div className="details_row">
              <h4>Website:</h4>
              <p>{angelInvestorDetails?.website}</p>
            </div>
            <RenderIf condition={identity.isTruthyString(angelInvestorDetails?.acceptanceLetter)}>
              <div className="details_row">
                <h4>Acceptance Letter:</h4>
                <div className="acceptance-letter">
                  <Input.DraggerUpload
                    defaultFileList={identity.isTruthyString(angelInvestorDetails?.acceptanceLetter)
                      ? [...getAngelInvestorFile(angelInvestorDetails?.acceptanceLetter!)] : []}
                    previewType="image"
                    // beforeUpload={beforeAcceptanceLetterUpload}
                    accept=".pdf"
                    isRoundableButton
                    name={identity.isTruthyString(angelInvestorDetails?.acceptanceLetter) ? 'acceptanceLetter' : ''}
                  />
                </div>
              </div>
            </RenderIf>
            <RenderIf condition={!identity.isZero(angelInvestorDetails?.aDGMApprovedStatus)}>
              <div className="details_row">
                <h4>ADGM License:</h4>
                <div>
                  <ViewUploadButton
                    userType="Angel investor"
                    name="ADGM License"
                    onClick={() => handleOpenDrawer(DRAWERS.aIAdgmLicenseDetails)}
                    condition={angelInvestorDetails?.aDGMApprovedStatus === 1
                      && identity.isTruthyString(angelInvestorDetails?.currentADGMLicense?.aDGMLicenseUrl)}
                    status={angelInvestorDetails?.aDGMApprovedStatus}
                  />
                </div>
              </div>
            </RenderIf>
            <RenderIf
              condition={!identity.isZero(angelInvestorDetails?.currentIncentiveAgreement?.licenseAgreementStatus)}
            >
              <div className="details_row">
                <h4>Incentive Agreement:</h4>
                <div>
                  <ViewUploadButton
                    userType="Angel investor"
                    name="Incentive Agreement"
                    onClick={() => handleOpenDrawer(DRAWERS.aIIncentiveAgreementDetails)}
                    condition={angelInvestorDetails?.currentIncentiveAgreement?.licenseAgreementStatus
                      && identity.isTruthyString(angelInvestorDetails?.currentIncentiveAgreement?.file)}
                    status={angelInvestorDetails?.currentIncentiveAgreement?.licenseAgreementStatus}
                  />
                </div>
              </div>
            </RenderIf>
          </div>
        </RenderIf>
      </div>
    </div>
  );
};

export default AboutAI;
