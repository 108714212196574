import { PayloadAction } from '@reduxjs/toolkit';

import { EditAngelInvestorMemberType } from 'types/people/editMemberProfile';
import { SetFieldType } from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { defaultAngelInvestorMember } from 'redux/utils/defaultStates/MemberDefaultState';
import { types } from './actions';

type EditAngelInvestorMemberState = {
  member: EditAngelInvestorMemberType,
  skillsShowError: boolean,
  hobbiesShowError: boolean,
  jobFunctionsShowError: boolean,
}

const initialState: EditAngelInvestorMemberState = {
  member: {
    ...defaultAngelInvestorMember,
    skills: '',
    hobbies: '',
    jobFunctions: '',
    numberOfKids: 0,
    gender: 0,
    hasEmiratesId: 0,
    nationalityId: 0,
    passport: '',
    visa: '',
  },
  skillsShowError: false,
  hobbiesShowError: false,
  jobFunctionsShowError: false,
};

const handleSetAll = (state: EditAngelInvestorMemberState, payload: DataPayload<EditAngelInvestorMemberType>) => ({
  ...state, member: payload.data,
});

const handleSetField = (state: EditAngelInvestorMemberState, payload: SetFieldType) => ({
  ...state, member: { ...state.member, [payload.field]: payload.value },
});

const handleSetLogo = (state: EditAngelInvestorMemberState, payload: DataPayload<string | Blob>) => ({
  ...state, member: { ...state.member, emiratesId: payload.data },
});

const handleSetShowError = (state: EditAngelInvestorMemberState, payload: SetFieldType) => ({
  ...state, [`${payload.field}ShowError`]: payload.value,
});

const handleSetPassport = (state: EditAngelInvestorMemberState, payload: DataPayload<string | Blob>) => ({
  ...state, member: { ...state.member, passport: payload.data },
});

const handleSetVisa = (state: EditAngelInvestorMemberState, payload: DataPayload<string | Blob>) => ({
  ...state, member: { ...state.member, visa: payload.data },
});

const reducer = (
  state: EditAngelInvestorMemberState,
  action: PayloadAction<DataPayload<EditAngelInvestorMemberType | string | Blob> | SetFieldType>,
): EditAngelInvestorMemberState => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ALL: {
      return handleSetAll(state, <DataPayload<EditAngelInvestorMemberType>>payload);
    }
    case types.SET_FIELD: {
      return handleSetField(state, <SetFieldType>payload);
    }
    case types.SET_EMIRATES_ID_PEOPLE: {
      return handleSetLogo(state, <DataPayload<string | Blob>>payload);
    }
    case types.SHOW_ERROR: {
      return handleSetShowError(state, <SetFieldType>payload);
    }
    case types.SET_VISA: {
      return handleSetVisa(state, <DataPayload<string | Blob>>payload);
    }
    case types.SET_PASSPORT: {
      return handleSetPassport(state, <DataPayload<string | Blob>>payload);
    }
    default:
      return state;
  }
}

export { reducer, initialState };
