// ServiceRequestType
import {
  RequestsCountObject, StatusType, TypeOfMedicalInsurance, TypeOfSpecialService,
} from 'types/requests'
import { UserRoleType } from 'types/auth'
import { isStartup, isEitherAdmin, isAngelInvestor } from 'utils'
import { REQUEST_STATUS } from 'utils/constants/requestStatusTypes'
import { REQUEST_STATUS_TO_REQUEST_STATUS_KEY_MAP } from 'packages/service_request_repository'
import { ServiceType } from 'packages/service_repository'
import { officeTypeOptions } from 'components/RequestServiceDrawer/utils/constants'

export const isAll = (status: StatusType | 'all') => status === 'all'
export const isNew = (status: StatusType | 'all') => status === REQUEST_STATUS.new
export const isInProgress = (status: StatusType | 'all') => status === REQUEST_STATUS.inProgress
export const isUnsent = (status: StatusType | 'all' | number) => status === REQUEST_STATUS.unsent || status === 0
export const isPendingHUB71 = (status: StatusType | 'all') => status === REQUEST_STATUS.pendingHUB71
export const isRejected = (status: StatusType | 'all') => status === REQUEST_STATUS.rejected
export const isQuoted = (status: StatusType | 'all') => status === REQUEST_STATUS.quoted
export const isDelivered = (status: StatusType | 'all') => status === REQUEST_STATUS.delivered

export const getStatusName = (status: StatusType | 'all', userType?: UserRoleType) => {
  switch (status) {
    case REQUEST_STATUS.new: {
      return isAngelInvestor(userType!) || isStartup(userType!) || isEitherAdmin(userType!)
        ? 'with service provider'
        : 'pending service provider'
    }
    case REQUEST_STATUS.quotedStartup: {
      if (isStartup(userType!)) {
        return 'pending startup'
      }
      return 'with startup'
    }
    case REQUEST_STATUS.quotedAngelInvestor: {
      if (isAngelInvestor(userType!)) {
        return 'pending angel investor'
      }
      return 'with angel investor'
    }
    case REQUEST_STATUS.pendingHUB71: {
      return isEitherAdmin(userType!) ? 'pending HUB71' : 'with HUB71'
    }
    default: {
      return status?.replace(/-/g, ' ')
    }
  }
}

export const getTabTitle = (count: RequestsCountObject, status: StatusType | 'all', userType: UserRoleType = '') => {
  if (['quoted-startup'].includes(status)) {
    return `${getStatusName(status, userType)} (${count.quotedStartup})`
  }
  if (['quoted-angel-investor'].includes(status)) {
    return `${getStatusName(status, userType)} (${count.quotedAngelInvestor})`
  }
  return `${getStatusName(status, userType)} (${count[REQUEST_STATUS_TO_REQUEST_STATUS_KEY_MAP[status]]})`
}

export const isSpecialServiceRequest = (service?: ServiceType) => [1, 2, 3].includes(service?.categoryId!)

export const getSpecialServiceType = (categoryId?: number): TypeOfSpecialService => {
  if (categoryId === 1) {
    return 'office space'
  }
  if (categoryId === 2) {
    return 'housing'
  }
  if (categoryId === 3) {
    return 'insurance'
  }

  return ''
}

export const getMedicalInsurancePlan = (plan: TypeOfMedicalInsurance): string => {
  if (plan === 'bronze') {
    return 'Bronze'
  }
  if (plan === 'gold') {
    return 'Enhanced Gold'
  }
  if (plan === 'platinum') {
    return 'Platinum'
  }
  if (plan === 'silver') {
    return 'Silver'
  }

  return 'None'
}

export const getOfficeType = (type: string): string => {
  const selectedOption = officeTypeOptions.filter((option) => option.value === type)
  return selectedOption.length > 0 ? selectedOption[0].label : 'Not Specified'
}
