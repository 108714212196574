import React, { useEffect, useMemo } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';

import { DashboardType } from 'types/componentTypes/Dashboard';
import routes from 'routes';
import {
  isAngelInvestor,
  isEitherAdmin,
  isProduction,
  isStartup,
} from 'utils';
import AngelInvestorWallet from 'components/components/AngelInvestorWallet';
import { RenderIf, StartupWallet } from 'components/components';
import {
  DashboardCards,
  RecentRequests,
  UpcomingEvents,
  DashboardHeader,
} from './components';
import StaticNotification from '../components/Notification/StaticNotification';

import './Dashboard.scss';

const Dashboard = (props: DashboardType) => {
  const {
    isLoading,
    userType,
    wallet,
    aiWallet,
    startupId,
    angelInvestorId,
    servicesCount,
    serviceProvidersCount,
  } = props;

  const {
    getWallet,
    getAIWallet,
    getDashboardData,
    getServicesSPCount,
  } = props.actions;

  const history = useHistory();

  useEffect(() => {
    getDashboardData();
    getServicesSPCount();
  }, []);

  useEffect(() => {
    if (isStartup(userType)) {
      getWallet({ startup_id: startupId, limit: 10, offset: 0 });
    }
  }, [startupId, userType]);

  useEffect(() => {
    if (isAngelInvestor(userType)) {
      getAIWallet({ angel_investor_id: angelInvestorId, limit: 10, offset: 0 });
    }
  }, [angelInvestorId, userType]);

  const notificationMessage = useMemo(() => (
    `${servicesCount} services available for request from ${serviceProvidersCount} service providers.`
  ), [servicesCount, serviceProvidersCount]);

  const handleViewServices = () => {
    props.actions.setActiveTab({ activeTab: 'services' });
    if (isEitherAdmin(userType)) {
      history.push(routes.servicesAndSpsOverviewUrl);
    } else {
      history.push(routes.servicesUrl);
    }
  }

  const handleViewServiceProviders = () => {
    props.actions.setActiveTab({ activeTab: 'service-providers' });
    if (isEitherAdmin(userType)) {
      history.push(routes.servicesAndSpsOverviewUrl);
    } else {
      history.push(routes.servicesUrl);
    }
  }

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="dashboard">
          <DashboardHeader />
          <div className="dashboard-content">
            <DashboardCards />
            <RenderIf condition={isStartup(userType)}>
              <StartupWallet showInfo wallet={wallet} startupId={startupId} showTransactionButton includeGraph />
            </RenderIf>
            <RenderIf condition={isAngelInvestor(userType)}>
              <AngelInvestorWallet
                showInfo
                wallet={aiWallet}
                angelInvestorId={angelInvestorId}
                showTransactionButton
                includeGraph
              />
            </RenderIf>
            <RenderIf condition={!isProduction && !isAngelInvestor(userType)}>
              <UpcomingEvents />
            </RenderIf>
            <RenderIf condition={isStartup(userType) || isEitherAdmin(userType)}>
              <StaticNotification
                type="info"
                message={notificationMessage}
                primaryButton={{ onClick: handleViewServices, text: 'View services' }}
                secondaryButton={{ onClick: handleViewServiceProviders, text: 'View service providers' }}
              />
            </RenderIf>
            <RecentRequests />
          </div>
        </div>
      </Spin>
    </div>
  )
};

export default Dashboard;
