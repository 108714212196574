import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { ADGMLicenseType, AngelInvestorType } from 'packages/angel_investors_repository';
// import { AngelInvestorType } from 'types/angelInvestors';
import { reducer, initialState } from './reducer';
import {
  setField as setFieldAC,
  setAll as setAllAC,
  SetFieldType,
  setHasLicense as setHasLicenseAC,
  setFileName as setFileNameAC,
  setADGMAll as setADGMAllAC,
  setADGMField as setADGMFieldAC,
  SetADGMFieldType,
} from './actions';

const useAcceptanceLetterHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setField = (payload: SetFieldType) => {
    dispatch(setFieldAC(payload));
  }

  const setAll = (payload: DataPayload<AngelInvestorType>) => {
    dispatch(setAllAC(payload));
  }

  const setHasLicense = (data: boolean) => {
    dispatch(setHasLicenseAC({ data }));
  }

  const setADGMAll = (payload: DataPayload<ADGMLicenseType>) => {
    dispatch(setADGMAllAC(payload));
  }

  const setFileName = (data: string) => {
    dispatch(setFileNameAC({ data }));
  }

  const setADGMField = (payload: SetADGMFieldType) => {
    dispatch(setADGMFieldAC(payload));
  }

  return {
    state,
    setAll,
    setField,
    setHasLicense,
    setFileName,
    setADGMAll,
    setADGMField,
  }
}

export default useAcceptanceLetterHook;
