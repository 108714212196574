import React, { useEffect } from 'react';
import { Button, Form, Spin } from 'antd';
import { useHistory } from 'react-router';
import { RcFile } from 'antd/lib/upload';

import {
  identity,
  isAngelInvestor,
  isAngelInvestorDataMissing,
  requiredValidation,
} from 'utils';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { AddMissingDetailsProps } from 'types/addAIDetails';
import StaticNotification from 'components/components/Notification/StaticNotification/View';
import { EditProfileImage, Input, RenderIf } from 'components/components';
import routes from 'routes';
import { ADGMLicense, AcceptanceLetter } from './components';
import Title from '../components/TitleWithActions/components/Title';
import { addMissingDetailsHook, disableAddMissingDetailsSubmitButton, getFileName } from './utils';

import './AddMissingDetails.scss';

const AddAIMissingDetails = (props: AddMissingDetailsProps) => {
  const {
    details,
    // stageOptions,
    isAuthenticated,
    angelInvestorId,
    adgmLicenseByAngelInvestorId,
    isLoading,
    userType,
  } = props;

  const {
    // getAngelInvestorStages,
    updateAngelInvestorById,
    getADGMLicenseByAngelInvestorId,
    updateADGMLicenseByAngelInvestorId,
    createAngelInvestorADGMLicense,
  } = props.actions;

  const {
    setAll,
    state,
    setField,
    setHasLicense,
    setFileName,
    setADGMAll,
    setADGMField,
  } = addMissingDetailsHook();
  const {
    angelInvestor,
    adgmLicense,
    hasLicense,
    fileName,
  } = state;
  const history = useHistory();

  useEffect(() => {
    if (identity.isFalsy(isAuthenticated)) {
      history.push(routes.dashboardUrl);
    }
  }, []);

  const onSetHasLicense = (value: DataPayload<boolean>) => {
    setHasLicense(value.data);
    if (value.data) {
      setADGMField({ field: 'aDGMLicenseNo', data: adgmLicenseByAngelInvestorId?.aDGMLicenseNo });
    }
  }

  useEffect(() => {
    if (isAngelInvestor(userType) && identity.isObjWithChildren(details)) {
      if (details.id === angelInvestorId) {
        if (angelInvestorId !== adgmLicenseByAngelInvestorId.angelInvestorId) {
          getADGMLicenseByAngelInvestorId({ id: angelInvestorId });
        }
        // if (identity.isEmptyArray(stageOptions)) {
        //   getAngelInvestorStages();
        // }
      }
    }
  }, [angelInvestorId]);

  useEffect(() => {
    if (identity.isObjWithChildren(details)) {
      if (isAngelInvestorDataMissing(details)) {
        setAll({ data: details });
        const hasAdgm = !identity.isZero(details.currentADGMLicenseID !== 0);
        setHasLicense(hasAdgm);
      } else {
        history.push(routes.dashboardUrl);
      }
    }
  }, [details]);

  useEffect(() => {
    if (hasLicense && !identity.isZero(adgmLicenseByAngelInvestorId.id)) {
      setADGMAll({ data: adgmLicenseByAngelInvestorId });
    }
  }, [hasLicense, adgmLicenseByAngelInvestorId]);

  useEffect(() => {
    setADGMAll({ data: adgmLicenseByAngelInvestorId });
    setFileName(getFileName(adgmLicenseByAngelInvestorId?.aDGMLicenseUrl));
  }, [adgmLicenseByAngelInvestorId]);

  const handleButton = () => {
    if (hasLicense) {
      if (!identity.isZero(details.currentADGMLicenseID)) {
        updateADGMLicenseByAngelInvestorId({ id: angelInvestorId, adgmLicense });
      } else {
        createAngelInvestorADGMLicense({ ...adgmLicense, angelInvestorId });
      }
    }
    updateAngelInvestorById({
      id: angelInvestorId,
      angelInvestor: { ...angelInvestor, stageId: angelInvestor.stage },
      reload: true,
    });
  }

  const beforeLogoUpload = (file: RcFile) => {
    setField({ field: 'imageLink', data: file as Blob });
    return false;
  }

  return (
    <Spin spinning={isLoading}>
      <div className="add-missing-details__container">
        <div className="add-missing-details__container__title">
          <Title size="l" title="Add missing details" />
        </div>
        <div className="add-missing-details__container__rows">
          <Form scrollToFirstError>
            <Input label="Angel Investor name" layout="vertical" required rules={[requiredValidation]}>
              <Input.InputField
                name="name"
                value={angelInvestor?.name}
                type="text"
                required
                onChange={(e) => setField({ field: 'name', data: e.target.value })}
              />
            </Input>
            {/* <Input label="Angel Investor stage" layout="vertical" required rules={[requiredValidation]}>
              <Input.Select
                onChange={(data) => setField({ field: 'stage', data })}
                value={identity.isTruthyNumber(angelInvestor?.stage) ? angelInvestor?.stage : undefined}
                options={stageOptions}
                placeholder="Select Angel Investor stage"
              />
            </Input> */}
            <Input label="Website" layout="vertical" required rules={[requiredValidation]}>
              <Input.InputField
                name="website"
                value={angelInvestor?.website}
                type="text"
                size="large"
                required
                placeholder="e.g. www.google.com"
                onChange={(e) => setField({ field: 'website', data: e.target.value })}
              />
            </Input>
            <div className="add-missing-details__container__column-inputs">
              <Input layout="vertical" label="Logo" required rules={[requiredValidation]}>
                <EditProfileImage imageLink={angelInvestor.imageLink} beforeLogoUpload={beforeLogoUpload} />
              </Input>
              <Input label="About" layout="vertical" required rules={[requiredValidation]}>
                <Input.InputField.TextArea
                  name="about"
                  value={angelInvestor?.about}
                  size="large"
                  rows={4}
                  placeholder="Enter description"
                  onChange={(e) => setField({ field: 'about', data: e.target.value })}
                />
              </Input>
            </div>
            <div className="add-missing-details__container__column__adgm">
              <h1 className="add-missing-details__container__column__title">Documents</h1>
              <AcceptanceLetter
                fileUrl={angelInvestor?.acceptanceLetter}
                letterAcceptanceStatus={angelInvestor?.status.name}
              />
              <ADGMLicense
                fileName={fileName}
                setHasLicense={onSetHasLicense}
                hasLicense={hasLicense}
                adgmLicense={adgmLicense}
                setADGMField={setADGMField}
                isAddMissingDetails
                setADGMAll={setADGMAll}
              />
            </div>
            <div className="submit-button">
              <Button
                disabled={disableAddMissingDetailsSubmitButton(angelInvestor, hasLicense, adgmLicense)}
                type="primary"
                htmlType="submit"
                className="submit-button__button"
                onClick={handleButton}
              >
                submit
              </Button>
            </div>
            <RenderIf condition={angelInvestor?.status.name === 'Acceptance letter sent'}>
              <StaticNotification
                type="error"
                message="Your acceptance letter has not been signed yet."
              />
            </RenderIf>
          </Form>
        </div>
      </div>
    </Spin>
  );
};

export default AddAIMissingDetails;
