import { AngelInvestorType } from 'packages/angel_investors_repository';
import { identity } from 'utils';

export const isAngelInvestorRequiredFieldsEmpty = (angelInvestor: AngelInvestorType) => {
  const {
    imageLink, name, website,
  } = angelInvestor;

  return identity.isEmptyString(imageLink)
  || identity.isEmptyString(name)
  || identity.isEmptyString(website)
  // || identity.isEmptyString(stageName);
};

export const isAngelInvestorDataMissing = (angelInvestor: AngelInvestorType) => {
  const { status } = angelInvestor;

  return status.code === 'acceptance_letter_sent'
    || (status.code === 'acceptance_letter_signed' && isAngelInvestorRequiredFieldsEmpty(angelInvestor));
};

export const isAICommunity = (status: string) => status === 'community';

export const isAIExpired = (status: string) => (['graduated', 'dropped_out', 'terminated'].includes(status))
