import React, { useEffect, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import { Button, Form } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import {
  RenderIf,
  Breadcrumb,
  TitleWithActions,
  Input,
  EditProfileImage,
  scrollToFirstError,
} from 'components/components';
import { composePeopleDetailsUrl } from 'routes/routeComposers';
import { EditAngelInvestorPeopleProfileProps } from 'types/people/editMemberProfile';
import { ChevronDownIcon } from 'assets/icons';
import { parseGenderValue } from 'packages/http_client';
import { CountryType } from 'packages/country_repository';
import routes from 'routes';
import {
  GENDERS,
  identity,
  isAngelInvestor as isAngelInvestorUser,
  requiredValidation,
  emailValidation,
} from 'utils';
import { AngelInvestorFieldType } from 'types/reduxTypes/ActionTypes/PeopleTypes';
import { formatDateDashYMDTimeHMS, formatSlashMDY } from '../utils/constants/formatters';
import MyProfileChangePassword from '../MyProfileChangePassword';
import { getFileList, useEditAIMemberHook } from './utils';

import './EditPeopleProfile.scss';

const EditMemberProfile = (props: EditAngelInvestorPeopleProfileProps) => {
  const {
    selectedMember,
    nationalities,
    userType,
    profileId,
  } = props;
  const {
    getNationalities,
    getAngelInvestorMemberById,
    updateAngelInvestorMemberById,
    getMyProfileData,
  } = props.actions;
  const history = useHistory();
  const location = useLocation();

  const params: { id?: string, type?: string} = useParams();

  const { id, type } = params;
  const isMyProfileEdit = location.pathname === routes.editMyProfileUrl;
  const memberId: number = useMemo(() => (isMyProfileEdit ? profileId : parseInt(id!, 10)), [id, isMyProfileEdit]);

  const {
    state, setAll, setField, setEmiratesId, setVisa, setPassport,
  } = useEditAIMemberHook();

  const { member } = state;

  const nationalitiesOptions = useMemo(() => nationalities.map(
    ({ id: value, name: label }: CountryType) => ({ value, label }),
  ), [nationalities]);

  const isAngelInvestor = isMyProfileEdit && isAngelInvestorUser(userType)
  const isPartner = useMemo(() => type === 'partners', [type]);

  const [form] = Form.useForm();

  useEffect(() => {
    getNationalities();
  }, []);

  useEffect(() => {
    if (!identity.isTruthyNumber(profileId) && isMyProfileEdit) {
      getMyProfileData();
    }
  }, [profileId]);

  useEffect(() => {
    const functionCall = getAngelInvestorMemberById;
    if (identity.isTruthyNumber(memberId)) {
      functionCall({ id: memberId });
    }
  }, [memberId]);

  useEffect(() => {
    setAll({
      data: {
        ...selectedMember,
        gender: parseGenderValue(selectedMember.gender),
        hasEmiratesId: identity.isTruthyString(selectedMember.emiratesId) ? 1 : 0,
      },
    });
  }, [selectedMember]);

  useEffect(() => form.setFieldsValue(member), [member]);

  if (isAngelInvestorUser(userType) && profileId !== selectedMember.userId && !isMyProfileEdit) {
    history.push(routes.dashboardUrl);
  }

  const defaultFileList: UploadFile[] = useMemo(() => getFileList(member.emiratesId as string), [member.emiratesId]);

  const defaultFileListVisa = useMemo(() => getFileList(member.visa as string), [member.visa]);

  const defaultFileListPassport = useMemo(() => getFileList(member.passport as string), [member.passport]);

  const handleSubmit = () => {
    const functionCall = updateAngelInvestorMemberById;
    if (JSON.stringify(selectedMember) !== JSON.stringify(member)) {
      functionCall({ member, route: isMyProfileEdit ? routes.myProfileUrl : composePeopleDetailsUrl(memberId, 'angel-investor') })
    }
  }

  const handleBeforeUpload = (file: RcFile, functionCall: (_payload: DataPayload<string | Blob>) => void) => {
    functionCall({ data: file as Blob });
    return false;
  }

  const handleBeforeUploadLogo = (file: RcFile, field: AngelInvestorFieldType) => {
    setField({ value: file as Blob, field });
    return false;
  }

  return (
    <div className="edit-people__container content-container">
      <Breadcrumb
        navigateTo={isMyProfileEdit ? routes.myProfileUrl : composePeopleDetailsUrl(memberId, isPartner ? type! : 'startup')}
        routeName={isMyProfileEdit ? 'My profile' : 'People details'}
      />
      <TitleWithActions title={`${member.firstName} ${member.lastName}`} />
      <Form
        onFinish={handleSubmit}
        onFinishFailed={scrollToFirstError}
        validateTrigger="submit"
        form={form}
      >
        <div className="edit-people__fields">
          <EditProfileImage imageLink={member.imageLink} beforeLogoUpload={handleBeforeUploadLogo} />
          <Input label="Angel investor name">
            <Input.InputField
              type="text"
              value={selectedMember.company}
              disabled
            />
          </Input>
          <Input label="Date of birth">
            <Input.DatePicker
              suffixIcon={<ChevronDownIcon />}
              format={formatSlashMDY}
              value={identity.isTruthyString(member.dateOfBirth) ? moment(member.dateOfBirth) : null}
              onChange={(value) => setField({ field: 'dateOfBirth', value: moment(value).utc().format(formatDateDashYMDTimeHMS) })}
            />
          </Input>
          <Input label="Nationality">
            <Input.Select
              options={nationalitiesOptions}
              value={member.nationalityId}
              onChange={(value) => setField({ field: 'nationalityId', value })}
            />
          </Input>
          <Input label="First Name" rules={[requiredValidation]} name="firstName">
            <Input.InputField
              type="text"
              value={member.firstName}
              onChange={({ target: { value } }) => setField({ field: 'firstName', value })}
            />
          </Input>
          <Input label="Last Name" rules={[requiredValidation]} name="lastName">
            <Input.InputField
              type="text"
              value={member.lastName}
              onChange={({ target: { value } }) => setField({ field: 'lastName', value })}
            />
          </Input>
          <Input label="Role/Title">
            <Input.InputField
              type="text"
              value={member.role}
              onChange={({ target: { value } }) => setField({ field: 'role', value })}
            />
          </Input>
          <Input label="Biography" rules={[requiredValidation]} name="about">
            <Input.InputField.TextArea
              value={member.about}
              onChange={({ target: { value } }) => setField({ field: 'about', value })}
            />
          </Input>
          <Input label="Mobile number">
            <Input.InputField
              type="text"
              value={member.phoneNumber}
              onChange={({ target: { value } }) => setField({ field: 'phoneNumber', value })}
            />
          </Input>
          <Input label="Email" rules={[requiredValidation, emailValidation]} name="email">
            <Input.InputField
              type="text"
              value={member.email}
              onChange={({ target: { value } }) => setField({ field: 'email', value })}
            />
          </Input>
          <Input label="Gender">
            <Input.Select
              options={GENDERS}
              value={member.gender}
              onChange={(value) => setField({ field: 'gender', value })}
            />
          </Input>
          { isAngelInvestor ? (
            <>
              <Input label="Do you have a valid Emirates ID?">
                <Input.Radio
                  value={member.hasEmiratesId}
                  values={[{ key: 'yes', value: 1, title: 'Yes' }, { key: 'no', value: 0, title: 'No' }]}
                  direction="horizontal"
                  onChange={({ target: { value } }) => setField({ field: 'hasEmiratesId', value })}
                />
              </Input>
              <RenderIf condition={identity.isTruthyNumber(member.hasEmiratesId)}>
                <Input label="Upload Emirates ID">
                  <Input.DraggerUpload
                    accept=".pdf"
                    defaultFileList={defaultFileList}
                    beforeUpload={(file) => handleBeforeUpload(file, setEmiratesId)}
                    allowChange
                    isRoundableButton
                    previewType="image"
                  />
                </Input>
              </RenderIf>
            </>
          ) : (
            <Input label="Link to connect">
              <Input.InputField
                type="text"
                required
                value={member.linkToConnect}
                onChange={({ target: { value } }) => setField({ field: 'linkToConnect', value })}
              />
            </Input>
          )}
          <Input label="Upload Visa">
            <Input.DraggerUpload
              accept=".pdf"
              defaultFileList={defaultFileListVisa}
              beforeUpload={(file) => handleBeforeUpload(file, setVisa)}
              allowChange
              isRoundableButton
              previewType="image"
            />
          </Input>
          <Input label="Upload Passport">
            <Input.DraggerUpload
              accept=".pdf"
              defaultFileList={defaultFileListPassport}
              beforeUpload={(file) => handleBeforeUpload(file, setPassport)}
              allowChange
              isRoundableButton
              previewType="image"
            />
          </Input>
          <Button
            htmlType="submit"
            className="primary-button"
          >
            save
          </Button>
        </div>
      </Form>
      <RenderIf condition={isMyProfileEdit}>
        <>
          <Input.Heading title="Change Password" />
          <MyProfileChangePassword />
        </>
      </RenderIf>
    </div>
  );
}

export default EditMemberProfile;
