import { ServiceProviderStateType } from 'types/reduxTypes/StateTypes';
import { ServiceProvider, ServiceProviderType } from 'types/serviceProviders';
import { ServiceProviderServicesType } from 'types/serviceProviders/ServiceProviderDetails';
import { defaultSelectedCohort } from './CohortDefaults';

export const defaultServiceProvider: ServiceProviderType = {
  about: '',
  category: '',
  companyDeck: '',
  email: '',
  id: 0,
  imageLink: '',
  name: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  role: '',
  websiteLink: '',
  type: '',
  howToObtain: '',
  perksDescription: '',
  perksWebLink: '',
}

export const serviceProviderDefault: ServiceProvider = {
  id: -1,
  provider: defaultServiceProvider,
  offer: '',
  price: '',
  startup: {
    balance: '',
    consumed: '',
    raised: '',
    about: '',
    id: -1,
    imageLink: '',
    industry: '',
    country: '',
    city: '',
    name: '',
    stage: '',
    type: '',
    employees: 0,
    cohort: defaultSelectedCohort,
    serviceProvidersCount: 0,
    upcomingEventsCount: 0,
    founderEmail: '',
    founders: [],
    website: '',
    numberOfEmployees: -1,
  },
  angelInvestor: {
    balance: '',
    consumed: '',
    raised: '',
    about: '',
    id: -1,
    imageLink: '',
    industry: '',
    country: '',
    city: '',
    name: '',
    stage: '',
    type: '',
    employees: 0,
    serviceProvidersCount: 0,
    upcomingEventsCount: 0,
    founders: [],
    website: '',
    numberOfEmployees: -1,
    moUSigned: false,
    moUStartDate: '',
    moUEndDate: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    contactRole: '',
  },
}

export const defaultServiceWithServices: ServiceProviderServicesType = {
  id: -1,
  name: '',
  about: '',
  category: '',
  type: '',
  serviceProvider: defaultServiceProvider,
  website: '',
  price: '',
  serviceStatus: 'pending',
}

export const serviceProviderDefaultState: ServiceProviderStateType = {
  serviceProviders: [],
  isLoading: false,
  selectedServiceProvider: {
    data: defaultServiceProvider,
  },
  serviceProvidersCount: 0,
  details: {
    data: defaultServiceProvider,
    requests: [],
    startups: [],
    startup: {
      // NOTE: current solution since the app crashed when importing default startup constant
      details: {
        balance: '',
        consumed: '',
        raised: '',
        about: '',
        id: -1,
        imageLink: '',
        industry: '',
        country: '',
        city: '',
        name: '',
        stage: '',
        type: '',
        employees: 0,
        cohort: defaultSelectedCohort,
        serviceProvidersCount: 0,
        upcomingEventsCount: 0,
        founderEmail: '',
        founders: [],
        website: '',
        numberOfEmployees: -1,
      },
      requests: [],
    },
    services: [],
    service: defaultServiceWithServices,
    requestedServices: [],
  },
}
