import React, { useMemo } from 'react';
import classnames from 'classnames';
import { Button } from 'antd';
import moment from 'moment';

import {
  identity,
  isEitherAdmin,
  isStartup,
  isAngelInvestor,
  parseNumberToPrice,
} from 'utils';
import { RequestRowProps, StatusType } from 'types/requests';
import { ResponseRequestType as AllRequestType } from 'packages/service_request_repository';
import { RenderIf, Text } from 'components/components';
import { STATUS_COLOR_CLASS } from '../RequestDetailsDrawer';
import { isRejected, isUnsent } from '../../utils';
import { formatSlashMDYHD } from '../../../utils/constants/formatters';
import RequestRowButtons from '../RequestRowButtons';

import './RequestRow.scss';

const RequestRow = (props: RequestRowProps) => {
  const { request, highlight, userType } = props;

  const isServiceRequest = useMemo(() => (
    identity.isObjWithChildren((request as AllRequestType).serviceRequest)
  ), [request]);

  const { id, startup, angelInvestor } = request;

  const requestData = useMemo(() => {
    let requestTitle = '';
    let rowDataLeft;
    let rowDataRight = '';
    let requestStatus: StatusType | 'all' = 'all';
    let date = '';
    let logo = '';
    let providerLogo = '';
    let requestId = 0;
    const dataType: 'service' | 'partner' = isServiceRequest ? 'service' : 'partner';

    if (identity.isObjWithChildren(request)) {
      logo = request?.startup?.name !== '' ? startup?.imageLink as string : angelInvestor?.imageLink as string;

      if (isServiceRequest) {
        const {
          itemCount, itemList, totalPrice, status, applicationDate, serviceProvider, id: sId,
        } = (request as AllRequestType).serviceRequest!;
        requestTitle = (itemCount <= 1 && identity.isPositiveNumber(itemCount)) ? itemList[0] : 'Request for Multiple services';
        rowDataRight = `${parseNumberToPrice(totalPrice, 'AED')}`;
        requestStatus = status as StatusType;
        providerLogo = serviceProvider?.imageLink || '';
        requestId = sId;
        rowDataLeft = `From ${startup.name}`;
        date = moment(applicationDate).format(formatSlashMDYHD);
      } else {
        const {
          subject, createdBy, status, applicationDate, partner, id: pId,
        } = (request as AllRequestType).partnerRequest!;
        const { firstName, lastName } = createdBy;
        requestTitle = subject;
        date = moment(applicationDate).format(formatSlashMDYHD);
        rowDataRight = startup.name;
        requestStatus = status as StatusType;
        providerLogo = partner.logo as string || '';
        requestId = pId;
        rowDataLeft = `From ${identity.isTruthyString(firstName) || identity.isTruthyString(lastName) ? `${firstName} ${lastName}` : ''}`;
      }
    }
    return ({
      requestTitle,
      date,
      requestId,
      requestStatus,
      rowDataLeft,
      rowDataRight,
      dataType,
      logo,
      providerLogo,
    });
  }, [request]);

  if (!identity.isObjWithChildren(request)) {
    return null;
  }

  const {
    date,
    requestId,
    requestStatus,
    rowDataLeft,
    rowDataRight,
    dataType,
    requestTitle,
    logo,
    providerLogo,
  } = requestData;

  return (
    <RenderIf condition={identity.isTruthyNumber(id)}>
      <div className={classnames('request-row', { 'highlighted-row': highlight })}>
        <div className="request-row__info-container">
          <div className="request-row__info-container--left">
            <RenderIf condition={isAngelInvestor(userType) || isStartup(userType) || isEitherAdmin(userType)}>
              <img src={providerLogo} alt="" className={classnames('request-row__info-container__logo', { lg: !isEitherAdmin(userType) })} />
            </RenderIf>
            <RenderIf condition={!isStartup(userType) && !isAngelInvestor(userType)}>
              <img
                src={logo}
                alt=""
                className={classnames('request-row__info-container__logo', { lg: !isEitherAdmin(userType) })}
              />
            </RenderIf>
          </div>
          <div className="request-row__info-container--right">
            <div className="request-row__status-container">
              <p className={classnames('request-row__status', STATUS_COLOR_CLASS(userType)[requestStatus])}>
                {requestStatus}
              </p>
              <RenderIf condition={isStartup(userType!) && isRejected(requestStatus) && isServiceRequest}>
                <Button className="request-row__denied-see-why">See Why?</Button>
              </RenderIf>
            </div>
            <div className="request-row__request-info">
              <Text text={requestTitle} size="xs" />
              <div className={classnames('request-row__request-info--row', { unsent: isUnsent(requestStatus) && isServiceRequest })}>
                <RenderIf condition={identity.isTruthyString(rowDataLeft)}>
                  <span className="request-row__request-info--row__details">{rowDataLeft}</span>
                  <div className="dot" />
                </RenderIf>
                <span className="request-row__request-info--row__details">{rowDataRight}</span>
              </div>
              <div className="request-row__request-info--row">
                <span className="request-row__request-info--row__details">{`R#${requestId}`}</span>
                <div className="dot" />
                <span className="request-row__request-info--row__details">{date}</span>
              </div>
            </div>
          </div>
        </div>
        <RequestRowButtons type={dataType} status={requestStatus} id={requestId} />
      </div>
    </RenderIf>
  );
}

RequestRow.defaultProps = {
  highlight: false,
  requestType: 'service',
};

export default RequestRow;
