import React, { useMemo } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';

import {
  identity,
  isEitherAdmin,
  isAngelInvestor,
  parseAmountPrice,
  parseNumberToPrice,
} from 'utils';
import { AngelInvestorWalletProps } from 'types/angelInvestors';
import { composeAngelInvestorWalletUrl } from 'routes/routeComposers';
import { angelInvestorWalletUrl } from 'routes/urls';
import routes from 'routes';
import { RenderIf, TitleWithActions } from 'components/components';
import { Amount, WalletGraph } from './components';
import StaticNotification from '../Notification/StaticNotification';

import './AngelInvestorWallet.scss';

const AngelInvestorWallet = (props: AngelInvestorWalletProps) => {
  const {
    wallet,
    showTransactionButton,
    includeGraph,
    angelInvestorId,
    userType,
    showInfo,
  } = props;

  const history = useHistory();

  const data = useMemo(() => {
    const balance = parseAmountPrice(wallet?.balance);
    const consumed = parseAmountPrice(wallet?.consumedAmount);
    const blocked = parseAmountPrice(wallet?.blockedAmount);
    return ([
      { id: 'balance', label: 'Balance', value: balance },
      { id: 'consumedAmount', label: 'Consumed', value: consumed },
      { id: 'blockedAmount', label: 'Blocked', value: blocked }]);
  }, [wallet]);

  const displayGraph = useMemo(() => (identity.isTruthyNumber(wallet?.consumedAmount)
    || identity.isTruthyNumber(wallet?.balance)), [wallet]);

  const percentageConsumed = useMemo(() => Math.round(
    (parseFloat(wallet.consumedAmount) / parseFloat(wallet.totalWalletAmount)) * 100,
  ), [wallet]);

  const notificationType = useMemo(() => {
    if (percentageConsumed < 50) {
      return 'info';
    }
    return percentageConsumed < 80 ? 'warning' : 'error';
  }, [percentageConsumed]);

  const walletData = useMemo(() => {
    const balance = parseNumberToPrice(parseAmountPrice(wallet.balance), 'AED');
    const total = parseNumberToPrice(parseAmountPrice(wallet.totalWalletAmount), 'AED');
    const consumed = parseNumberToPrice(parseAmountPrice(wallet.consumedAmount), 'AED');
    const blocked = parseNumberToPrice(parseAmountPrice(wallet.blockedAmount), 'AED');

    return {
      balance, total, consumed, blocked,
    };
  }, [wallet]);

  const message = useMemo(() => `Angel investor has consumed ${percentageConsumed}% of wallet, ${walletData.consumed}`, [percentageConsumed]);

  const onViewTransactionsButtonClick = () => {
    if (isEitherAdmin(userType)) {
      history.push(composeAngelInvestorWalletUrl(angelInvestorId!));
    } else {
      history.push(routes.walletUrl);
    }
  }

  const renderViewTransactionsButton = useMemo(() => [
    isEitherAdmin(userType) || isAngelInvestor(userType),
    showTransactionButton,
    identity.isPositiveNumber(angelInvestorId),
  ].every(Boolean), [angelInvestorId, showTransactionButton, userType]);

  return (
    <div className="angel-investor-wallet">
      <div className="angel-investor-wallet__header">
        <TitleWithActions title="Wallet" />
        <RenderIf condition={renderViewTransactionsButton}>
          <Button className="secondary-button" onClick={onViewTransactionsButtonClick}>
            View transactions
          </Button>
        </RenderIf>
      </div>
      <div className="angel-investor-wallet__container">
        <div
          className={
            classnames(
              'angel-investor-wallet__amount-info',
              { 'angel-investor-wallet__amount-info--profile-view': includeGraph },
            )
          }
          style={{ flexDirection: angelInvestorWalletUrl === history.location.pathname ? 'row' : 'column' }}
        >
          <Amount name="balance" amount={walletData.balance} />
          <Amount name="total" amount={walletData.total} />
          <Amount name="consumed" amount={walletData.consumed} />
          <Amount name="blocked" amount={walletData.blocked} />
        </div>
        <RenderIf condition={includeGraph! && displayGraph}>
          <WalletGraph data={data} wallet={wallet} />
        </RenderIf>
      </div>
      <RenderIf condition={showInfo}>
        <StaticNotification message={message} type={notificationType} />
      </RenderIf>
    </div>
  );
}

AngelInvestorWallet.defaultProps = {
  showTransactionButton: false,
  includeGraph: false,
  showInfo: false,
};

export default AngelInvestorWallet;
