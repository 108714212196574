import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

import { identity, parseNumberToPrice } from 'utils';
import { IncentiveAgreementDetailsDrawerProps } from 'types/angelInvestors/editAngelInvestor';
import { Input } from 'components/components';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getAngelInvestorFile } from '../../utils';
import { DetailsDrawerRow, AIIAUploadForm } from '..';

import './IncentiveAgreementDetailsDrawer.scss';

const IncentiveAgreementDetailsDrawer = (props: IncentiveAgreementDetailsDrawerProps) => {
  const { incentiveAgreement, angelInvestorID, incentiveAgreementStatus } = props;
  const {
    startDate: iaStartDate,
    endDate: iaEndDate,
    file,
    walletAmount,
  } = incentiveAgreement;

  useEffect(() => {
    if (identity.isPositiveNumber(angelInvestorID)) {
      props.actions.getIncentiveAgreementById();
    }
  }, [angelInvestorID]);

  const isApproved = useMemo(() => incentiveAgreementStatus === 1, [incentiveAgreementStatus]);
  const startDate = useMemo(() => (identity.isTruthyString(iaStartDate) ? moment(iaStartDate).format(formatSlashMDY) : 'Not specified'), [iaStartDate]);
  const endDate = useMemo(() => (identity.isTruthyString(iaEndDate) ? moment(iaEndDate).format(formatSlashMDY) : 'Not specified'), [iaEndDate]);

  if (!isApproved) {
    return <AIIAUploadForm incentiveAgreement={incentiveAgreement} angelInvestorID={angelInvestorID} />;
  }

  return (
    <div className="incentive-agreement-details-drawer">
      <DetailsDrawerRow label="Start Date" item={startDate} />
      <DetailsDrawerRow label="End Date" item={endDate} />
      <DetailsDrawerRow label="Wallet Amount" item={parseNumberToPrice(walletAmount, 'AED')} />
      <Input.DraggerUpload
        previewType="image"
        defaultFileList={[...getAngelInvestorFile(file)]}
        isRoundableButton
      />
    </div>
  );
}

export default IncentiveAgreementDetailsDrawer;
