import { ServicesStateType } from 'types/reduxTypes/StateTypes';
import { ServicesFiltersType, ServicesType } from 'types/services';
import { defaultServiceProvider } from './ServiceProvidersDefaults';

export const defaultService: ServicesType = {
  id: -1,
  serviceProvider: defaultServiceProvider,
  name: '',
  about: '',
  category: '',
  priceFrom: '',
  priceTo: '',
  priceType: 'per-hour',
  type: '',
  offerPrice: 'range',
  offerDocLink: '',
  additionalOptionName: '',
  additionalOptionPrice: '',
  pricingOptions: [],
  servicePrice: { unitID: 0, id: 0 },
  startup: { id: 0, name: '' },
  angelInvestor: { id: 0, name: '' },
  serviceStatus: 'new',
  categoryId: 0,
}

export const defaultServiceFilters: ServicesFiltersType = {
  categoryId: undefined,
  priceTypeId: undefined,
  from: undefined,
  to: undefined,
  serviceProviderId: undefined,
}

export const defaultServices: ServicesStateType = {
  filtersActive: false,
  services: [],
  isLoading: false,
  selectedServiceProvider: -1,
  details: {
    service: defaultService,
  },
  filters: defaultServiceFilters,
  insuranceTypesOptions: [],
  activeTab: 'services',
  counter: { servicesCount: 0 },
  servicesFilterTab: 'All',
  servicesSubCategoryFilterTab: 0,
  units: [],
  subCategories: [],
  categories: [],
}
