import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { createIncentiveAgreement, getAngelInvestorById, updateIncentiveAgreement } from 'redux/actions/angelInvestors';
import { setDrawerBody } from 'redux/actions/workspace';
import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setDrawerBody,
    createIncentiveAgreement,
    updateIncentiveAgreement,
    getAngelInvestorById,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
