import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { RequestFiltersState } from 'types/requests';

export const types = {
  SET_TYPE: 'SET_TYPE',
  SET_USERS_TYPE: 'SET_USERS_TYPE',
  SET_SERVICE_PROVIDERS: 'SET_SERVICE_PROVIDERS',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_AMOUNT: 'SET_AMOUNT',
  SET_PARTNERS: 'SET_PARTNERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_STARTUPS: 'SET_STARTUPS',
  SET_ANGEL_INVESTORS: 'SET_ANGEL_INVESTORS',
  SET_ALL_FILTERS: 'SET_ALL_FILTERS',
}

export const resetFilters = (payload: object = {}) => ({
  type: types.RESET_FILTERS,
  payload,
});

export const setType = (payload: DataPayload<'all' | 'service' | 'partner'>) => ({
  type: types.SET_TYPE,
  payload,
});

export const setUsersType = (payload: DataPayload<'angel-investor' | 'startup'>) => ({
  type: types.SET_USERS_TYPE,
  payload,
});

export const setServiceProviders = (payload: DataPayload<number[]>) => ({
  type: types.SET_SERVICE_PROVIDERS,
  payload,
});

export const setCategory = (payload: DataPayload<number[]>) => ({
  type: types.SET_CATEGORY,
  payload,
});

export const setAmount = (payload: DataPayload<{ field: 'to' | 'from', value: number }>) => ({
  type: types.SET_AMOUNT,
  payload,
});

export const setPartners = (payload: DataPayload<number[]>) => ({
  type: types.SET_PARTNERS,
  payload,
});

export const setStartups = (payload: DataPayload<number[]>) => ({
  type: types.SET_STARTUPS,
  payload,
});

export const setAngelInvestors = (payload: DataPayload<number[]>) => ({
  type: types.SET_ANGEL_INVESTORS,
  payload,
});

export const setAllFilters = (payload: DataPayload<RequestFiltersState>) => ({
  type: types.SET_ALL_FILTERS,
  payload,
});
