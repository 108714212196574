import React from 'react';

import { ProfileProps } from 'types/user';
import { ROLES } from 'utils';
import EditAIMemberProfile from 'components/EditAIMemberProfile';
import EditMemberProfile from '../../EditMemberProfile';
import { EditPartnerMember, EditHub71Admin } from './components';

import './EditMyProfile.scss';

const EditMyProfile = (props: ProfileProps) => {
  const { userType } = props;

  switch (userType) {
    case ROLES.angelInvestor: {
      return (
        <div className="content-container">
          <EditAIMemberProfile />
        </div>
      );
    }
    case ROLES.startupFounder:
    case ROLES.startupTeamMember: {
      return (
        <div className="content-container">
          <EditMemberProfile />
        </div>
      );
    }
    case ROLES.partner:
    case ROLES.serviceProvider:
    case ROLES.superAdmin: {
      return (
        <div className="content-container">
          <EditPartnerMember />
        </div>
      );
    }
    case ROLES.admin: {
      return (
        <div className="content-container">
          <EditHub71Admin />
        </div>
      );
    }
    default: {
      return null;
    }
  }
}

export default EditMyProfile;
