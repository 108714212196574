import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectAngelInvestorDetails } from 'redux/selectors/angelInvestors';
import {
  getAngelInvestorById,
  updateAngelInvestorById,
} from 'redux/actions/angelInvestors';
import { selectUserAngelInvestorId, selectUserType } from 'redux/selectors/auth';
import { setDrawerBody, setIsDrawerOpen } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  selectedAngelInvestor: selectAngelInvestorDetails(state),
  userAngelInvestorId: selectUserAngelInvestorId(state),
  userType: selectUserType(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAngelInvestorById,
    updateAngelInvestorById,
    setDrawerBody,
    setIsDrawerOpen,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
