import { connect } from 'react-redux';

import { StateType } from 'types';
import { selectStartupStatus, selectUserType, selectAngelInvestorStatus } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  startupStatus: selectStartupStatus(state),
  angelInvestorStatus: selectAngelInvestorStatus(state),
});

export default connect(mapStateToProps);
