import {
  all, call, put, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  GetAIWalletQueryParams,
  GetAIWalletTransactionsQueryParams,
  getAIWallet,
  getAIWalletTransactions,
  getAIWalletTransactionsCount,
} from 'packages/ai_wallet_repository';
import { identity } from 'utils';
import showNotification from 'services/utils/showNotification';
import {
  getAIWalletCount,
  getAIWalletTransactions as getAIWalletTransactionsList,
  setIsLoading,
  setAIWallet,
  setAIWalletCount,
  setAIWalletTransactions,
  types,
} from '../../actions/ai_wallet';

function * handleGetAIWallet(action: PayloadAction<GetAIWalletQueryParams>) {
  yield put(setIsLoading({ isLoading: true }));
  const queryParams = action.payload;
  const { data: wallet, error, httpStatus } = yield call(getAIWallet, queryParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching wallet information: ${error?.message}`, true, httpStatus);
  } else if (identity.isObjWithChildren(wallet)) {
    yield all([
      put(setAIWallet({ wallet })),
      put(getAIWalletTransactionsList({
        wallet_id: wallet.id,
        $preload: 1,
        $limit: queryParams.limit,
        $offset: queryParams.offset,
        $order: '-1',
      })),
      put(getAIWalletCount({ wallet_id: wallet.id })),
    ]);
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetAIWalletTransactions(action: PayloadAction<GetAIWalletTransactionsQueryParams>) {
  yield put(setIsLoading({ isLoading: true }));

  const queryParams = action.payload;
  queryParams.$preload = 1;
  const { data: walletTransactions, error, httpStatus } = yield call(getAIWalletTransactions, queryParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching wallet transactions: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setAIWalletTransactions({ walletTransactions }));
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetAIWalletCount(action: PayloadAction<GetAIWalletTransactionsQueryParams>) {
  const queryParams = action.payload;
  const { data, error, httpStatus } = yield call(getAIWalletTransactionsCount, queryParams);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Error fetching wallet transactions count: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setAIWalletCount({ data }));
  }
}

export default function * walletSagas() {
  yield all([
    takeLatest(types.GET_AI_WALLET, handleGetAIWallet),
    takeLatest(types.GET_AI_WALLET_TRANSACTIONS, handleGetAIWalletTransactions),
    takeLatest(types.GET_AI_WALLET_COUNT, handleGetAIWalletCount),
  ]);
}
