import httpClient, {
  ApiResponseType,
  GetResourceSuccessResponseType,
  HttpRequestConfig,
  NumberResponseType,
  queryBuilder,
} from 'packages/http_client';
import { AI_WALLET_API, AI_WALLET_TRANSACTION_API } from './utils';
import {
  GetAmountSumsBe,
  GetAmountSumsBySpAndAngelInvestorQuery,
  GetAmountSumsResponse,
  GetAIWalletQueryParams,
  GetAIWalletTransactionResponse,
  GetAIWalletTransactionsQueryParams,
  ResponseAIWalletTransactionType,
  AIWalletTransactionType,
  AIWalletType,
} from './types';
import {
  convertGetAmountSumsResponse,
  convertGetAIWalletTransactionListResponse,
} from './utils/converters';

const getAIWalletApi = async (params: GetAIWalletQueryParams) => {
  const url = AI_WALLET_API;
  const config: HttpRequestConfig = {
    params: queryBuilder({ angel_investor_id: params.angel_investor_id }).toObject(),
  };

  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<AIWalletType[]>>>(url, config);
};

const getAIWalletTransactionsApi = async (params: GetAIWalletTransactionsQueryParams) => {
  const url = AI_WALLET_TRANSACTION_API;
  const config: HttpRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetAIWalletTransactionResponse>>(url, config);
};

const getAmountSumsBySpAndAngelInvestor = async (params: GetAmountSumsBySpAndAngelInvestorQuery) => {
  const url = AI_WALLET_TRANSACTION_API;
  const config: HttpRequestConfig = {
    params: queryBuilder({
      service_provider_id: params.serviceProviderId,
      angel_investor_id: params.angelInvestorId,
      hide: 0,
    })
      .select(['type', { field: 'amount', aggregation: 'sum' }])
      .in({ type: [1, 3] })
      .groupBy('type')
      .toObject(),
  }
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<GetAmountSumsBe[]>>>(url, config);
}

export const getAIWallet = async (queryParams: GetAIWalletQueryParams): Promise<ApiResponseType<AIWalletType>> => {
  const response = await getAIWalletApi(queryParams);
  const { data, error, httpStatus } = response;
  return { data: data?.result![0], error, httpStatus };
};

export const getAIWalletTransactions = async (queryParams: GetAIWalletTransactionsQueryParams):
  Promise<ApiResponseType<AIWalletTransactionType[]>> => {
  const response = await getAIWalletTransactionsApi(queryParams);
  const { data, error, httpStatus } = response;
  return {
    data: convertGetAIWalletTransactionListResponse(data?.result as ResponseAIWalletTransactionType[]),
    error,
    httpStatus,
  };
};

export const getAIWalletTransactionsCount = async (query: GetAIWalletTransactionsQueryParams):
  Promise<NumberResponseType> => {
  const response = await getAIWalletTransactionsApi({ ...query, $f: 'id__count' });
  const { data, error, httpStatus } = response;
  return { data: (data?.result! as { id__count: number }[])[0].id__count, error, httpStatus };
}

export const getAmountSumsBySpAndAngelInvestorApi = async (query: GetAmountSumsBySpAndAngelInvestorQuery):
  Promise<ApiResponseType<GetAmountSumsResponse>> => {
  const response = await getAmountSumsBySpAndAngelInvestor(query);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAmountSumsResponse(data?.result) };
}
