import React, { useMemo } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { RenderIf, Text } from 'components/components';
import { STATUS_COLOR_CLASS } from 'components/RequestsOverview/components/RequestDetailsDrawer';
import {
  identity,
  isStartup,
  parseNumberToPrice,
} from 'utils';
import { StatusType } from 'types/requests';
import { isUnsent, isRejected } from 'components/RequestsOverview/utils';
import RequestRowButtons from 'components/RequestsOverview/components/RequestRowButtons';
import { formatSlashMDYHD } from '../../../../utils/constants/formatters';

import './RequestCard.scss';

const RequestCard = (props: any) => {
  const getStatusType = (status: any) => {
    if (status === 1) {
      return 'unsent'
    }
    if (status === 2) {
      return 'new'
    }
    if (status === 3) {
      return 'quoted'
    }
    if (status === 4) {
      return 'pendingHUB71'
    }
    if (status === 5) {
      return 'inProgress'
    }
    if (status === 6) {
      return 'rejected'
    }
    if (status === 7) {
      return 'delivered'
    }
  }
  const serviceRequest = props?.request?.serviceRequest!
  const startup = props?.request?.startup!

  const requestData = useMemo(() => {
    let requestTitle = '';
    let rowDataLeft;
    let rowDataRight = '';
    let requestStatus: StatusType | 'all' = 'all';
    let date = '';
    let startupLogo = '';
    let providerLogo = '';
    let requestId = 0;
    const dataType: 'service' | 'partner' = 'service';

    if (identity.isObjWithChildren(props?.request)) {
      startupLogo = startup?.logo as string || ''
      requestTitle = serviceRequest?.itemList;
      rowDataRight = `${parseNumberToPrice(serviceRequest?.totalPrice, 'AED')}`;
      requestStatus = serviceRequest?.status as StatusType;
      providerLogo = serviceRequest?.serviceProvider?.logo || '';
      requestId = serviceRequest?.id;
      rowDataLeft = `From ${startup?.name}`;
      date = moment(props?.request?.applicationDate).format(formatSlashMDYHD);
    }
    return ({
      requestTitle,
      date,
      requestId,
      requestStatus,
      rowDataLeft,
      rowDataRight,
      dataType,
      startupLogo,
      providerLogo,
    });
  }, [props.request]);

  if (!identity.isObjWithChildren(props?.request)) {
    return null;
  }

  const {
    date,
    requestId,
    requestStatus,
    rowDataLeft,
    rowDataRight,
    requestTitle,
    startupLogo,
    providerLogo,
  } = requestData;

  return (
    <RenderIf condition={identity.isTruthyNumber(requestId)}>
      <div className="request-row">
        <div className="request-row__info-container">
          <div className="request-row__info-container--left">
            <img src={providerLogo} alt="" className={classnames('request-row__info-container__logo', { lg: false })} />
            <img src={startupLogo} alt="" className={classnames('request-row__info-container__logo', { lg: false })} />
          </div>
          <div className="request-row__info-container--right">
            <div className="request-row__status-container">
              <p className={classnames('request-row__status', STATUS_COLOR_CLASS(props?.userType)[getStatusType(requestStatus)!])}>
                {getStatusType(requestStatus)}
              </p>
              <RenderIf condition={isStartup('Startup founder') && isRejected(requestStatus)}>
                <Button className="request-row__denied-see-why">See Why?</Button>
              </RenderIf>
            </div>
            <div className="request-row__request-info">
              <Text text={requestTitle} size="xs" />
              <div className={classnames('request-row__request-info--row', { unsent: isUnsent(requestStatus) })}>
                <RenderIf condition={identity.isTruthyString(rowDataLeft)}>
                  <span className="request-row__request-info--row__details">{rowDataLeft}</span>
                  <div className="dot" />
                </RenderIf>
                <span className="request-row__request-info--row__details">{rowDataRight}</span>
              </div>
              <div className="request-row__request-info--row">
                <span className="request-row__request-info--row__details">{`R#${requestId}`}</span>
                <div className="dot" />
                <span className="request-row__request-info--row__details">{date}</span>
              </div>
            </div>
          </div>
        </div>
        <RequestRowButtons type="service" status={requestStatus} id={requestId} />
      </div>
    </RenderIf>
  );
}

export default RequestCard;
