import httpClient, { ApiResponseType, DeleteResourceSuccessResponseType, HttpRequestConfig } from 'packages/http_client';
import { EditTemplateDataType } from 'types/reduxTypes/ActionTypes/SettingsOverviewTypes';
import {
  AddTemplateType,
  CreateTemplateApiResponseType,
  GetTemplateApiRequestParams,
  GetTemplateByIdApiResponseType,
  GetTemplateByIdResponse,
  GetTemplatesListApiResponseType,
  GetTemplatesListResponse,
  TemplateParams,
  TemplateTypeEnum,
} from './types';
import { TEMPLATE_API } from './utils/constants';
import {
  convertGetTemplateResponse,
  convertGetTemplatesListResponse,
  convertTemplateRequestPayload,
  convertUpdateTemplateRequestPayload,
} from './utils/converters';

const getTemplatesApi = (params: GetTemplateApiRequestParams) => {
  const url = TEMPLATE_API;
  const config: HttpRequestConfig = {
    params,
  };
  return httpClient.get<ApiResponseType<GetTemplatesListApiResponseType>>(url, config);
};

const getIncentiveTemplatesApi = () => {
  const url = TEMPLATE_API;
  const config: HttpRequestConfig = {};
  return httpClient.get<ApiResponseType<GetTemplatesListApiResponseType>>(url, config);
};

const getTemplateByIdApi = (id: number) => {
  const url = `${TEMPLATE_API}/${id}`;
  return httpClient.get<ApiResponseType<GetTemplateByIdApiResponseType>>(url);
};

export const getIncentiveAgreementTemplates = async (): Promise<GetTemplatesListResponse> => {
  // const incentiveAgreementCode = TemplateTypeEnum['Incentive Agreement'];
  // const requestParams: GetTemplateApiRequestParams = {
  //   type: incentiveAgreementCode,
  // };
  const response = await getIncentiveTemplatesApi();
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetTemplatesListResponse(data) };
};

export const getAcceptanceLetterTemplates = async (): Promise<GetTemplatesListResponse> => {
  const acceptanceLetterCode = TemplateTypeEnum['Acceptance Letter'];
  const requestParams: GetTemplateApiRequestParams = {
    type: acceptanceLetterCode,
  };
  const response = await getTemplatesApi(requestParams);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetTemplatesListResponse(data) };
};

export const getWorkOrderTemplates = async (): Promise<GetTemplatesListResponse> => {
  const incentiveAgreementCode = TemplateTypeEnum.Other;
  const requestParams: GetTemplateApiRequestParams = {
    type: incentiveAgreementCode,
  };
  const response = await getTemplatesApi(requestParams);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: convertGetTemplatesListResponse(data) };
};

const createTemplateApi = (payload: AddTemplateType) => {
  const url = TEMPLATE_API;
  const requestPayload = convertTemplateRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateTemplateApiResponseType>>(url, requestPayload);
};

export const createTemplate = async (payload: AddTemplateType) => {
  const response = await createTemplateApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

const deleteTemplatesApi = (params?: TemplateParams) => {
  const url = TEMPLATE_API;
  const config = { params };
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url, config);
}

export const deleteTemplates = async (params?: TemplateParams) => {
  const response = await deleteTemplatesApi(params);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

const deleteTemplateByIdApi = (id: number) => {
  const url = `${TEMPLATE_API}/${id}`;
  return httpClient.delete<ApiResponseType<DeleteResourceSuccessResponseType>>(url);
};

export const deleteTemplateById = async (id: number) => {
  const response = await deleteTemplateByIdApi(id);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

const updateTemplateByIdApi = (id: number, payload: EditTemplateDataType) => {
  const url = `${TEMPLATE_API}/${id}`;
  const requestPayload = convertUpdateTemplateRequestPayload(payload);
  return httpClient.put<ApiResponseType<CreateTemplateApiResponseType>>(url, requestPayload);
};

export const updateTemplateById = async (id: number, payload: EditTemplateDataType) => {
  const response = await updateTemplateByIdApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
}

export const getTemplateById = async (id: number)
: Promise<GetTemplateByIdResponse> => {
  const response = await getTemplateByIdApi(id);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data:  convertGetTemplateResponse(data?.result!) }
}
