import React from 'react';

import { Navbar } from 'components/LayoutWithSidebar/components';
import AddAIMissingDetails from 'components/AddAIMissingDetails';

const AddAIMissingDetailsWithNavbar = () => (
  <div>
    <Navbar />
    <AddAIMissingDetails />
  </div>
);

export default AddAIMissingDetailsWithNavbar;
