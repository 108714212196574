import produce from 'immer';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { StartupType } from 'packages/startup_repository';
import { AngelInvestorType } from 'packages/angel_investors_repository';
import { ServiceProviderType } from 'packages/service_repository';
import { PartnerType } from 'packages/partner_repository';
import {
  SetAuthPayload,
  AuthActionType,
  AuthStateType,
  SetLoadingPayload, MyProfileType,
} from 'types/auth';
import authDefaultState from '../../../utils/defaultStates/auth';
import { types } from '../../actions/auth';

const defaultState: AuthStateType = authDefaultState;

const handleLoginSuccess = (state: AuthStateType, payload: SetAuthPayload) => {
  state.user = payload.user;
  state.isAuthenticated = true;
  return state;
}

const handleSetLoading = (state: AuthStateType, payload: SetLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleAuthenticationChecked = (state: AuthStateType) => {
  state.isAuthenticationChecked = true;
  return state;
}

const handleSetStartupStatus = (state: AuthStateType, payload: DataPayload<string>) => {
  state.startupStatus = payload.data;
  return state;
}

const handleSetStartup = (state: AuthStateType, payload: DataPayload<StartupType>) => {
  state.startup = payload.data;
  return state;
}

const handleSetAngelInvestorStatus = (state: AuthStateType, payload: DataPayload<string>) => {
  state.angelInvestorStatus = payload.data;
  return state;
}

const handleSetAngelInvestor = (state: AuthStateType, payload: DataPayload<AngelInvestorType>) => {
  state.angelInvestor = payload.data;
  return state;
}

const handleSetServiceProvider = (state: AuthStateType, payload: DataPayload<ServiceProviderType>) => {
  state.user.serviceProvider = payload.data;
  return state;
}

const handleSetPartner = (state: AuthStateType, payload: DataPayload<PartnerType>) => {
  state.partner = payload.data;
  return state;
}

const handleResetUserNotice = (state: AuthStateType) => {
  state.user.notice = undefined;
  return state;
}

const handleSetMyProfileData = (state: AuthStateType, payload: DataPayload<MyProfileType>) => {
  state.myProfile = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: AuthStateType = defaultState, action: AuthActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_STARTUP_STATUS: {
      return handleSetStartupStatus(state, <DataPayload<string>>payload);
    }
    case types.SET_ANGEL_INVESTOR_STATUS: {
      return handleSetAngelInvestorStatus(state, <DataPayload<string>>payload);
    }
    case types.LOGIN_SUCCESS: {
      return handleLoginSuccess(state, <SetAuthPayload>payload);
    }
    case types.SET_IS_AUTH_LOADING: {
      return handleSetLoading(state, <SetLoadingPayload>payload);
    }
    case types.SET_AUTHENTICATION_CHECKED: {
      return handleAuthenticationChecked(state);
    }
    case types.SET_STARTUP: {
      return handleSetStartup(state, <DataPayload<StartupType>>payload);
    }
    case types.SET_ANGEL_INVESTOR: {
      return handleSetAngelInvestor(state, <DataPayload<AngelInvestorType>>payload);
    }
    case types.SET_SERVICE_PROVIDER: {
      return handleSetServiceProvider(state, <DataPayload<ServiceProviderType>>payload);
    }
    case types.SET_PARTNER: {
      return handleSetPartner(state, <DataPayload<PartnerType>>payload);
    }
    case types.RESET_AUTH: {
      return defaultState;
    }
    case types.RESET_USER_NOTICE: {
      return handleResetUserNotice(state);
    }
    case types.SET_MY_PROFILE_DATA: {
      return handleSetMyProfileData(state, <DataPayload<MyProfileType>>payload);
    }
    default:
      return state;
  }
});
