import { CohortStateType } from 'types/reduxTypes/StateTypes';
import { CohortType } from 'types/cohorts/cohortOverview';

export const defaultSelectedCohort: CohortType = {
  id: -1,
  name: '',
  from: '',
  to: '',
  type: '',
  about: '',
  amount: '',
  startups: 0,
  acceptanceLetterTemplate: '',
  incentiveAgreementTemplate: 0,
  selectionFrom: '',
  selectionTo: '',
  reviewStartups: [],
}

export const cohortDefaultState: CohortStateType = {
  cohorts: [],
  cohortsCount: 0,
  selectedCohort: {
    data: defaultSelectedCohort,
    startups: [],
  },
  programs: [],
  isLoading: false,
  programCohorts: [],
};
