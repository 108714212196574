import { API_ROOT } from 'packages/http_client';

export const AI_WALLET_API = `${API_ROOT}/walletamountutilization`;

export const AI_WALLET_TRANSACTION_API = `${API_ROOT}/wallettransaction`;

export const NUMBER_TO_AI_WALLET_TRANSACTION_TYPE_MAP: {[key: number]: string} = {
  0: ' - ',
  1: 'Withdraw',
  2: 'Deposit',
  3: 'Block',
  4: 'Unblock',
};
