import produce from 'immer';

import {
  IncentiveAgreementType,
  InvestmentStageType,
  StageType,
} from 'packages/angel_investors_repository';
import { checkAngelInvestorFiltersActive, getLabelValueObject, identity } from 'utils';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { AngelInvestorsStateType } from 'types/reduxTypes/StateTypes';
import {
  AngelInvestorActionType,
  SetAngelInvestorsPayload,
  SetAngelInvestorByIdPayload,
  SetAngelInvestorServiceProviderPayload,
  SetAngelInvestorCountPayload,
  SetAngelInvestorForReviewPayload,
} from 'types/reduxTypes/ActionTypes/AngelInvestorsTypes';
import { AngelInvestorFiltersType } from 'types/angelInvestors/angelInvestorFilters';
import { ChangeRequestStatusPayload, SetRequestsPayload } from 'types/reduxTypes/ActionTypes/RequestsTypes';
import { SetTeamMembersPayload } from 'types/reduxTypes/ActionTypes/TeamMemberTypes';
import { StatusType } from 'types/requests';
import { ADGMLicenseType } from 'packages/angel_investors_repository/types/adgm_license';
import { ServiceProviderType } from 'types/serviceProviders';
import { GetAmountSumsResponse } from 'packages/wallet_repository';
import { changeRequestStatusHelper, angelInvestorsDefaultState } from '../../utils';
import { types } from '../../actions/angelInvestors';

const defaultState: AngelInvestorsStateType = angelInvestorsDefaultState;

const handleSetSumsForSP = (state: AngelInvestorsStateType, payload: DataPayload<GetAmountSumsResponse[]>) => {
  state.details.serviceProviders = state.details.serviceProviders.map((sp) => {
    const serviceProvider = payload.data.find(({ serviceProviderId }) => serviceProviderId === sp.id);

    if (identity.isObjWithChildren(serviceProvider)) {
      sp.amountConsumed = serviceProvider!.amountConsumed;
      sp.amountBlocked = serviceProvider!.amountBlocked;
    }

    return sp;
  });

  return state;
}

const handleSetAngelInvestorServiceProviders = (
  state: AngelInvestorsStateType,
  payload: DataPayload<ServiceProviderType[]>,
) => {
  state.details.serviceProviders = payload.data;
  state.details.serviceProvidersCount = payload.data.length;
  return state;
}

const handleSetAngelInvestors = (state: AngelInvestorsStateType, payload: SetAngelInvestorsPayload) => {
  state.angelInvestors = payload.angelInvestors;
  state.filtersActive = checkAngelInvestorFiltersActive(state.filters);
  return state;
}

const handleSetAngelInvestorById = (state: AngelInvestorsStateType, payload: SetAngelInvestorByIdPayload) => {
  state.details.data = payload.angelInvestor;
  return state;
}

const handleSetAngelInvestorRequests = (state: AngelInvestorsStateType, payload: SetRequestsPayload) => {
  state.details.requests = payload.requests;
  return state;
}

const handleSetRequestStatusChange = (state: AngelInvestorsStateType, payload: ChangeRequestStatusPayload) => {
  const { id, status } = payload;
  const { details: { requests } } = state;
  state.details.requests = changeRequestStatusHelper(id, requests, status as StatusType);
  return state;
}

const handleSetAngelInvestorMembers = (state: AngelInvestorsStateType, payload: SetTeamMembersPayload) => {
  state.details.teamMembers = payload.members;
  return state;
}

const handleSetServiceProvider = (state: AngelInvestorsStateType, payload: SetAngelInvestorServiceProviderPayload) => {
  state.details.serviceProvider = payload.serviceProvider;
  return state;
}

const handleSetStageOptions = (state: AngelInvestorsStateType, payload: DataPayload<StageType[]>) => {
  state.stageOptions = payload.data?.map((stage) => getLabelValueObject(stage.name, stage.id));
  return state;
}

const handleSetInvestmentStageOptions = (
  state: AngelInvestorsStateType,
  payload: DataPayload<InvestmentStageType[]>,
) => {
  state.investmentStageOptions = payload.data?.map((stage) => getLabelValueObject(stage.name, stage.id));
  return state;
}

const handleSetLoading = (state: AngelInvestorsStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
}

const handleSetAngelInvestorCount = (state: AngelInvestorsStateType, payload: SetAngelInvestorCountPayload) => {
  state.angelInvestorCount = payload.angelInvestorCount;
  return state;
}

const handleSetAngelInvestorMembersCount = (state: AngelInvestorsStateType, payload: DataPayload<number>) => {
  state.details.teamMembersCount = payload.data;
  return state;
}

const handleSetADGMLicenseByAngelInvestorId = (
  state: AngelInvestorsStateType,
  payload: DataPayload<ADGMLicenseType>,
) => {
  state.adgmLicenseByAngelInvestorId = payload.data;
  return state;
}

const handleSetAngelInvestorId = (state: AngelInvestorsStateType, payload: DataPayload<number>) => {
  state.angelInvestorId = payload.data;
  return state;
}

const handleSetAngelInvestorForReview = (state: AngelInvestorsStateType, payload: SetAngelInvestorForReviewPayload) => {
  state.selectedAngelInvestorForReview = payload.angelInvestor;
  return state;
}

const handleSetIncentiveAgreementById = (
  state: AngelInvestorsStateType,
  payload: DataPayload<IncentiveAgreementType>,
) => {
  state.incentiveAgreement = payload.data;
  return state;
}

const handleSetAngelInvestorFilters = (state: AngelInvestorsStateType, payload: AngelInvestorFiltersType) => {
  state.filters = payload;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: AngelInvestorsStateType = defaultState, action: AngelInvestorActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ADGM_LICENSE_BY_ANGEL_INVESTOR_ID: {
      return handleSetADGMLicenseByAngelInvestorId(state, <DataPayload<ADGMLicenseType>>payload);
    }
    case types.SET_ANGEL_INVESTORS: {
      return handleSetAngelInvestors(state, <SetAngelInvestorsPayload>payload);
    }
    case types.SET_ANGEL_INVESTORS_COUNT: {
      return handleSetAngelInvestorCount(state, <SetAngelInvestorCountPayload>payload);
    }
    case types.SET_ANGEL_INVESTOR_BY_ID: {
      return handleSetAngelInvestorById(state, <SetAngelInvestorByIdPayload>payload);
    }
    case types.SET_ANGEL_INVESTOR_REQUESTS: {
      return handleSetAngelInvestorRequests(state, <SetRequestsPayload>payload);
    }
    case types.SET_REQUEST_STATUS_CHANGE: {
      return handleSetRequestStatusChange(state, <ChangeRequestStatusPayload>payload);
    }
    case types.SET_ANGEL_INVESTOR_MEMBERS: {
      return handleSetAngelInvestorMembers(state, <SetTeamMembersPayload>payload);
    }
    case types.SET_ANGEL_INVESTOR_SERVICE_PROVIDER_BY_ID: {
      return handleSetServiceProvider(state, <SetAngelInvestorServiceProviderPayload>payload);
    }
    case types.SET_ANGEL_INVESTORS_IS_LOADING: {
      return handleSetLoading(state, <IsLoadingPayload>payload);
    }
    case types.SET_ANGEL_INVESTOR_STAGES_OPTIONS: {
      return handleSetStageOptions(state, <DataPayload<StageType[]>>payload);
    }
    case types.SET_INVESTMENT_STAGE_OPTIONS: {
      return handleSetInvestmentStageOptions(state, <DataPayload<InvestmentStageType[]>>payload);
    }
    case types.SET_ANGEL_INVESTOR_TEAM_MEMBERS_COUNT: {
      return handleSetAngelInvestorMembersCount(state, <DataPayload<number>>payload);
    }
    case types.SET_ANGEL_INVESTOR_ID: {
      return handleSetAngelInvestorId(state, <DataPayload<number>>payload);
    }
    case types.SET_ANGEL_INVESTOR_FOR_REVIEW: {
      return handleSetAngelInvestorForReview(state, <SetAngelInvestorForReviewPayload>payload);
    }
    case types.SET_ANGEL_INVESTOR_INCENTIVE_AGREEMENT_BY_ID: {
      return handleSetIncentiveAgreementById(state, <DataPayload<IncentiveAgreementType>>payload);
    }
    case types.SET_ANGEL_INVESTOR_FILTERS: {
      return handleSetAngelInvestorFilters(state, <AngelInvestorFiltersType>payload);
    }
    case types.SET_ANGEL_INVESTOR_SERVICE_PROVIDERS: {
      return handleSetAngelInvestorServiceProviders(state, <DataPayload<ServiceProviderType[]>>payload);
    }
    case types.SET_SUMS_FOR_SERVICE_PROVIDERS: {
      return handleSetSumsForSP(state, <DataPayload<GetAmountSumsResponse[]>>payload);
    }
    case types.RESET_ANGEL_INVESTORS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
