import { MemberType, AngelInvestorMemberType } from 'types/memberDetails';
import { MembersStateType, AngelInvestorMembersStateType } from 'types/reduxTypes/StateTypes';
import { UpcomingEventFilter } from 'components/MemberDetails/utils/eventTypes';
import { GenderType, LocationType } from 'types/people';

const defaultGender: GenderType = 'Male';

const defaultLocation: LocationType = {
  city: '',
  country: '',
};

export const defaultMember: MemberType = {
  userId: -1,
  startupId: -1,
  id: -1,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  company: '',
  location: defaultLocation,
  role: '',
  gender: defaultGender,
  nationality: '',
  dateOfBirth: '',
  about: '',
  imageLink: '',
  founder: 0,
  skills: [],
  hobbies: [],
  jobFunctions: [],
  nationalityId: 0,
  passport: '',
  visa: '',
  visaProof: '',
}

export const defaultAngelInvestorMember: AngelInvestorMemberType = {
  userId: -1,
  angelInvestorId: -1,
  id: -1,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  company: '',
  location: defaultLocation,
  role: '',
  gender: defaultGender,
  nationality: '',
  dateOfBirth: '',
  about: '',
  imageLink: '',
  jobFunctions: [],
  skills: [],
  nationalityId: 0,
  passport: '',
  visa: '',
  visaProof: '',
}

export const membersDefaultState: MembersStateType = {
  members: [],
  selectedMember: {
    data: defaultMember,
    eventFilter: UpcomingEventFilter,
    pastEvents: [],
    upcomingEvents: [],
  },
};

export const angelInvestorMembersDefaultState: AngelInvestorMembersStateType = {
  members: [],
  selectedMember: {
    data: defaultAngelInvestorMember,
    eventFilter: UpcomingEventFilter,
    pastEvents: [],
    upcomingEvents: [],
  },
};
