import { PayloadAction } from '@reduxjs/toolkit';

import { AngelInvestorType as AngelInvestor } from 'packages/angel_investors_repository';
import { angelInvestorDef } from 'redux/utils';
import { SetAllType, SetFieldType, types } from './actions';

const initialState: AngelInvestor = angelInvestorDef;

const handleSetAll = (state: AngelInvestor, payload: SetAllType) => payload.angelInvestor;

const handleSetField = (state: AngelInvestor, payload: SetFieldType) => ({ ...state, [payload.field]: payload.value });

const reducer = (state: AngelInvestor, action: PayloadAction<SetAllType | SetFieldType>) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_ALL: {
      return handleSetAll(state, <SetAllType>payload);
    }
    case types.SET_FIELD: {
      return handleSetField(state, <SetFieldType>payload);
    }
    default:
      return state;
  }
}

export { reducer, initialState };
