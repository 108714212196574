import { useReducer } from 'react';

import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { RequestFiltersState } from 'types/requests';
import {
  setAmount as setAmountAC,
  setType as setTypeAC,
  setUsersType as setUsersTypeAC,
  setCategory as setCategoryAC,
  setServiceProviders as setServiceProvidersAC,
  setPartners as setPartnersAC,
  resetFilters as resetFiltersAC,
  setStartups as setStartupsAC,
  setAngelInvestors as setAngelInvestorsAC,
  setAllFilters as setAllFiltersAC,
} from './actions';
import { initialState, reducer } from './reducer';

const useRequestFiltersHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const resetFilters = () => {
    dispatch(resetFiltersAC());
  }

  const setAmount = (payload: DataPayload<{ field: 'from' | 'to', value: number }>) => {
    dispatch(setAmountAC(payload));
  }

  const setType = (payload: DataPayload<'all' | 'service' | 'partner'>) => {
    dispatch(setTypeAC(payload));
  }

  const setUsersType = (payload: DataPayload<'angel-investor' | 'startup'>) => {
    dispatch(setUsersTypeAC(payload));
  }

  const setCategory = (payload: DataPayload<number[]>) => {
    dispatch(setCategoryAC(payload));
  }

  const setServiceProviders = (payload: DataPayload<number[]>) => {
    dispatch(setServiceProvidersAC(payload));
  }

  const setPartners = (payload: DataPayload<number[]>) => {
    dispatch(setPartnersAC(payload));
  }

  const setStartups = (payload: DataPayload<number[]>) => {
    dispatch(setStartupsAC(payload));
  }

  const setAngelInvestors = (payload: DataPayload<number[]>) => {
    dispatch(setAngelInvestorsAC(payload));
  }

  const setAllFilters = (payload: DataPayload<RequestFiltersState>) => {
    dispatch(setAllFiltersAC(payload));
  }

  return {
    state,
    initialState,
    setServiceProviders,
    setType,
    setUsersType,
    setCategory,
    setAmount,
    setPartners,
    resetFilters,
    setStartups,
    setAngelInvestors,
    setAllFilters,
  };
}

export default useRequestFiltersHook;
