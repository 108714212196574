import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectAdgmLicenseByAngelInvestorId, selectAngelInvestorId } from 'redux/selectors/angelInvestors';
import { getADGMLicenseByAngelInvestorId } from 'redux/actions/angelInvestors';
import { selectUserAngelInvestorId } from 'redux/selectors/auth';

const mapStateToProps = (state: StateType) => ({
  adgm: selectAdgmLicenseByAngelInvestorId(state),
  userAngelInvestorId: selectUserAngelInvestorId(state),
  id: selectAngelInvestorId(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({ getADGMLicenseByAngelInvestorId }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
