import React, { useEffect } from 'react';
import { Tabs } from 'antd';

import { identity } from 'utils';
import { RequestDetailsDrawerProps, RequestDetailsTabs } from 'types/requests';
import { TABS } from './utils';
import { DiscussionBoard, OverviewTab } from './components';
import { disableCommentsStatuses } from '../PartnerRequestDetailsDrawer/utils/constants';

import './RequestDetailsDrawer.scss';
import DocumentsTab from './components/DocumentsTab';

const RequestDetailsDrawer = (props: RequestDetailsDrawerProps) => {
  const {
    comments,
    commentsLoading,
    activeTab,
    requestId: id,
    userType,
    request,
  } = props;
  const {
    sendNewRequestComment,
    getRequestItemsByRequestId,
    getCommentsByRequestId,
    setActiveTab,
    getRequestById,
    getWallet,
  } = props.actions;

  useEffect(() => {
    if (identity.isTruthyNumber(id)) {
      getRequestById({ id });
      getRequestItemsByRequestId({ id: id! });
      getCommentsByRequestId({ id: id!, request: 'service-request' });
      setActiveTab({ tab: TABS.overview });
    }
  }, [id]);

  useEffect(() => {
    if (identity.isTruthyNumber(request.startupId)) {
      getWallet({ startup_id: request.startupId });
    }
  }, [request]);

  const onTabChange = (tab: string) => {
    setActiveTab({ tab: tab as RequestDetailsTabs });
  }

  const onComment = (text: string) => {
    sendNewRequestComment({ text });
  }

  return (
    <div className="request-details-drawer">
      <Tabs onChange={onTabChange} activeKey={activeTab} color="green">
        <Tabs.TabPane
          tab={TABS.overview}
          key={TABS.overview}
        >
          <OverviewTab />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={TABS.discussionBoard}
          key={TABS.discussionBoard}
        >
          <DiscussionBoard
            userType={userType}
            isLoading={commentsLoading}
            comments={comments}
            onComment={onComment}
            disableComment={disableCommentsStatuses.includes(request?.status)}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={TABS.documents}
          key={TABS.documents}
        >
          <DocumentsTab />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}

export default RequestDetailsDrawer;
