import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import {
  selectPartnerRequestDetail,
  selectRequestDetails,
  selectRequestItems,
  selectRequestStatus,
  selectShowInvoiceFields,
} from 'redux/selectors/request';
import {
  changeRequestStatus,
  acknowledgePartnerRequestById,
  sendNewRequestComment,
  getRequestById,
  setShowInvoiceFields,
} from 'redux/actions/request';
import { getWallet } from 'redux/actions/wallet';
import { getAIWallet } from 'redux/actions/ai_wallet';
import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { selectWallet } from 'redux/selectors/wallet';
import { selectAIWallet } from 'redux/selectors/ai_wallet';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  requestItems: selectRequestItems(state),
  status: selectRequestStatus(state),
  details: selectRequestDetails(state),
  wallet: selectWallet(state),
  ai_wallet: selectAIWallet(state),
  partnerRequestDetails: selectPartnerRequestDetail(state),
  showInvoiceFields: selectShowInvoiceFields(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    changeRequestStatus,
    sendNewRequestComment,
    acknowledgePartnerRequestById,
    getWallet,
    getAIWallet,
    getRequestById,
    setShowInvoiceFields,
  }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps);
