import React, { useEffect, useState } from 'react';
import './ServiceRequestTotal.scss';
import { getTotalPrice } from 'packages/service_request_repository/api';
import { TotalPriceType } from 'types/services/servicesDrawer';

type DataPayload<DataType> = {
  data: DataType
}

const ServiceRequestTotal = (props: TotalPriceType) => {
  const [isLoading, setIsLoading] = useState(false)
  const [price, setPrice] = useState<number>()
  const [errorMsg, setErrorMsg] = useState<string>('')

  const {
    setTotalPrice,
    setPlan,
    noOfDesk,
    quantity,
    officeType,
    isStartupUser,
    memberId,
    passport,
    plan,
  } = props;

  useEffect(() => {
    setIsLoading(true)
    const accessToken = localStorage.getItem('access_token')
    const MEMBER_ID = isStartupUser ? `memberID=${memberId}` : `aiMemberID=${memberId}`
    const PLAN = plan ? `&plan=${plan}` : null
    const PASSPORT = passport ? `&passportNo=${passport}` : null
    const EMIRATE = '&emirate=Abu Dhabi'
    if (accessToken && MEMBER_ID && PASSPORT && PLAN) {
      const fetchData = async () => {
        setIsLoading(true)
        const etp = {} as DataPayload<number>
        setTotalPrice(etp)
        const data = await getTotalPrice({
          searchParams: `${MEMBER_ID}${PLAN}${PASSPORT}${EMIRATE}`,
          accessToken,
        })
        if (data.error) {
          setIsLoading(false)
          setErrorMsg(data.error.message)
          setPlan({ data: '' })
        } else if (data?.data) {
          const tp = { data: data?.data?.amount } as DataPayload<number>
          setIsLoading(false)
          setErrorMsg('')
          if (data?.data?.amount) {
            setPrice(data.data.amount)
            setTotalPrice(tp)
          }
        }
      }
      fetchData()
    } else {
      setIsLoading(false)
    }
  }, [memberId, passport, plan, props.price]);

  return (
    <div className="service-request-total--container">
      <div className="service-request__total">
        <div className="service-request__total-text">Total</div>
        <div className="service-request__total-amount">
          {
            isLoading && (
              <div className="service-request__spinner">
                <div className="service-request__loader" />
              </div>
            )
          }
          {price && !errorMsg && `AED ${price}`}
          {officeType && noOfDesk && quantity && `AED ${props.price! * noOfDesk}`}
          {!price && !officeType && !errorMsg && `AED ${props.price}`}
          {!errorMsg && <span className="vat-text">&nbsp;(ex. VAT)</span>}
          {errorMsg && `${errorMsg}`}
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestTotal;
