import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { selectIsLoading } from 'redux/selectors/dashboard';
import { getDashboardData } from 'redux/actions/dashboard';
import { selectUserAngelInvestorId, selectUserStartupId, selectUserType } from 'redux/selectors/auth';
import { selectWallet } from 'redux/selectors/wallet';
import { selectAIWallet } from 'redux/selectors/ai_wallet';
import { getWallet } from 'redux/actions/wallet';
import { getAIWallet } from 'redux/actions/ai_wallet';
import { getServicesSPCount } from 'redux/actions/workspace';
import {
  selectWorkspaceServicesCount,
  selectWorkspaceSpCount,
} from 'redux/selectors/workspace';
import { setActiveTab } from 'redux/actions/services';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  userType: selectUserType(state),
  wallet: selectWallet(state),
  aiWallet: selectAIWallet(state),
  startupId: selectUserStartupId(state),
  angelInvestorId: selectUserAngelInvestorId(state),
  servicesCount: selectWorkspaceServicesCount(state),
  serviceProvidersCount: selectWorkspaceSpCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators(
    {
      setActiveTab,
      getServicesSPCount,
      getDashboardData,
      getWallet,
      getAIWallet,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps);
