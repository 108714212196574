import { identity } from 'utils';
import { ADGMLicenseType, AngelInvestorType } from 'packages/angel_investors_repository';

const licenseValidation = (hasLicense: boolean, adgm: ADGMLicenseType) => hasLicense && (
  identity.isEmptyString(adgm.aDGMLicenseNo)
    || identity.isEmptyString(adgm.aDGMLicenseUrl)
    // || identity.isEmptyString(adgm.acceptanceDate)
    || identity.isEmptyString(adgm.expiryDate)
    || identity.isEmptyString(adgm.issueDate)
    || identity.isEmptyString(adgm.legalSignatoryTitle)
    || identity.isEmptyString(adgm.address)
    || identity.isEmptyString(adgm.legalSignatoryEmail)
    || identity.isEmptyString(adgm.legalADGMName)
    || identity.isEmptyString(adgm.legalSignatoryName)
);

export const disableAddMissingDetailsSubmitButton = (
  angelInvestor: AngelInvestorType,
  hasLicense: boolean,
  adgm: ADGMLicenseType,
) => (
  identity.isEmptyString(angelInvestor?.name)
  || identity.isEmptyString(angelInvestor?.about)
  || identity.isEmptyString(angelInvestor?.imageLink)
  || identity.isEmptyString(angelInvestor?.website)
  || angelInvestor?.status.id === 1
  || licenseValidation(hasLicense, adgm)
);

export const getFileName = (value: string): string => {
  if (identity.isTruthyString(value)) {
    const data = value?.split('/');
    return identity.isFullArray(data) ? data.pop()! : '';
  }
  return '';
}
