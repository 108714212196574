import {
  AggregationObject,
  CreateResourceSuccessResponseType,
  GetParamsType,
  GetResourceSuccessResponseType,
  ResponseType,
  UpdateResourceSuccessResponseType,
} from 'packages/http_client';
import { ResponseServiceProviderType, ServiceProviderType, ServiceProviderTypeType } from '../service_provider';

/* Application models and definitions */

export type PriceType = 'per-hour' | 'per-day' | 'per-week' | 'per-month' | 'per-year' | '';

export type PricingOption = {
  id: number,
  amount: string,
  option: string,
}

export type StatusType = 'unsent' | 'rejected' | 'delivered' | 'in progress' | 'pending HUB71' | 'quoted' | 'new' | 'pending quotation' | 'draft' | 'completed'
| 'pending HUB71 approval' | 'quoted-startup' | 'quoted-angel-investor'

export type ServiceType = {
  id: number,
  serviceProvider: ServiceProviderType,
  name: string,
  about: string,
  category: string,
  priceFrom: string,
  priceTo: string,
  priceType: PriceType,
  type: string,
  offerPrice: 'range' | 'fixed' | '-',
  offerDocLink: string | Blob,
  additionalOptionName?: string,
  additionalOptionPrice?: string,
  pricingOptions?: PricingOption[],
  startup: {
    id: number,
    name: string,
  },
  angelInvestor: {
    id: number,
    name: string,
  },
  serviceStatus: StatusType,
  servicePrice?: ServicePriceType,
  subCategories?: ServiceSubCategoryType[],
  priceText?: string,
  categoryId: number,
  offerPriceId?: 0 | 1 | 2,
  priceUnits?: string,
  serviceProviderId?: number,
  active?: boolean,
};

/* API Models and definitions */

// eslint-disable-next-line no-shadow
export enum ServicePriceTypeEnum {
  'fixed' = 1,
  'range' = 2,
  '-' = 0,
}

export type ServiceCategoryType = {
  id: number,
  deletedAt?: string | null,
  name?: string,
  color?: string,
  active?: boolean | number | string,
}

export type ServiceSubCategoryType = {
  id: number,
  serviceCategory?: ServiceCategoryType,
  serviceCategoryID?: number,
  name?: string,
  color?: string,
  active?: boolean,
};

export type CreateServiceCategoryType = {
  name?: string,
}

export type CreateServiceSubCategoryType = {
  name?: string,
  serviceCategoryID?: number,
}

export type ServiceFiltersType = {
  categoryId?: number[],
  priceTypeId?: number[],
  from?: number,
  to?: number,
  serviceProviderId?: number[],
  fieldSelection?: Array<string | AggregationObject>,
  serviceSubCategory?: number,
}

export type ServiceQuery = {
  filters?: ServiceFiltersType,
  isPreload?: boolean
  limit?: number,
  offset?: number,
}

export type ResponseServiceType = {
  category?: ServiceCategoryType,
  categoryID?: number,
  description?: string,
  document?: string,
  id: number,
  name?: string,
  priceType?: 0 | 1 | 2,
  serviceProvider?: ResponseServiceProviderType,
  serviceProviderID?: number,
  serviceProviderType?: ServiceProviderTypeType,
  serviceProviderTypeID?: number,
  subCategories?: ServiceSubCategoryType[],
  unitType?: number,
  price?: string,
  unit?: string,
  active?: boolean,
};

export type Unit = 'Time' | 'Fixed' | 'Length' | 'Area' | 'Volume' | 'Weight' | '';

export type UnitType = {
  id: number,
  type?: Unit,
  name?: string,
};

export type ResponseUnitType = {
  id: number,
  type?: number,
  name?: string,
  default?: boolean,
  multiplier?: number,
};

export type ServicePriceType = {
  fromPrice?: number,
  id: number,
  service?: ResponseServiceType,
  serviceID?: number,
  toPrice?: number,
  unit?: ResponseUnitType,
  unitID?: number,
};

export interface ServiceCategoryParams extends GetParamsType {
  id?: number,
  name?: string,
}

export interface ServiceSubCategoryParams extends GetParamsType {
  id?: number,
  name?: string,
  service_category_id?: number,
}

/* API Request Dtos and Payloads */

export type CreateServicePayload = Omit<ServiceType, 'id' | 'subCategories'> & {
  subCategories?: number[],
  unit?: UnitType,
};

export type UpdateServicePayload = {
  name?: string,
  type: string,
  about?: string,
  offerDoc?: string | Blob,
  offerPrice?: 'range' | 'fixed' | '-',
  unit?: UnitType,
  priceFrom?: string,
  pricingOptions?: PricingOption[],
  amount?: string,
  active?: boolean,
};

export type CreateServiceApiRequestPayload = {
  _category_id?: number | string,
  _description?: string,
  _document?: string | Blob,
  _name?: string,
  _price_type?: number,
  _service_provider_id?: number,
  _service_provider_type_id?: number,
  _sub_categories?: string,
  _unit_type?: number,
  _active?: number,
};

export type UpdateServiceApiRequestPayload = {
  _description?: string,
  _document?: string | Blob,
  _name?: string,
  _service_provider_type_id?: number,
  _price_type?: number,
  _unit_type?: number,
  _amount?: string,
  _active?: number,
};

export type ServicePriceApiRequestPayload = {
  _from_price?: number,
  _service_id: number,
  _to_price?: number,
  _unit_id?: number,
};

export type CreateServiceCategoryApiRequestPayload = {
  _active?: boolean | number | string,
  _color?: string,
  _name?: string,
};

export type CreateServiceSubCategoryApiRequestPayload = {
  _active?: boolean | number | string,
  _color?: string,
  _name?: string,
  _service_category_id?: number,
};

export type ServiceCount = { id__count: number }

export type GetServicesListCountApiResponseType = {
  result: ServiceCount[],
};

export interface GetServicesCountResponse extends ResponseType {
  servicesCount?: number,
}

export type GetserviceCategoryByIdApiResponseType = {
  result: ServiceCategoryType,
};

export type GetserviceSubCategoryByIdApiResponseType = {
  result: ServiceSubCategoryType,
};

/* API Response Dtos and Payloads */

export type CreateServiceApiResponseType = CreateResourceSuccessResponseType;
export interface CreateServiceResponse extends ResponseType {
  data?: number,
}

export type CreateServiceCategoryApiResponseType = CreateResourceSuccessResponseType;

export type GetServicesListApiResponseType = GetResourceSuccessResponseType<ResponseServiceType[]>;

export type GetServiceApiResponseType = GetResourceSuccessResponseType<ResponseServiceType>;

export type GetServicePricesByServiceType = GetResourceSuccessResponseType<ServicePriceType[]>;

export type UpdateServiceApiResponseType = UpdateResourceSuccessResponseType;

export type GetServiceCategoriesApiResponseType = GetResourceSuccessResponseType<ServiceCategoryType[]>;

export type GetServiceSubCategoriesApiResponseType = GetResourceSuccessResponseType<ServiceSubCategoryType[]>;

export type AddServicePriceApiResponseType = CreateResourceSuccessResponseType;
