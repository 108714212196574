import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { identity, requiredValidation } from 'utils';
import { RcFile } from 'antd/lib/upload/interface';
import showNotification from 'services/utils/showNotification';
import { ServiceRequestDocumentsProps } from 'types/requests';
import { postServiceRequestDocuments } from 'packages/service_request_repository/api';
import { RenderIf, Input } from 'components/components';
import DocumentFields from './components/DocumentFields/View';

import './DocumentsTab.scss';

const DocumentsTab = (props: ServiceRequestDocumentsProps) => {
  const {
    requestDocuments,
    serviceRequestId,
  } = props;
  const {
    getServiceRequestDocuments,
  } = props.actions;

  const [newFileName, setNewFileName] = useState<string>();
  const [documentFile, setDocumentFile] = useState<string | Blob>();
  const [addDocument, setAddDocument] = useState<boolean>(false);
  const [editDocumentIndex, setEditDocumentIndex] = useState<number>(0)

  const handleBeforeUpload = (file: RcFile) => {
    setDocumentFile(file);
    return false;
  }

  const handleDiscardForm = () => {
    setAddDocument(false)
    setDocumentFile(undefined)
    setNewFileName(undefined)
  }

  useEffect(() => {
    if (editDocumentIndex !== -100) {
      handleDiscardForm()
    }
  }, [editDocumentIndex])

  useEffect(() => {
    if (identity.isTruthyNumber(serviceRequestId)) {
      getServiceRequestDocuments({ service_request_id: serviceRequestId });
    }
  }, [serviceRequestId]);

  const handleSubmit = async () => {
    try {
      const result = await postServiceRequestDocuments({
        _service_request_id: serviceRequestId,
        _name: newFileName!,
        _document: documentFile!,
      })

      if (result.data?.status === 'ok' && result.httpStatus === 200 && result.error === undefined) {
        setAddDocument(false)
        getServiceRequestDocuments({ service_request_id: serviceRequestId });
        showNotification('Document Added Successfully.', false, result?.httpStatus);
      } else if (result.error && result.error !== undefined) {
        showNotification(`Something went wrong: ${result.error}`, true, result.httpStatus);
      }
    } catch (error) {
      if (error instanceof Error) {
        showNotification(`Something went wrong: ${error?.message!}`, true, (error as any).code);
      } else {
        showNotification(`Something went wrong: ${error}`, true, 500);
      }
    }
  }

  const handleAddDocumentButton = () => {
    setAddDocument(true)
    setEditDocumentIndex(-100)
  }

  return (
    <div className="documents-tab-content">
      <h3>Service Request Documents</h3>
      {requestDocuments?.map((document) => (
        <DocumentFields
          fileUrl={document.document as string}
          fileName={document.name}
          serviceRequestId={serviceRequestId}
          key={document.id}
          id={document.id!}
          getServiceRequestDocuments={getServiceRequestDocuments}
          setEditDocumentIndex={setEditDocumentIndex}
          editDocumentIndex={editDocumentIndex}
        />
      ))}
      <div className="add-document-container">
        <RenderIf condition={addDocument}>
          <h4>Add New Document</h4>
          <div className="add-document-fields">
            <Input label="Document Name" layout="vertical" required rules={[requiredValidation]}>
              <Input.InputField
                name="filename"
                onChange={(e) => setNewFileName(e.target.value)}
                className="add-input-field"
                placeholder="Enter Document Name"
              />
            </Input>
            <Input label="Document File" layout="vertical" required rules={[requiredValidation]}>
              <Input.DraggerUpload
                accept=".pdf"
                beforeUpload={handleBeforeUpload}
                isRoundableButton
                // allowChange
              />
            </Input>
            <div className="add-action-btns">
              <Button
                type="dashed"
                className="discard-btn"
                onClick={handleDiscardForm}
              >
                Discard
              </Button>
              <Button
                type="dashed"
                disabled={!newFileName || !documentFile}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </RenderIf>
        <RenderIf condition={!addDocument}>
          <Button
            className="add-doc-button"
            onClick={handleAddDocumentButton}
          >
            Add New Document
          </Button>
        </RenderIf>
      </div>
    </div>
  )
}

export default DocumentsTab;
