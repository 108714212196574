import { convertGetServiceProviderResponse, convertGetServiceResponse, getPriceTypeFromUnitName } from 'packages/service_repository';
import { convertGetStartupResponse } from 'packages/startup_repository';
import { convertGetAngelInvestorResponse } from 'packages/angel_investors_repository';
import { convertPartnerRequest } from 'packages/partner_repository';
import { identity } from 'utils';
import { RequestsCountObject } from 'types/requests';
import { requestsCountDefault } from 'redux/utils';
import {
  CreateServiceRequestDocumentApiRequestPayload,
  CreateServiceRequestDocumentType,
  CreateServiceRequestItemPayload,
  CreateServiceRequestItemType,
  CreateServiceRequestPayload,
  CreateServiceRequestType,
  ResponseRequestType,
  ResponseRequestTypePayload,
  ResponseServiceRequestItemType,
  ResponseServiceRequestType,
  ServiceRequestItemType,
  ServiceRequestType,
  CreateServiceRequestCommentPayload,
  CreateServiceRequestCommentType,
  ResponseServiceRequestCommentType,
  ServiceRequestCommentType,
  UpdateServiceRequestType,
  UpdateServiceRequestPayload,
  GetServiceRequestsParams,
  UpdateServiceRequestDocumentPayload,
  UpdateServiceRequestDocumentType,
} from '../types';
import {
  NUMBER_TO_REQUEST_STATUS_KEY_MAP,
  NUMBER_TO_REQUEST_STATUS_MAP,
  REQUEST_STATUS_TO_NUMBER_MAP,
} from './constants';
import { queryBuilder } from '../../http_client';

export const convertServiceRequestBody = (payload?: CreateServiceRequestType): CreateServiceRequestPayload => ({
  _application_date: payload?.applicationDate,
  _created_by_id: payload?.createdById,
  _service_provider_id: payload?.serviceProviderId,
  _startup_id: payload?.startupId,
  _angel_investor_id: payload?.angelInvestorId,
  _status: REQUEST_STATUS_TO_NUMBER_MAP[payload?.status!],
  _total_price: payload?.totalPrice,
});

export const convertUpdateServiceRequestBody = (payload?: UpdateServiceRequestType): UpdateServiceRequestPayload => ({
  _application_date: payload?.applicationDate,
  _created_by_id: payload?.createdById,
  _service_provider_id: payload?.serviceProviderId,
  _startup_id: payload?.startupId,
  _angel_investor_id: payload?.angelInvestorId,
  _status: REQUEST_STATUS_TO_NUMBER_MAP[payload?.status!],
  _total_price: payload?.totalPrice,
  _invoice: payload?.invoice,
  _invoice_number: payload?.invoiceNumber,
  _invoice_date: payload?.invoiceDate,
})

export const convertCreateServiceRequestItemPayload = (payload: CreateServiceRequestItemType):
    CreateServiceRequestItemPayload => ({
  _price: payload.price,
  _service_id: payload.serviceId,
  _service_price_id: payload.servicePriceId,
  _service_request_id: payload.serviceRequestId,
  _quantity: payload.quantity,
  _details: JSON.stringify(payload.details),
});

export const convertUpdateServiceRequestDocumentPayload = (payload: UpdateServiceRequestDocumentType):
UpdateServiceRequestDocumentPayload => ({
  id: payload.id,
  _name: payload?.name,
  _document: payload?.document as Blob,
  _service_request_id: payload?.serviceRequestId,
  _rows_count: payload?.rowsCount,
  // _details: JSON.stringify(payload.details),
});

export const convertGetServiceRequestResponse = (payload?: ResponseServiceRequestType):
ServiceRequestType | undefined => {
  if (identity.isObjWithChildren(payload)) {
    return ({
      id: payload?.id!,
      serviceProviderId: payload?.serviceProviderID!,
      serviceProvider: convertGetServiceProviderResponse(payload?.serviceProvider)!,
      startupId: payload?.startupID!,
      angelInvestorId: payload?.angelInvestorID!,
      angelInvestor: convertGetAngelInvestorResponse(payload?.angelInvestor)!,
      startup: convertGetStartupResponse(payload?.startup)!,
      status: NUMBER_TO_REQUEST_STATUS_MAP[payload?.status!],
      totalPrice: payload?.totalPrice!,
      applicationDate: payload?.applicationDate!,
      service: convertGetServiceResponse(payload?.service!),
      itemCount: payload?.itemCount!,
      itemList: payload?.itemList.split('\n')!,
      workOrder: payload?.workOrder,
      invoice: payload?.invoice,
      paymentAmount: payload?.paymentAmount,
      paymentDate: payload?.paymentDate,
      paymentProcessed: payload?.paymentProcessed,
      paymentReference: payload?.paymentReference,
    })
  }
};

export const convertGetAllRequests = (payload?: ResponseRequestTypePayload): ResponseRequestType => ({
  id: payload?.id,
  partnerRequest: convertPartnerRequest(payload?.partnerRequest!)!,
  serviceRequest: convertGetServiceRequestResponse(payload?.serviceRequest!)!,
  startup: convertGetStartupResponse(payload?.startup)!,
  angelInvestor: convertGetAngelInvestorResponse(payload?.angelInvestor)!,
})

export const convertGetRequestListResponse = (payload?: ResponseRequestTypePayload[]): ResponseRequestType[] => {
  if (identity.isFullArray(payload)) {
    return payload!.map((item) => convertGetAllRequests(item));
  }
  return [];
}

export const convertGetRequestsCount = (payload?: { id__count: number, status: number }[]): RequestsCountObject => {
  const count: RequestsCountObject = { ...requestsCountDefault };
  if (identity.isFullArray(payload)) {
    for (const i in payload!) {
      count[NUMBER_TO_REQUEST_STATUS_KEY_MAP[payload[i].status] as keyof RequestsCountObject] = payload[i].id__count;
      count.all += payload[i].id__count;
    }
  }
  return count;
}

export const convertGetRequestStartupCount = (payload?: { id__count: number, status: number }[]):
{quotedStartup: number} => {
  const count: {quotedStartup: number} = { ...requestsCountDefault };
  if (identity.isFullArray(payload)) {
    if (!identity.isEmptyArray(payload)) {
      count.quotedStartup = payload![0].id__count;
    }
  }
  return count;
}

export const convertGetServiceRequestItemResponse = (payload?: ResponseServiceRequestItemType):
ServiceRequestItemType => ({
  id: payload?.id!,
  serviceId: payload?.serviceID!,
  service: convertGetServiceResponse(payload?.service!),
  servicePriceId: payload?.servicePriceID!,
  serviceRequestId: payload?.serviceRequestID!,
  priceType: getPriceTypeFromUnitName(payload?.service?.unit, payload?.service?.unitType),
  serviceRequest: convertGetServiceRequestResponse(payload?.serviceRequest),
  servicePrice: payload?.servicePrice,
  price: payload?.price!,
  details: identity.isTruthyString(payload?.details) ? JSON.parse(payload?.details!) : '',
  quantity: payload?.quantity,
});

export const convertGetServiceRequestItemListResponse = (payload?: ResponseServiceRequestItemType[]) => {
  if (identity.isObjWithChildren(payload)) {
    return payload?.map((item) => convertGetServiceRequestItemResponse(item));
  }
  return [];
}

export const convertCreateServiceRequestDocumentsPayload = (payload: CreateServiceRequestDocumentType):
    CreateServiceRequestDocumentApiRequestPayload => ({
  _service_request_id: payload.serviceRequestId,
  _name: payload.name,
  _document: payload.document,
});

export const convertServiceRequestCommentType = (payload: ResponseServiceRequestCommentType):
  ServiceRequestCommentType => ({
  id: payload.id,
  createdAt: payload.createdAt,
  createdBy: payload.createdBy,
  createdById: payload.createdByID,
  message: payload.message,
  organization: payload.organization,
  serviceRequestId: payload.serviceRequestID,
  serviceRequest: convertGetServiceRequestResponse(payload.serviceRequest)!,
});

export const convertGetServiceRequestCommentList = (payload?: ResponseServiceRequestCommentType[]) => {
  if (!identity.isNotArray(payload)) {
    return payload?.map((item) => convertServiceRequestCommentType(item));
  }
}

export const convertServiceRequestCommentBody = (payload: CreateServiceRequestCommentType):
  CreateServiceRequestCommentPayload => ({
  _message: payload.message,
  _organization: payload.organization,
  _service_request_id: payload.serviceRequestId,
  _partner_request_id: payload.partnerRequestId,
});

export const requestsQuery = (data?: GetServiceRequestsParams) => {
  if (!identity.isObjWithChildren(data)) {
    return {};
  }

  const {
    startupId,
    angelInvestorId,
    partnerId,
    serviceProviderId,
    limit,
    order,
    offset,
    status,
    from,
    to,
    type,
  } = data!;
  const fields = [];
  const queryTable = [];
  const params = queryBuilder({
    startup_id: identity.isTruthyNumber(startupId) ? startupId : undefined,
    angel_investor_id: identity.isTruthyNumber(angelInvestorId) ? angelInvestorId : undefined,
  })
    .orderBy(order)
    .in({
      status,
      startup_id: identity.isFullArray(startupId) ? startupId as number[] : undefined,
      angel_investor_id: identity.isFullArray(angelInvestorId) ? angelInvestorId as number[] : undefined,
    })
    .limit(limit)
    .offset(offset)
    .preload()
  if (['service', 'partner'].includes(type as string)) {
    params.greaterThan(`${type}_request_id`, 1);
  }
  if (['startup', 'angel_investor'].includes(type as string)) {
    params.greaterThan(`${type}_id`, 1);
  }
  if (data?.isCount) {
    params
      .select(['status', { field: 'id', aggregation: 'count' }])
      .groupBy('status');
  }
  if (identity.isTruthyNumber(partnerId) || identity.isFullArray(partnerId)) {
    fields.push({ tableName: 'partner_requests', onId: 'partner_request_id' });
    queryTable.push({
      tableName: 'partner_requests',
      field: `partner_id${identity.isFullArray(partnerId) ? '__in' : ''}`,
      value: (partnerId as number).toString(),
    });
  }
  if (identity.isTruthyNumber(serviceProviderId)
    || identity.isFullArray(serviceProviderId)
    || identity.isTruthyNumber(from)
    || identity.isTruthyNumber(to)) {
    fields.push({ tableName: 'service_requests', onId: 'service_request_id' });
  }
  if (identity.isTruthyNumber(serviceProviderId) || identity.isFullArray(serviceProviderId)) {
    queryTable.push({
      tableName: 'service_requests',
      field: `service_provider_id${identity.isFullArray(serviceProviderId) ? '__in' : ''}`,
      value: (serviceProviderId as number).toString(),
    });
  }
  if (identity.isTruthyNumber(from) || identity.isTruthyNumber(to)) {
    let value = 'less';
    if (identity.isTruthyNumber(from) && identity.isTruthyNumber(to)) {
      value = 'range';
    } else if (identity.isTruthyNumber(from)) {
      value = 'greater';
    }
    queryTable.push({ tableName: 'service_requests', field: 'total_price', value: JSON.stringify({ value, from, to }) });
  }
  params.join(fields).queryRelatedTable(queryTable);
  return params.toObject();
}
