import produce from 'immer';

import { AIWalletStateType } from 'types/reduxTypes/StateTypes';
import { AIWalletActionType, SetAIWalletTransactionsPayload, SetAIWalletPayload } from 'types/reduxTypes/ActionTypes/AIWalletTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';
import { defaultAIWalletState } from '../../utils';
import { types } from '../../actions/ai_wallet';

const defaultState: AIWalletStateType = defaultAIWalletState;

const handleSetAIWallet = (state: AIWalletStateType, payload: SetAIWalletPayload) => {
  state.wallet = payload.wallet;
  return state;
}

const handleSetAIWalletTransactions = (state: AIWalletStateType, payload: SetAIWalletTransactionsPayload) => {
  state.walletTransactions = payload.walletTransactions;
  return state;
}

const handleSetIsLoading = (state: AIWalletStateType, payload: IsLoadingPayload) => {
  state.isLoading = payload.isLoading;
  return state;
};

const handleSetAIWalletCount = (state: AIWalletStateType, payload: DataPayload<number>) => {
  state.count = payload.data;
  return state;
}

// eslint-disable-next-line default-param-last
export default produce((state: AIWalletStateType = defaultState, action: AIWalletActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_AI_WALLET: {
      return handleSetAIWallet(state, <SetAIWalletPayload>payload);
    }
    case types.SET_AI_WALLET_TRANSACTIONS: {
      return handleSetAIWalletTransactions(state, <SetAIWalletTransactionsPayload>payload);
    }
    case types.SET_AI_WALLET_IS_LOADING: {
      return handleSetIsLoading(state, <IsLoadingPayload> payload);
    }
    case types.SET_AI_WALLET_COUNT: {
      return handleSetAIWalletCount(state, <DataPayload<number>>payload);
    }
    case types.RESET_AI_WALLET: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
