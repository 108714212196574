import { inputTypes } from 'components/components/FormBuilder/utils/constants';
import { getFormFieldBuilder } from 'components/components/FormBuilder/utils/FormFieldBuilder';
import { positiveNumbersOnly, requiredValidation } from 'utils/constants/validationRules';

export const addProgramTypeFields = [
  {
    fields: [
      getFormFieldBuilder('name', 'Name')
        .required()
        .withRules([requiredValidation])
        .withPlaceholder('Name')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('description', 'Description', inputTypes.textArea)
        .withPlaceholder('Description')
        .required()
        .withRules([requiredValidation])
        .withRows(3)
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('amount', 'Amount', inputTypes.number)
        .required()
        .min(0)
        .withRules([requiredValidation, positiveNumbersOnly])
        .withPlaceholder('Amount')
        .build(),
    ],
  },
  {
    fields: [
      getFormFieldBuilder('image', 'Image URL', inputTypes.image)
        .withPlaceholder('URL')
        .withAccept('.jpeg/*')
        .withPreviewType('image')
        .withRules([requiredValidation])
        .build(),
    ],
  },
];

export default null;
