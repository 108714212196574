import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { getStartupTeamMembers } from 'redux/actions/startups';
import { getAngelInvestorMembers, getAngelInvestorById } from 'redux/actions/angelInvestors';
import { selectStartupTeamMembers } from 'redux/selectors/startups';
import { selectAngelInvestorTeamMembers } from 'redux/selectors/angelInvestors';
import { selectUserAngelInvestorId, selectUserStartupId, selectUserType } from 'redux/selectors/auth';
import { getInsuranceTypes } from 'redux/actions/services';
import { selectMemberDetails } from 'redux/selectors/members';
import { selectMemberDetails as selectAIMemberDetails } from 'redux/selectors/angelInvestorMembers';
import { getMemberById } from 'redux/actions/memberDetails';
import { StateType } from 'types';
import { setIsDrawerOpen } from 'redux/actions/workspace';
import { selectInsuranceTypes, selectServiceDetails } from 'redux/selectors/services';
import { addNewRequest } from 'redux/actions/request';
import { getAIWallet } from 'redux/actions/ai_wallet';
import { getWallet } from 'redux/actions/wallet';

const mapStateToProps = (state: StateType) => ({
  userType: selectUserType(state),
  details: selectServiceDetails(state),
  teamMembers: selectStartupTeamMembers(state),
  aITeamMembers: selectAngelInvestorTeamMembers(state),
  startupId: selectUserStartupId(state),
  angelInvestorId: selectUserAngelInvestorId(state),
  insuranceTypesOptions: selectInsuranceTypes(state),
  selectedMember: selectMemberDetails(state),
  selectedAIMember: selectAIMemberDetails(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setIsDrawerOpen,
    addNewRequest,
    getStartupTeamMembers,
    getAngelInvestorMembers,
    getAngelInvestorById,
    getInsuranceTypes,
    getMemberById,
    getWallet,
    getAIWallet,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
