import React, { useMemo } from 'react';
import { Button } from 'antd';
import classnames from 'classnames';

import { identity, ROLES } from 'utils';
import { RenderIf } from 'components/components';
import { ServicesAndServiceProvidersTabsProps } from 'types/services';

const Tabs = (props: ServicesAndServiceProvidersTabsProps) => {
  const {
    userType,
    setCurrentPage,
    setSpCurrentPage,
    servicesCount,
    activeTab,
    serviceProvidersCount,
  } = props;
  const { setActiveTab } = props.actions;

  const servicesTabs = useMemo((): Array<'services' | 'service-providers'> => {
    switch (userType) {
      case ROLES.admin:
      case ROLES.superAdmin: {
        return ['service-providers', 'services'];
      }
      case ROLES.startupFounder:
      case ROLES.startupTeamMember:
      case ROLES.angelInvestor: {
        return ['services', 'service-providers'];
      }
      default: {
        return [];
      }
    }
  }, [userType]);

  const tabTitles = {
    services: `Services (${servicesCount})`,
    'service-providers': `Service providers (${serviceProvidersCount})`,
  };

  const handleOnChange = (activeKey: string) => {
    if (['services', 'service-providers'].includes(activeKey)) {
      setActiveTab({ activeTab: activeKey });
    }
    if (activeKey === 'service-providers') {
      setSpCurrentPage(1);
    } else {
      setCurrentPage(1);
    }
  }

  return (
    <RenderIf condition={identity.isFullArray(servicesTabs)}>
      <div className="tab-buttons-lg">
        {servicesTabs.map((tab) => (
          <Button
            key={tabTitles[tab]}
            className={classnames('tab-button-lg', { 'tab-button-active': activeTab === tab })}
            onClick={() => handleOnChange(tab)}
          >
            {tabTitles[tab]}
          </Button>
        ))}
      </div>
    </RenderIf>
  );
}

export default Tabs;
