import { API_ROOT } from 'packages/http_client/utils';

export const ANGELINVESTOR_API = `${API_ROOT}/angelinvestor`;

export const STAGE_API = `${API_ROOT}/angelinvestorstage`;

export const INVESTMENT_STAGE_API = `${API_ROOT}/investmentstage`;

export const SECTOR_API = `${API_ROOT}/sector`;

export const ANGELINVESTOR_ADGM_LICENSE = `${API_ROOT}/startupadgmlicense`;
export const ANGELINVESTOR_INCENTIVE_AGREEMENT = `${API_ROOT}/startupincentiveagreement`;
