import React, { useMemo } from 'react';
import { Redirect } from 'react-router';
import { Layout } from 'antd';
import { Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';

import PartnerDetails from 'components/PartnerDetails';
import {
  isCommunity,
  isProduction,
  isStartup,
  isAngelInvestor,
  ROLES as USER_ROLES,
  identity,
  isExpired,
} from 'utils';
import { LayoutWithSidebarType } from 'types/layoutWithSidebar/LayoutWithSidebarProps';
import routes from 'routes';
import ActivitySidebar from 'components/ActivitySidebar';
import { NoticeModal, RenderIf } from 'components/components'
import Performance from 'components/Performance';
import EditPartner from 'components/EditPartner';
import SingleServiceOverview from 'components/SingleServiceOverview';
import CreatePerformanceReport from 'components/Performance/components/CreatePerformanceReport';
import AIWalletOverview from 'components/AIWalletOverview';
import { PrivateRoute } from './routes';
import { Sidebar, Navbar } from './components';
import CohortOverview from '../CohortOverview';
import CohortDetails from '../CohortDetails';
import EditCohort from '../EditCohort';
import Settings from '../Settings';
import SubmitOverview from '../SubmitOverview';
import StartupsOverview from '../StartupsOverview';
import EditStartup from '../EditStartup';
import PeopleOverview from '../PeopleOverview';
import StartupDetails from '../StartupDetails';
import EditPeopleProfile from '../EditMemberProfile';
import MemberDetails from '../MemberDetails';
import EditServiceProvider from '../EditServiceProvider';
import ServiceProviderDetails from '../ServiceProviderDetails';
import TeamMembers from '../TeamMembers';
import WalletOverview from '../WalletOverview';
import RequestsOverview from '../RequestsOverview';
import IncentiveRequestDetails from '../RequestDetails/components/IncentiveRequestDetails';
import ServicesOverView from '../ServiceProviderDetails/components/ServicesOverview';
import ChangePassword from '../ChangePassword';
import Events from '../Events';
import PartnersOverview from '../PartnersOverview';
import Dashboard from '../Dashboard';
import {
  EditMyProfile,
  MyProfile,
  CompanyProfile,
  EditCompanyProfile,
} from '../User';
import HIDDEN_ROUTES from '../../utils/constants/hiddenRoutes';
import StaticNotification from '../components/Notification/StaticNotification';
import ServicesAndServiceProviders from '../ServicesAndServiceProviders';
import { NotFound } from '../App/components';

import './LayoutWithSidebar.scss';

const { Content } = Layout;

const LayoutWithSidebar = (props: LayoutWithSidebarType) => {
  const {
    location,
    isAuthenticated,
    isOpen,
    startupStatus,
    angelInvestorStatus,
    userType,
  } = props;

  const startupIsNotCommunity = useMemo(() => (
    identity.isTruthyString(startupStatus) && !isCommunity(startupStatus) && isStartup(userType)
  ), [startupStatus, userType]);

  const angelInvestorIsNotCommunity = useMemo(() => (
    identity.isTruthyString(angelInvestorStatus) && !isCommunity(angelInvestorStatus) && isAngelInvestor(userType)
  ), [angelInvestorStatus, userType]);

  if (location.pathname === '/' || (HIDDEN_ROUTES.includes(location.pathname) && isProduction)) {
    return (<Redirect to={isAuthenticated ? routes.dashboardUrl : routes.loginUrl} />);
  }

  return (
    <Layout hasSider className="layout-with-sidebar">
      <RenderIf condition={isAuthenticated}>
        <Sidebar location={location} />
      </RenderIf>
      <Layout>
        <RenderIf condition={isAuthenticated}>
          <Navbar />
        </RenderIf>
        <Content className="content">
          <RenderIf condition={startupIsNotCommunity && isAuthenticated}>
            <div className="content-notification">
              <StaticNotification
                type="warning"
                message={isExpired(startupStatus)
                  ? 'Your incentives period is expired, you can no longer request any incentives.'
                  : 'Onboarding stage: Some features are only available after signing the incentives agreement.'}
              />
            </div>
          </RenderIf>
          <RenderIf condition={angelInvestorIsNotCommunity && isAuthenticated}>
            <div className="content-notification">
              <StaticNotification
                type="warning"
                message={isExpired(angelInvestorStatus)
                  ? 'Your incentives period is expired, you can no longer request any incentives.'
                  : 'Onboarding stage: Some features are only available after signing the incentives agreement.'}
              />
            </div>
          </RenderIf>
          <Switch location={location}>
            <PrivateRoute
              path={routes.walletUrl}
              exact
              permittedUserTypes={[USER_ROLES.startupFounder, USER_ROLES.startupTeamMember]}
              component={WalletOverview}
            />
            <PrivateRoute
              path={routes.startupWallet}
              exact
              permittedUserTypes={[USER_ROLES.admin, USER_ROLES.superAdmin]}
              component={WalletOverview}
            />
            <PrivateRoute
              path={routes.angelInvestorWalletUrl}
              exact
              permittedUserTypes={[USER_ROLES.angelInvestor]}
              component={AIWalletOverview}
            />
            <PrivateRoute
              path={routes.angelInvestorWallet}
              exact
              permittedUserTypes={[USER_ROLES.admin, USER_ROLES.superAdmin]}
              component={AIWalletOverview}
            />
            <PrivateRoute
              path={routes.editServiceProviderUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.serviceProvider,
              ]}
              component={EditServiceProvider}
            />
            <PrivateRoute
              path={routes.serviceProviderMoreDetailsUrl}
              exact
              component={EditServiceProvider}
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestor,
              ]}
            />
            <PrivateRoute
              exact
              path={routes.settingsOverviewUrl}
              permittedUserTypes={[USER_ROLES.superAdmin]}
              component={Settings}
            />
            <PrivateRoute
              path={routes.cohortsUrl}
              exact
              permittedUserTypes={[USER_ROLES.superAdmin, USER_ROLES.admin]}
              component={CohortOverview}
            />
            <PrivateRoute
              path={routes.startupsUrl}
              exact
              permittedUserTypes={[USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.partner]}
              component={StartupsOverview}
            />
            <PrivateRoute
              path={routes.viewAllServicesUrl}
              exact
              permittedUserTypes={[USER_ROLES.startupFounder, USER_ROLES.startupTeamMember]}
              component={ServicesOverView}
            />
            <PrivateRoute
              exact
              path={routes.editStartupUrl}
              permittedUserTypes={[USER_ROLES.admin, USER_ROLES.superAdmin]}
              component={EditStartup}
            />
            <PrivateRoute
              path={routes.peopleUrl}
              exact
              permittedUserTypes={[USER_ROLES.superAdmin, USER_ROLES.admin]}
              component={PeopleOverview}
            />
            <PrivateRoute
              path={routes.serviceProvidersUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
              ]}
              component={ServicesAndServiceProviders}
            />
            <PrivateRoute
              path={routes.editPeopleProfileUrl}
              exact
              permittedUserTypes={[USER_ROLES.superAdmin, USER_ROLES.admin]}
              component={EditPeopleProfile}
            />
            <PrivateRoute
              exact
              path={routes.editCohortUrl}
              permittedUserTypes={[USER_ROLES.admin, USER_ROLES.superAdmin]}
              component={EditCohort}
            />
            <PrivateRoute
              path={routes.cohortsDetailUrl}
              exact
              permittedUserTypes={[USER_ROLES.superAdmin, USER_ROLES.admin]}
              component={CohortDetails}
            />
            <PrivateRoute
              path={routes.submitOverviewUrl}
              exact
              permittedUserTypes={[USER_ROLES.startupFounder, USER_ROLES.startupTeamMember, USER_ROLES.superAdmin]}
              component={SubmitOverview}
            />
            <PrivateRoute
              path={routes.teamMembersUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestor,
              ]}
              component={TeamMembers}
            />
            <PrivateRoute
              path={routes.startupDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.serviceProvider,
                USER_ROLES.partner,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
              component={StartupDetails}
            />
            <PrivateRoute
              path={routes.peopleDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.angelInvestor,
              ]}
              component={MemberDetails}
            />
            <PrivateRoute
              path={routes.serviceProviderDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.angelInvestor,
              ]}
              component={ServiceProviderDetails}
            />
            <PrivateRoute
              path={routes.requestsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.startupFounder,
                USER_ROLES.serviceProvider,
                USER_ROLES.startupTeamMember,
                USER_ROLES.partner,
                USER_ROLES.angelInvestor,
              ]}
              component={RequestsOverview}
            />
            <PrivateRoute
              path={routes.requestMoreDetailsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.serviceProvider,
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
              ]}
              component={IncentiveRequestDetails}
            />
            <PrivateRoute
              component={ChangePassword}
              path={routes.changePasswordUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.employee,
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
                USER_ROLES.serviceProvider,
                USER_ROLES.angelInvestor,
              ]}
            />
            <PrivateRoute
              path={routes.servicesUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.serviceProvider,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.angelInvestor,
              ]}
              component={ServicesAndServiceProviders}
            />
            <PrivateRoute
              path={routes.eventsUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.partner,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.serviceProvider,
              ]}
              component={Events}
            />
            <PrivateRoute
              component={PartnersOverview}
              path={routes.partnersUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
              ]}
            />
            <PrivateRoute
              component={MyProfile}
              path={routes.myProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
                USER_ROLES.partner,
                USER_ROLES.serviceProvider,
                USER_ROLES.angelInvestor,
              ]}
            />
            <PrivateRoute
              component={EditMyProfile}
              path={routes.editMyProfileUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.startupTeamMember,
                USER_ROLES.startupFounder,
                USER_ROLES.partner,
                USER_ROLES.serviceProvider,
                USER_ROLES.angelInvestor,
              ]}
            />
            <PrivateRoute
              component={EditCompanyProfile}
              path={routes.editStartupProfileUrl}
              exact
              permittedUserTypes={[USER_ROLES.startupFounder]}
            />
            <PrivateRoute
              component={CompanyProfile}
              path={routes.startupProfileUrl}
              exact
              permittedUserTypes={[USER_ROLES.startupFounder, USER_ROLES.startupTeamMember]}
            />
            <PrivateRoute
              component={CompanyProfile}
              path={routes.companyProfileUrl}
              exact
              permittedUserTypes={[USER_ROLES.serviceProvider, USER_ROLES.partner, USER_ROLES.angelInvestor]}
            />
            <PrivateRoute
              component={EditCompanyProfile}
              exact
              path={routes.editCompanyProfileUrl}
              permittedUserTypes={[USER_ROLES.serviceProvider, USER_ROLES.partner, USER_ROLES.angelInvestor]}
            />
            <PrivateRoute
              component={Dashboard}
              path={routes.dashboardUrl}
              exact
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.serviceProvider,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.partner,
                USER_ROLES.employee,
                USER_ROLES.angelInvestor,
              ]}
            />
            <PrivateRoute
              component={EditPartner}
              exact
              path={routes.editPartnerUrl}
              permittedUserTypes={[USER_ROLES.superAdmin, USER_ROLES.admin]}
            />
            <PrivateRoute
              exact
              path={routes.servicesAndSpsOverviewUrl}
              permittedUserTypes={[
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
              component={ServicesAndServiceProviders}
            />
            <PrivateRoute
              component={PartnerDetails}
              exact
              path={routes.partnerDetailsUrl}
              permittedUserTypes={[
                USER_ROLES.superAdmin,
                USER_ROLES.admin,
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
              ]}
            />
            <PrivateRoute
              component={Performance}
              exact
              path={routes.performanceUrl}
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
            />
            <PrivateRoute
              component={CreatePerformanceReport}
              exact
              path={routes.createPerformanceReportUrl}
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
            />
            <PrivateRoute
              component={CreatePerformanceReport}
              exact
              path={routes.editPerformanceReportUrl}
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
              ]}
            />
            <PrivateRoute
              component={SingleServiceOverview}
              exact
              path={routes.serviceOverview}
              permittedUserTypes={[
                USER_ROLES.startupFounder,
                USER_ROLES.startupTeamMember,
                USER_ROLES.admin,
                USER_ROLES.superAdmin,
                USER_ROLES.serviceProvider,
              ]}
            />
            <Route path="*" component={NotFound} />
          </Switch>
        </Content>
        <div className={classNames('layout-with-sidebar__notifications', isOpen ? '' : 'hidden')}>
          <ActivitySidebar />
        </div>
        <NoticeModal />
      </Layout>
    </Layout>
  );
};

export default withRouter(LayoutWithSidebar);
