import { AIWalletStateType } from 'types/reduxTypes/StateTypes';

export const defaultAIWalletState: AIWalletStateType = {
  wallet: {
    id: 0,
    angelInvestorID: 0,
    balance: '0',
    totalWalletAmount: '0',
    consumedAmount: '0',
    blockedAmount: '0',
  },
  walletTransactions: [],
  isLoading: false,
  count: 0,
}

export default null;
