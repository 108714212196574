import { formatUrl } from 'packages/http_client/utils/converters';
import { identity } from 'utils';
import { startupDef } from 'redux/utils';
import {
  ADGMLicenseType,
  CreateADGMLicenseApiRequestPayload,
  CreateADGMLicensePayload,
  CreateIncentiveAgreementBodyType,
  CreateStartupPayload,
  CreateStartupRequestPayload,
  EditStartupPayload,
  GetInvestmentStagesApiResponseType,
  GetSectorsApiResponseType,
  GetStagesApiResponseType,
  GetStartupsApiResponseType,
  InvestmentStageResponseType,
  ResponseStartupType,
  SectorResponseType,
  SectorType,
  StageResponseType,
  StageType,
  StartupQuery,
  StartupType,
  UpdateAdgmLicenseByStartupIdRequestPayload,
  UpdateCreateIncentiveAgreementType,
  UpdateIncentiveAgreementBodyType,
  UpdateStartupRequestPayload,
} from '../types';
import { queryBuilder } from '../../http_client';
import { convertGetCohortResponse } from '../../cohort_repository';

export const convertIncentiveAgreementBody = (data: UpdateCreateIncentiveAgreementType)
  : CreateIncentiveAgreementBodyType | UpdateIncentiveAgreementBodyType => ({
  _startup_id: data.startupID,
  _start_date: data.startDate,
  _end_date: data.endDate,
  _file: data.file as Blob,
  _wallet_amount: data.walletAmount,
});
export const convertGetStagesResponse = (stage: StageResponseType): StageType => ({
  id: stage.id,
  name: stage.name,
  createdAt: stage.createdAt,
});

export const convertGetInvestmentStagesResponse = (stage: InvestmentStageResponseType): StageType => ({
  id: stage.id,
  name: stage.invStageName || stage.name,
});

export const convertGetSectorsResponse = (sector: SectorResponseType): SectorType => ({
  id: sector.id,
  name: sector.name,
});

export const convertCreateStartupRequestPayload = (payload: CreateStartupPayload): CreateStartupRequestPayload => ({
  _name: payload.name,
  _cohort_id: payload.cohort,
  _location: payload.location,
  _logo: payload.logo,
  _primary_sector_id: payload.primarySector,
  _secondary_sector_id: payload.secondarySector,
  _website: payload.website,
  _stage_id: payload.stage,
  _investment_stage_id: payload.investmentStage,
  _email: payload.email,
  _first_name: payload.firstName,
  _last_name: payload.lastName,
  _job_title: payload.jobTitle,
  _number_of_employees: payload.numberOfEmployees,
  _description: payload.description,
  _acceptance_letter: payload.acceptanceLetter,
});

export const convertCreateADMGLicenseRequestPayload = (payload: CreateADGMLicensePayload):
CreateADGMLicenseApiRequestPayload => ({
  _adgm_license_no: payload.aDGMLicenseNo,
  _startup_id: payload.startupId,
  _adgm_license_url: payload.aDGMLicenseUrl,
  _legal_signatory_name: payload?.legalSignatoryName!,
  _legal_adgm_name: payload?.legalADGMName!,
  _legal_signatory_email: payload?.legalSignatoryEmail!,
  _legal_signatory_title: payload?.legalSignatoryTitle!,
  _acceptance_date: payload?.acceptanceDate!,
  _issue_date: payload?.issueDate!,
  _expiry_date: payload?.expiryDate!,
  _address: payload?.address!,

});

export const convertGetStagesListResponse = (response?: GetStagesApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result.map((stage) => convertGetStagesResponse(stage));
  }
}

export const convertGetInvestmentStagesListResponse = (response?: GetInvestmentStagesApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result.map((stage) => convertGetInvestmentStagesResponse(stage));
  }
}

export const convertGetSectorsListResponse = (response?: GetSectorsApiResponseType) => {
  if (identity.isFullArray(response?.result)) {
    return response?.result!.map((sector) => convertGetSectorsResponse(sector));
  }
}

export const convertCreateStartupApiId = (data?: number[]) => (identity.isFunction(data) ? data![0] : undefined);

export const convertGetStartupResponse = (startup?: ResponseStartupType): StartupType => {
  if (identity.isObjWithChildren(startup)) {
    return {
      id: startup?.id!,
      name: startup?.name!,
      primarySector: startup?.primarySector!,
      primarySectorID: startup?.primarySectorID!,
      secondarySector: startup?.secondarySector!,
      secondarySectorID: startup?.secondarySectorID!,
      stage: startup?.stage.id!,
      website: startup?.website!,
      numberOfEmployees: startup?.numberOfEmployees!,
      cohort: convertGetCohortResponse(startup?.cohort)!,
      about: startup?.description!,
      imageLink: startup?.logo!,
      location: startup?.location!,
      industry: startup?.primarySector?.name!,
      stageName: startup?.stage.name!,
      walletID: startup?.walletID!,
      wallet: startup?.wallet!,
      status: startup?.status!,
      founderEmail: startup?.email!,
      aDGMApprovedStatus: startup?.aDGMApprovedStatus!,
      acceptanceLetterApprovedStatus: startup?.acceptanceLetterApprovedStatus!,
      incentiveAgreementApprovedStatus: startup?.incentiveAgreementApprovedStatus!,
      currentADGMLicense: startup?.currentADGMLicense!,
      currentADGMLicenseID: startup?.currentADGMLicenseID!,
      currentIncentiveAgreement: startup?.currentIncentiveAgreement,
      currentIncentiveAgreementID: startup?.currentIncentiveAgreementID!,
      acceptanceLetter: startup?.acceptanceLetter!,
      firstName: startup?.firstName!,
      lastName: startup?.lastName!,
      facebook: startup?.facebookPage,
      instagram: startup?.instagramID,
      twitter: startup?.twitterID,
      linkedIn: startup?.linkedInPage,
    };
  }
  return startupDef;
}

export const convertGetAdGMLicenseResponse = (adgmLicense?: ADGMLicenseType): ADGMLicenseType => ({
  id: adgmLicense?.id!,
  aDGMLicenseNo: adgmLicense?.aDGMLicenseNo!,
  aDGMLicenseUrl: adgmLicense?.aDGMLicenseUrl!,
  startupID: adgmLicense?.startupID!,
  legalSignatoryName: adgmLicense?.legalSignatoryName!,
  legalSignatoryTitle: adgmLicense?.legalSignatoryTitle!,
  legalSignatoryEmail: adgmLicense?.legalSignatoryEmail!,
  legalADGMName: adgmLicense?.legalADGMName!,
  acceptanceDate: adgmLicense?.acceptanceDate!,
  expiryDate: adgmLicense?.expiryDate!,
  issueDate: adgmLicense?.issueDate!,
  address: adgmLicense?.address!,
});

export const convertGetStartupListResponse = (response: GetStartupsApiResponseType | undefined): StartupType[] => {
  const data: StartupType[] = [];
  if (identity.isFullArray(response?.result)) {
    response?.result!.forEach((startup: ResponseStartupType) => {
      const mappedStartup = convertGetStartupResponse(startup);
      if (identity.isObjWithChildren(mappedStartup)) {
        data.push(mappedStartup!);
      }
    });
  }

  return data;
}

export const convertEditStartupRequestPayload = (payload: Partial<EditStartupPayload>)
  : UpdateStartupRequestPayload => ({
  _name: payload?.name!,
  _number_of_employees: payload.numberOfEmployees,
  _website: payload?.website!,
  _description: payload?.about!,
  _location: payload?.location!,
  _stage_id: payload?.stage!,
  _primary_sector_id: payload?.primarySectorID!,
  _logo: payload?.imageLink!,
  _facebook_page: formatUrl(payload?.facebook),
  _instagram_id: formatUrl(payload?.instagram),
  _linked_in_page: formatUrl(payload?.linkedIn),
  _twitter_id: formatUrl(payload?.twitter),
  _acceptance_letter_approved_status: payload.acceptanceLetterApprovedStatus,
  _adgm_approved_status: payload.aDGMApprovedStatus,
  _incentive_agreement_approved_status: payload.incentiveAgreementApprovedStatus,
  _acceptance_letter: payload.acceptanceLetter,
});

export const convertEditAdgmLicenseByStartupIdRequestPayload = (
  payload: ADGMLicenseType,
): UpdateAdgmLicenseByStartupIdRequestPayload => ({
  _adgm_license_no: payload?.aDGMLicenseNo!,
  _legal_signatory_name: payload?.legalSignatoryName!,
  _legal_adgm_name: payload?.legalADGMName!,
  _legal_signatory_email: payload?.legalSignatoryEmail!,
  _legal_signatory_title: payload?.legalSignatoryTitle!,
  _acceptance_date: payload?.acceptanceDate!,
  _issue_date: payload?.issueDate!,
  _expiry_date: payload?.expiryDate!,
  _address: payload?.address!,
  _adgm_license_url: payload?.aDGMLicenseUrl!,
});

export const formatQuery = (query: StartupQuery) => {
  const {
    isPreload, limit, offset, distinct, statusID, partnerId, serviceProviderId,
  } = query;
  const {
    industries, cohorts, locations, stage, capitalRaised, incentiveAmount, fieldSelection, searchString,
  } = query.filters || {};
  const queryRelatedTable = [];
  const joinTables = [];
  const params = queryBuilder({ stage_id: identity.isPositiveNumber(stage) ? stage : undefined, status_id: statusID });
  if (identity.isTruthyString(searchString)) {
    params.searchQuery(searchString!);
  }
  if (identity.isTruthyString(capitalRaised)) {
    queryRelatedTable.push({ tableName: 'wallet_amount_utilizations', field: 'total_wallet_amount', value: capitalRaised });
  }
  if (identity.isTruthyString(incentiveAmount)) {
    queryRelatedTable.push({ tableName: 'wallet_amount_utilizations', field: 'consumed_amount', value: incentiveAmount });
  }
  if (identity.isTruthyNumber(partnerId)) {
    queryRelatedTable.push({ tableName: 'partner_requests', field: 'partner_id', value: partnerId!.toString() });
    joinTables.push({ tableName: 'partner_requests', onId: 'startups.id__partner_requests.startup_id' });
  } else if (identity.isTruthyNumber(serviceProviderId)) {
    queryRelatedTable.push({ tableName: 'service_requests', field: 'service_provider_id', value: serviceProviderId!.toString() });
    joinTables.push({ tableName: 'service_requests', onId: 'startups.id__service_requests.startup_id' });
  }
  if (isPreload) {
    params.preload();
  }
  if (distinct || identity.isTruthyNumber(query?.serviceProviderId) || identity.isTruthyNumber(query?.partnerId)) {
    params.distinct();
  }
  if (identity.isTruthyString(incentiveAmount) || identity.isTruthyString(capitalRaised)) {
    joinTables.push({ tableName: 'wallet_amount_utilizations', onId: 'wallet_id' });
  }

  params
    .limit(limit)
    .offset(offset)
    .select(fieldSelection)
    .in({ location: locations, cohort_id: cohorts, primary_sector_id: industries })
    .queryRelatedTable(queryRelatedTable)
    .join(joinTables);
  return params.toObject();
}
