import { API_ROOT } from 'packages/http_client/utils';
import { StatusType } from 'packages/service_repository';
import REQUEST_STATUS from 'utils/constants/requestStatusTypes';

export const SERVICE_REQUEST_API = `${API_ROOT}/servicerequest`;
export const SERVICE_REQUEST_ITEM_API = `${API_ROOT}/servicerequestitem`;
export const SERVICE_REQUEST_DOCUMENT_API = `${API_ROOT}/servicerequestdocument`;
export const SERVICE_REQUEST_COMMENT = `${API_ROOT}/servicerequestcomment`;
export const REQUEST_API = `${API_ROOT}/request/`;

export type CamelCaseStatusType = 'unsent' | 'rejected' | 'delivered' | 'inProgress' | 'pendingHUB71' | 'quoted' | 'new' | 'pendingQuotation' | 'quotedStartup' | 'quotedAngelInvestor'

export const NUMBER_TO_REQUEST_STATUS_MAP: {[key: number]: StatusType} = {
  1: REQUEST_STATUS.unsent,
  2: REQUEST_STATUS.new,
  3: REQUEST_STATUS.quoted,
  4: REQUEST_STATUS.pendingHUB71,
  5: REQUEST_STATUS.inProgress,
  6: REQUEST_STATUS.rejected,
  7: REQUEST_STATUS.delivered,
};

export const REQUEST_STATUS_TO_NUMBER_MAP: {[key: string]: number} = {
  [REQUEST_STATUS.unsent]: 1,
  [REQUEST_STATUS.new]: 2,
  [REQUEST_STATUS.pendingQuotation]: 2,
  [REQUEST_STATUS.quoted]: 3,
  [REQUEST_STATUS.pendingHUB71]: 4,
  [REQUEST_STATUS.inProgress]: 5,
  [REQUEST_STATUS.rejected]: 6,
  [REQUEST_STATUS.delivered]: 7,
};

export const NUMBER_TO_REQUEST_STATUS_KEY_MAP: {[key: number]: CamelCaseStatusType} = {
  1: 'unsent',
  2: 'new',
  3: 'quoted',
  4: 'pendingHUB71',
  5: 'inProgress',
  6: 'rejected',
  7: 'delivered',
};

export const REQUEST_STATUS_TO_REQUEST_STATUS_KEY_MAP: {[key: string]: CamelCaseStatusType | 'all'} = {
  all: 'all',
  [REQUEST_STATUS.unsent]: 'unsent',
  [REQUEST_STATUS.new]: 'new',
  [REQUEST_STATUS.quoted]: 'quoted',
  [REQUEST_STATUS.quotedStartup]: 'quoted',
  [REQUEST_STATUS.quotedAngelInvestor]: 'quoted',
  [REQUEST_STATUS.pendingHUB71]: 'pendingHUB71',
  [REQUEST_STATUS.inProgress]: 'inProgress',
  [REQUEST_STATUS.rejected]: 'rejected',
  [REQUEST_STATUS.delivered]: 'delivered',
  [REQUEST_STATUS.pendingQuotation]: 'new',
}
