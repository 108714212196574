import React from 'react';

import { ProfileProps } from 'types/user';
import { ROLES } from 'utils';
import AngelInvestorDetails from '../../AngelInvestorDetails';
import StartupDetails from '../../StartupDetails';
import ServiceProviderDetails from '../../ServiceProviderDetails';
import PartnerDetails from '../../PartnerDetails';

const CompanyProfile = (props: ProfileProps) => {
  const { userType } = props;

  switch (userType) {
    case ROLES.startupFounder:
    case ROLES.startupTeamMember: {
      return <StartupDetails />;
    }
    case ROLES.angelInvestor: {
      return <AngelInvestorDetails />;
    }
    case ROLES.serviceProvider: {
      return <ServiceProviderDetails />;
    }
    case ROLES.partner: {
      return <PartnerDetails />;
    }
    default: {
      return null;
    }
  }
}

export default CompanyProfile;
