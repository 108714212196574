import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { Button, Form } from 'antd';
import { RcFile } from 'antd/lib/upload';

import {
  identity,
  isStartupFounder,
  validateFacebookLink,
  validateInstagramLink,
  validateTwitterLink,
  validateLinkedInLink,
  DRAWERS,
  isEitherAdmin,
} from 'utils';
import {
  TitleWithActions,
  Input,
  Breadcrumb,
  EditProfileImage,
} from 'components/components';
import { scrollToFirstError } from 'components/components/FormBuilder/utils/helpers';
import { EditStartupProps } from 'types/startups';
import routes from 'routes';
import { composeStartupDetailsUrl } from 'routes/routeComposers';
import { getStartupFile, useEditStartupHook } from './utils';
import { formatSlashMDY } from '../utils/constants/formatters';
import { ViewUploadButton } from './components';

import './EditStartup.scss';

const EditStartup = (props: EditStartupProps) => {
  const {
    selectedStartup,
    sectorOptions,
    stageOptions,
    userStartupId,
    userType,
  } = props;
  const {
    getStartupById,
    updateStartupById,
    getStartupSectors,
    getStartupStages,
    setDrawerBody,
    setIsDrawerOpen,
  } = props.actions;

  const [form] = Form.useForm();

  useEffect(() => {
    if (!identity.isFullArray(sectorOptions)) {
      getStartupSectors();
    }
    if (!identity.isFullArray(stageOptions)) {
      getStartupStages();
    }
  }, []);

  const params: { id?: string } = useParams();
  const { id } = params;
  const startupId = useMemo(() => (identity.isTruthyNumber(userStartupId) ? userStartupId : parseInt(id!, 10)), [id]);

  const { state, setAll, setField } = useEditStartupHook();

  const {
    currentADGMLicense,
    aDGMApprovedStatus,
    incentiveAgreementApprovedStatus,
    currentIncentiveAgreement,
    acceptanceLetter,
    acceptanceLetterApprovedStatus,
    status,
    imageLink,
    facebook,
    twitter,
    instagram,
    linkedIn,
    about,
    stage,
    location,
    website,
  } = state;

  const waitingForApprovalIA = useMemo(() => [
    identity.isZero(incentiveAgreementApprovedStatus),
    identity.isTruthyString(currentIncentiveAgreement?.file),
  ].every(Boolean), [currentIncentiveAgreement, incentiveAgreementApprovedStatus]);

  const waitingForApprovalADGM = useMemo(() => [
    identity.isZero(aDGMApprovedStatus),
    identity.isTruthyString(currentADGMLicense?.aDGMLicenseUrl),
  ].every(Boolean), [aDGMApprovedStatus, currentADGMLicense]);

  const waitingForApprovalAL = useMemo(() => [
    identity.isZero(status.id),
    identity.isEmptyString(acceptanceLetter),
  ].some(Boolean), [status, acceptanceLetter]);

  useEffect(() => {
    getStartupById({ id: startupId, owner: 'startup', isUpdate: false });
  }, [id]);

  useEffect(() => {
    setAll({ startup: selectedStartup });
  }, [selectedStartup]);

  useEffect(() => {
    form.setFieldsValue(state);
  }, [state]);

  const handleSubmit = () => {
    updateStartupById({ id: startupId, startup: state });
  }

  const beforeLogoUpload = (file: RcFile, field: 'imageLink') => {
    setField({ field, value: file as Blob });
    return false;
  }

  const beforeAcceptanceLetterUpload = (file: RcFile) => {
    setField({ field: 'acceptanceLetter', value: file as Blob });
    return false;
  }

  const handleOpenDrawer = (drawerBody: string) => {
    setIsDrawerOpen({ isDrawerOpen: true });
    setDrawerBody({ drawerBody });
  }

  return (
    <div className="edit-startup__container content-container">
      <Breadcrumb navigateTo={isStartupFounder(userType) ? routes.startupProfileUrl : composeStartupDetailsUrl(startupId)} routeName="Startup Details" />
      <TitleWithActions title={selectedStartup?.name} />
      <Form
        onFinish={handleSubmit}
        onFinishFailed={scrollToFirstError}
        validateTrigger="onSubmit"
        form={form}
      >
        <div className="edit-startup__fields">
          <div className="edit-startup__section">
            <EditProfileImage imageLink={imageLink} beforeLogoUpload={beforeLogoUpload} />
            <Input.Heading title="Cohort Details" />
            <Input label="Name">
              <Input.InputField
                type="text"
                value={selectedStartup.cohort.name}
                required
                disabled
              />
            </Input>
            <Input label="Amount">
              <Input.InputField
                type="text"
                value={selectedStartup.cohort.amount}
                required
                disabled
              />
            </Input>
            <Input label="From date">
              <Input.DatePicker
                placeholder=""
                suffixIcon={null}
                format={formatSlashMDY}
                value={moment(selectedStartup.cohort.nominationFrom)}
                disabled
              />
            </Input>
            <Input label="To date">
              <Input.DatePicker
                placeholder=""
                suffixIcon={null}
                format={formatSlashMDY}
                value={moment(selectedStartup.cohort.nominationTo)}
                disabled
              />
            </Input>
          </div>
          <div className="edit-startup__section">
            <Input.Heading title="Startup Details" />
            <Input label="About">
              <Input.InputField.TextArea
                rows={3}
                value={about}
                required
                onChange={(e) => setField({ field: 'about', value: e.target.value })}
              />
            </Input>
            <Input label="Primary sector">
              <Input.Select
                value={selectedStartup.primarySectorID}
                options={sectorOptions}
                size="large"
                onChange={(e) => setField({ field: 'primarySectorID', value: e })}
              />
            </Input>
            <Input label="Startup stage">
              <Input.Select
                value={stage}
                options={stageOptions}
                onChange={(e) => setField({ field: 'stage', value: e })}
              />
            </Input>
            <Input label="Location">
              <Input.InputField
                type="text"
                value={location}
                required
                onChange={(e) => setField({ field: 'location', value: e.target.value })}
              />
            </Input>
            <Input label="Website">
              <Input.InputField
                type="text"
                value={website}
                onChange={(e) => setField({ field: 'website', value: e.target.value })}
              />
            </Input>
          </div>
          <div className="edit-startup__section">
            <Input.Heading title="Documents" />
            <Input label="Acceptance Letter">
              {waitingForApprovalAL || (isEitherAdmin(userType) && acceptanceLetterApprovedStatus !== 1)
                ? (
                  <p className="message">
                    {waitingForApprovalAL
                      ? 'Waiting for Acceptance Letter to be approved.'
                      : 'Waiting for startup to upload acceptance letter.'}
                  </p>
                )
                : (
                  <Input.DraggerUpload
                    defaultFileList={identity.isTruthyString(acceptanceLetter)
                      ? [...getStartupFile(acceptanceLetter!)] : []}
                    previewType="image"
                    beforeUpload={beforeAcceptanceLetterUpload}
                    accept=".pdf"
                    isRoundableButton
                    name={identity.isTruthyString(acceptanceLetter) ? 'acceptanceLetter' : ''}
                    allowChange={identity.isZero(acceptanceLetterApprovedStatus)
                        && identity.isTruthyString(acceptanceLetter)}
                  />
                )}
            </Input>
            <Input label="ADGM License">
              {waitingForApprovalADGM
                ? <p className="message">Waiting for ADGM license to be approved.</p>
                : (
                  <ViewUploadButton
                    userType={userType}
                    name="ADGM License"
                    onClick={() => handleOpenDrawer(DRAWERS.startupAdgmLicenseDetails)}
                    condition={aDGMApprovedStatus === 1 && identity.isTruthyString(currentADGMLicense?.aDGMLicenseUrl)}
                    status={aDGMApprovedStatus}
                  />
                )}
            </Input>
            <Input label="Incentive Agreement">
              {waitingForApprovalIA
                ? <p className="message">Waiting for Incentive Agreement to be approved.</p>
                : (
                  <ViewUploadButton
                    userType={userType}
                    name="Incentive Agreement"
                    onClick={() => handleOpenDrawer(DRAWERS.incentiveAgreementDetails)}
                    condition={incentiveAgreementApprovedStatus === 1
                      && identity.isTruthyString(currentIncentiveAgreement?.file)}
                    status={incentiveAgreementApprovedStatus}
                  />
                )}
            </Input>
            <Input.Heading title="Social media links" />
            <Input label="Facebook" name="facebook" rules={[validateFacebookLink]}>
              <Input.InputField
                type="text"
                placeholder="Please enter facebook link, e.g. https://www.facebook.com/username"
                value={facebook}
                onChange={({ target: { value } }) => setField({ field: 'facebook', value })}
              />
            </Input>
            <Input
              label="Instagram"
              name="instagram"
              rules={[validateInstagramLink]}
            >
              <Input.InputField
                type="text"
                placeholder="Please enter instagram link, e.g. https://www.instagram.com/username"
                value={instagram}
                onChange={(e) => setField({ field: 'instagram', value: e.target.value })}
              />
            </Input>
            <Input label="LinkedIn" name="linkedIn" rules={[validateLinkedInLink]}>
              <Input.InputField
                type="text"
                placeholder="Please enter linkedIn link, e.g. https://www.linkedin.com/username"
                value={linkedIn}
                onChange={({ target: { value } }) => setField({ field: 'linkedIn', value })}
              />
            </Input>
            <Input label="Twitter" name="twitter" rules={[validateTwitterLink]}>
              <Input.InputField
                type="text"
                placeholder="Please enter twitter link, e.g. https://www.twitter.com/username"
                value={twitter}
                onChange={({ target: { value } }) => setField({ field: 'twitter', value })}
              />
            </Input>
          </div>
          <Button type="primary" htmlType="submit" className="primary-button">
            Update Startup
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default EditStartup;
