import { AxiosRequestConfig } from 'axios';

import httpClient, {
  ApiResponseType,
  queryBuilder,
  ParamsPayload,
} from 'packages/http_client';
import { identity } from 'utils';
import { FORM_DATA_CONFIG } from 'packages/http_client/utils';
import {
  GetAngelInvestorsWorkingWithIdCountResponse,
  CreateAngelInvestorPayload,
  CreateAngelInvestorApiResponseType,
  GetAngelInvestorListCountApiResponseType,
  GetAngelInvestorListResponse,
  GetAngelInvestorsCountResponse,
  AngelInvestorQuery,
  AngelInvestorType,
  GetAngelInvestorApiResponseType,
  GetAngelInvestorsApiResponseType,
  UpdateAngelInvestorApiResponseType,
  EditAngelInvestorPayload,
} from '../types';
import {
  formatQuery,
  ANGELINVESTOR_API,
  convertCreateAngelInvestorRequestPayload,
  convertGetAngelInvestorResponse,
  convertGetAngelInvestorListResponse,
  convertEditAngelInvestorRequestPayload,
} from '../utils';

const getAngelInvestorsListApi = (query: AngelInvestorQuery) => {
  const url = `${ANGELINVESTOR_API}`;
  const config: AxiosRequestConfig = { params: {} };
  config.params = formatQuery(query);
  return httpClient.get<ApiResponseType<GetAngelInvestorsApiResponseType>>(url, config);
}

const getAngelInvestorsListCount = (query: AngelInvestorQuery) => {
  const url = `${ANGELINVESTOR_API}`;
  const config: AxiosRequestConfig = { params: {} };
  const countQuery: AngelInvestorQuery = { ...query, filters: { ...query.filters, fieldSelection: [{ field: 'id', aggregation: 'count' }] } };
  config.params = formatQuery(countQuery);
  return httpClient.get<ApiResponseType<GetAngelInvestorListCountApiResponseType>>(url, config);
}

const getAngelInvestorsCountWorkingWithIdCountApi = (query: ParamsPayload) => {
  const url = ANGELINVESTOR_API;
  const params = formatQuery({ ...query, fieldSelection: ['angel_investor_id'], distinct: true });
  const config: AxiosRequestConfig = { params };
  return httpClient.get<ApiResponseType<GetAngelInvestorsWorkingWithIdCountResponse>>(url, config);
}

const createAngelInvestorApi = (payload: CreateAngelInvestorPayload) => {
  const url = ANGELINVESTOR_API;
  const requestPayload = convertCreateAngelInvestorRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateAngelInvestorApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

const updateAngelInvestorApi = (id: number, payload: Partial<EditAngelInvestorPayload>) => {
  const url = `${ANGELINVESTOR_API}/${id}`;
  const requestPayload = convertEditAngelInvestorRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateAngelInvestorApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
};

export const createAngelInvestor = async (payload: CreateAngelInvestorPayload) => {
  const response = await createAngelInvestorApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: data?.id[0] };
}

const getAngelInvestorApi = (id: number, isPreload: boolean) => {
  const url = `${ANGELINVESTOR_API}/${id}`;
  const params = queryBuilder();
  if (isPreload) {
    params.preload();
  }
  const config: AxiosRequestConfig = { params: params.toObject() };
  return httpClient.get<ApiResponseType<GetAngelInvestorApiResponseType>>(url, config);
};

export const getAngelInvestorById = async (
  id: number,
  isPreload: boolean,
): Promise<ApiResponseType<AngelInvestorType>> => {
  const response = await getAngelInvestorApi(id, isPreload);
  const { data: angelInvestor, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAngelInvestorResponse(angelInvestor?.result) };
};

export const updateAngelInvestor = async (id: number, payload: Partial<AngelInvestorType>) => {
  const response = await updateAngelInvestorApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const getAngelInvestorsCountApi = async (query: AngelInvestorQuery): Promise<GetAngelInvestorsCountResponse> => {
  const response = await getAngelInvestorsListCount(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: data?.result![0].id__count };
}

export const getAngelInvestorsCountWorkingWithIdCount = async (
  query: ParamsPayload,
): Promise<GetAngelInvestorsCountResponse> => {
  const response = await getAngelInvestorsCountWorkingWithIdCountApi(query);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: identity.isFullArray(data?.result) ? data?.result!.length : 0 };
}

export const getAngelInvestorsList = async (query?: AngelInvestorQuery): Promise<GetAngelInvestorListResponse> => {
  const response = await getAngelInvestorsListApi(query || {});
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAngelInvestorListResponse(data) };
}
