import React, { useMemo } from 'react';
import { InferProps } from 'prop-types';

import { UserRoleType, UserType } from 'types/auth';
import { RenderIf, TitleWithActions } from 'components/components';
import { identity, isEitherAdmin, ROLES } from 'utils';
import { PartnerType } from 'packages/partner_repository';
import { StartupType } from 'packages/startup_repository';
import { ServiceProviderType } from 'packages/service_repository';
import { AngelInvestorType } from 'packages/angel_investors_repository';

type DashboardHeaderProps = InferProps<{
  userType: UserRoleType,
  partner: PartnerType,
  user: UserType,
  startup: StartupType,
  serviceProvider: ServiceProviderType,
  angelInvestor: AngelInvestorType,
}>

const DashboardHeader = (props: DashboardHeaderProps) => {
  const {
    userType, partner, user, startup, serviceProvider, angelInvestor,
  } = props;

  const userData = useMemo(() => {
    switch (userType) {
      case ROLES.partner: {
        return { userName: user.userName, logo: partner.logo as string, logoAlt: partner.name };
      }
      case ROLES.serviceProvider: {
        return { userName: user.userName, logo: serviceProvider.imageLink as string, logoAlt: serviceProvider.name };
      }
      case ROLES.startupTeamMember:
      case ROLES.startupFounder: {
        return { userName: user.userName, logo: startup.imageLink as string, logoAlt: startup.name };
      }
      case ROLES.angelInvestor: {
        return { userName: user.userName, logo: angelInvestor.imageLink as string, logoAlt: angelInvestor.name };
      }
      default: {
        return { userName: user.userName, logo: '', logoAlt: '' };
      }
    }
  }, [user, partner, startup, serviceProvider, angelInvestor]);
  const { logo, logoAlt, userName } = userData;

  return (
    <div className="dashboard-logo-title">
      <RenderIf condition={!isEitherAdmin(userType) && identity.isTruthyString(logo)}>
        <div className="dashboard-logo__container">
          <img className="dashboard-logo" src={logo} alt={logoAlt} />
        </div>
      </RenderIf>
      <TitleWithActions size="l" title={`Welcome, ${userName}`} subtitle="View some of your stats, events, and requests below" />
    </div>
  );
}

export default DashboardHeader;
