import { connect } from 'react-redux';
import { Action, bindActionCreators, Dispatch } from 'redux';

import { StateType } from 'types';
import { getAIWallet, getAIWalletTransactions } from 'redux/actions/ai_wallet';
import {
  selectIsLoading,
  selectAIWallet,
  selectAIWalletTransactions,
  selectAIWalletCount,
} from 'redux/selectors/ai_wallet';
import { selectUserAngelInvestorId, selectUserType } from 'redux/selectors/auth';
import { selectIsRowTableLayout } from 'redux/selectors/workspace';
import { setTableLayout } from 'redux/actions/workspace';

const mapStateToProps = (state: StateType) => ({
  isLoading: selectIsLoading(state),
  angelInvestorId: selectUserAngelInvestorId(state),
  userType: selectUserType(state),
  wallet: selectAIWallet(state),
  walletTransactions: selectAIWalletTransactions(state),
  isRowTableLayout: selectIsRowTableLayout(state),
  count: selectAIWalletCount(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAIWallet,
    getAIWalletTransactions,
    setTableLayout,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
