import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import { StateType } from 'types';
import { selectUserType } from 'redux/selectors/auth';
import { selectRequestOverviewFilters } from 'redux/selectors/request';
import {
  getStartups,
  getAngelInvestors,
  getWorkspacePartners,
  getWorkspaceServiceProviders,
} from 'redux/actions/workspace';
import {
  selectStartups,
  selectAngelInvestors,
  selectWorkspacePartners,
  selectWorkspaceServiceProviders,
} from 'redux/selectors/workspace';
import { setRequestOverviewFilters } from 'redux/actions/request';

const mapStateToProps = (state: StateType) => ({
  serviceProviders: selectWorkspaceServiceProviders(state),
  partners: selectWorkspacePartners(state),
  userType: selectUserType(state),
  startups: selectStartups(state),
  angelInvestors: selectAngelInvestors(state),
  filters: selectRequestOverviewFilters(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    setRequestOverviewFilters,
    getWorkspaceServiceProviders,
    getWorkspacePartners,
    getStartups,
    getAngelInvestors,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
