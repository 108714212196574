import { DataPayload } from 'types/reduxTypes/ActionTypes';
import { EditAngelInvestorMemberType } from 'types/people';
import { SetAngelInvestorFieldType } from 'types/reduxTypes/ActionTypes/PeopleTypes';

export const types = {
  SET_FIELD: 'SET_FIELD',
  SET_ALL: 'SET_ALL',
  SET_EMIRATES_ID_PEOPLE: 'SET_EMIRATES_ID_PEOPLE',
  SHOW_ERROR: 'SHOW_ERROR',
  SET_VISA: 'SET_VISA',
  SET_PASSPORT: 'SET_PASSPORT',
}

export const setAll = (payload: DataPayload<EditAngelInvestorMemberType>) => ({
  type: types.SET_ALL,
  payload,
});

export const setShowError = (payload: SetAngelInvestorFieldType) => ({
  type: types.SHOW_ERROR,
  payload,
});

export const setField = (payload: SetAngelInvestorFieldType) => ({
  type: types.SET_FIELD,
  payload,
});

export const setEmiratesId = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_EMIRATES_ID_PEOPLE,
  payload,
});

export const setVisa = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_VISA,
  payload,
});

export const setPassport = (payload: DataPayload<string | Blob>) => ({
  type: types.SET_PASSPORT,
  payload,
});
