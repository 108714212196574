import React from 'react';

import { InputColumnsProps } from 'types/services/requestService';
import {
  DefaultColumns,
  HousingColumns,
  InsuranceColumns,
  OfficeColumns,
} from './components';

const InputColumns = (props: InputColumnsProps) => {
  const {
    isStartupUser,
    state,
    details,
    teamMembersOptions,
    setRequestDocuments,
    setIsApplyingForDependant,
    setRelationToTM,
    setIsDependantUAE,
    setDependantGender,
    setDependantFullName,
    setDependantDateOfBirth,
    setChildPlaceOfBirth,
    setIsUserUAERes,
    setPlan,
    setTeamMember,
    setPriceType,
    setQuantity,
    setNoOfDesk,
    setTotalPrice,
    setFurnished,
    setAccommodationType,
    setOfficeType,
    setPassportFile,
    setVisaFile,
    setVisaProofFile,
    setEmiratesIDFile,
    setETAWithSignFile,
    setEmiratesIDNo,
    setPassportNo,
    setAITeamMember,
  } = props;

  const { getMemberById, getAngelInvestorMemberById } = props.actions;

  const {
    plan, priceType, quantity,
  } = state.request;

  switch (details.categoryId) {
    case 1: {
      return (
        <OfficeColumns
          setTeamMember={setTeamMember}
          setAITeamMember={setAITeamMember}
          setQuantity={setQuantity}
          setNoOfDesk={setNoOfDesk}
          setTotalPrice={setTotalPrice}
          setOfficeType={setOfficeType}
          state={state}
          teamMembersOptions={teamMembersOptions}
          getMemberById={getMemberById}
          getAngelInvestorMemberById={getAngelInvestorMemberById}
          member={props.member}
          aIMember={props.aIMember}
          isStartupUser={isStartupUser}
        />
      );
    }
    case 2: {
      return (
        <HousingColumns
          setTeamMember={setTeamMember}
          setAITeamMember={setAITeamMember}
          setFurnished={setFurnished}
          setAccommodationType={setAccommodationType}
          state={state}
          teamMembersOptions={teamMembersOptions}
          getMemberById={getMemberById}
          getAngelInvestorMemberById={getAngelInvestorMemberById}
          member={props.member}
          aIMember={props.aIMember}
          isStartupUser={isStartupUser}
          setQuantity={setQuantity}
        />
      );
    }
    case 3: {
      return (
        <InsuranceColumns
          isStartupUser={isStartupUser}
          teamMembersOptions={teamMembersOptions}
          getMemberById={getMemberById}
          getAngelInvestorMemberById={getAngelInvestorMemberById}
          state={state}
          plan={plan!}
          member={props.member}
          aIMember={props.aIMember}
          setIsApplyingForDependant={setIsApplyingForDependant}
          setRelationToTM={setRelationToTM}
          setIsDependantUAE={setIsDependantUAE}
          setDependantGender={setDependantGender}
          setDependantFullName={setDependantFullName}
          setDependantDateOfBirth={setDependantDateOfBirth}
          setChildPlaceOfBirth={setChildPlaceOfBirth}
          setIsUserUAERes={setIsUserUAERes}
          setPassportFile={setPassportFile}
          setVisaFile={setVisaFile}
          setTeamMember={setTeamMember}
          setAITeamMember={setAITeamMember}
          setPlan={setPlan}
          setVisaProofFile={setVisaProofFile}
          setEmiratesIDFile={setEmiratesIDFile}
          setRequestDocuments={setRequestDocuments}
          setETAWithSignFile={setETAWithSignFile}
          setQuantity={setQuantity}
          setEmiratesIDNo={setEmiratesIDNo}
          setPassportNo={setPassportNo}
        />
      );
    }
    default: {
      return (
        <DefaultColumns
          details={details}
          setPriceType={setPriceType}
          setQuantity={setQuantity}
          quantity={quantity}
          priceType={priceType!}
        />
      );
    }
  }
}

export default InputColumns;
