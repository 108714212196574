import produce from 'immer';

import {
  AngelInvestorMemberActionType,
  SetEventFilterPayload,
  SetEventsPayload,
  SetAngelInvestorMemberByIdPayload,
} from 'types/reduxTypes/ActionTypes/AngelInvestorMemberActionTypes';
import { AngelInvestorMembersStateType } from 'types/reduxTypes/StateTypes';
import { angelInvestorMembersDefaultState } from '../../utils/defaultStates/MemberDefaultState';
import { types } from '../../actions/angelInvestorMemberDetails';

const defaultState: AngelInvestorMembersStateType = angelInvestorMembersDefaultState;

const handleSetAngelInvestorMemberById = (
  state: AngelInvestorMembersStateType,
  payload: SetAngelInvestorMemberByIdPayload,
) => {
  state.selectedMember.data = payload.member;
  return state;
};

const handleSetPastEvents = (state: AngelInvestorMembersStateType, payload: SetEventsPayload) => {
  state.selectedMember.pastEvents = payload.events;
  return state;
};

const handleSetUpcomingEvents = (state: AngelInvestorMembersStateType, payload: SetEventsPayload) => {
  state.selectedMember.upcomingEvents = payload.events;
  return state;
};

const handleSetEventFilter = (state: AngelInvestorMembersStateType, payload: SetEventFilterPayload) => {
  state.selectedMember.eventFilter = payload.eventFilter;
  return state;
};

// eslint-disable-next-line default-param-last
export default produce((state: AngelInvestorMembersStateType = defaultState, action: AngelInvestorMemberActionType) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_EVENT_FILTER_TYPE: {
      return handleSetEventFilter(state, <SetEventFilterPayload> payload);
    }
    case types.SET_ANGEL_INVESTOR_MEMBER_BY_ID: {
      return handleSetAngelInvestorMemberById(state, <SetAngelInvestorMemberByIdPayload> payload);
    }
    case types.SET_PAST_EVENTS: {
      return handleSetPastEvents(state, <SetEventsPayload> payload);
    }
    case types.SET_UPCOMING_EVENTS: {
      return handleSetUpcomingEvents(state, <SetEventsPayload> payload);
    }
    case types.RESET_ANGEL_INVESTOR_MEMBER_DETAILS: {
      return defaultState;
    }
    default: {
      return state;
    }
  }
});
