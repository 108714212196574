import { GetAIWalletQueryParams, GetAIWalletTransactionsQueryParams } from 'packages/ai_wallet_repository';
import { SetAIWalletPayload, SetAIWalletTransactionsPayload } from 'types/reduxTypes/ActionTypes/AIWalletTypes';
import { DataPayload, IsLoadingPayload } from 'types/reduxTypes/ActionTypes';

export const types = {
  GET_AI_WALLET: 'GET_AI_WALLET',
  SET_AI_WALLET:'SET_AI_WALLET',
  GET_AI_WALLET_TRANSACTIONS: 'GET_AI_WALLET_TRANSACTIONS',
  SET_AI_WALLET_TRANSACTIONS: 'SET_AI_WALLET_TRANSACTIONS',
  SET_AI_WALLET_IS_LOADING: 'SET_AI_WALLET_IS_LOADING',
  RESET_AI_WALLET: 'RESET_AI_WALLET',
  GET_AI_WALLET_COUNT: 'GET_AI_WALLET_COUNT',
  SET_AI_WALLET_COUNT: 'SET_AI_WALLET_COUNT',
};

export const resetAIWallet = (payload: object = {}) => ({
  type: types.RESET_AI_WALLET,
  payload,
});

export const getAIWallet = (payload: GetAIWalletQueryParams) => ({
  type: types.GET_AI_WALLET,
  payload,
});

export const setAIWallet = (payload: SetAIWalletPayload) => ({
  type: types.SET_AI_WALLET,
  payload,
});

export const getAIWalletTransactions = (payload: GetAIWalletTransactionsQueryParams) => ({
  type: types.GET_AI_WALLET_TRANSACTIONS,
  payload,
});

export const setAIWalletTransactions = (payload: SetAIWalletTransactionsPayload) => ({
  type: types.SET_AI_WALLET_TRANSACTIONS,
  payload,
});

export const setIsLoading = (payload: IsLoadingPayload) => ({
  type: types.SET_AI_WALLET_IS_LOADING,
  payload,
});

export const getAIWalletCount = (payload: GetAIWalletTransactionsQueryParams) => ({
  type: types.GET_AI_WALLET_COUNT,
  payload,
});

export const setAIWalletCount = (payload: DataPayload<number>) => ({
  type: types.SET_AI_WALLET_COUNT,
  payload,
});
