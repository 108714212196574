import {
  all, call, put, select, takeLatest,
} from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';

import sleep from 'components/utils/mocks/sleep';
import {
  changePassword,
  login,
  logout,
  NoticeType,
  resetPassword,
} from 'packages/authentication_repository';
import {
  clearUserTokens,
  getUserFromLocal,
  removeUserFromLocal,
  saveUserTokens,
  saveUserToLocal,
} from 'packages/local_storage';
import { getServiceProvider } from 'packages/service_repository';
import {
  resetForm,
  resetWorkspace,
  setIsNoticeModalOpen,
  setNoticeModalTemplate,
} from 'redux/actions/workspace'
import routes from 'routes';
import {
  getFullPhotoUrl,
  isProduction,
  identity,
  isEitherAdmin,
  isStartup,
  ROLES,
  isAngelInvestor,
} from 'utils';
import {
  LoginPayload,
  MyProfileType,
  UserRoleType,
  UserType,
} from 'types/auth';
import showNotification from 'services/utils/showNotification';
import { ResetPasswordEmail, ResetPasswordPayload } from 'types/auth/resetPassword';
import { ChangePasswordPayload } from 'types/auth/changePassword';
import { getStartupById } from 'packages/startup_repository';
import { getAngelInvestorById } from 'packages/angel_investors_repository';
import {
  selectMyProfileId,
  selectUser,
  selectUserId,
  selectUserNotice,
  selectUserType,
} from 'redux/selectors/auth';
import { getPartnerById } from 'packages/partner_repository';
import { NoticeModalConfig } from 'types/componentTypes';
import { NOTICE_LEVEL_TO_TEMPLATE_MAP } from 'utils/constants/noticeModal';
import history from 'history_instance';
import { getStartupMemberByUserId, getAngelInvestorMemberByUserId } from 'packages/people_repository';
import { MemberType } from 'types/memberDetails';
import { ResponseType } from 'packages/http_client';
import {
  getHub71ByUserId,
  getPartnerMemberByUserId,
  getSPMemberByUserId,
  getUserById, Hub71Employee,
  PartnerMemberType,
  SPMemberType,
  updateHub71EmployeeById,
  updatePartnerMember,
  updateSPMember,
  updateUserById,
  UserType as UserAuthType,
} from 'packages/user_repository';
import { DataPayload } from 'types/reduxTypes/ActionTypes';
import {
  loginSuccess,
  types,
  setIsLoading,
  setAuthenticationChecked,
  resetAuth,
  setStartup,
  setAngelInvestor,
  setServiceProvider,
  setStartupStatus,
  setAngelInvestorStatus,
  setPartner,
  setMyProfileData,
} from '../../actions/auth';
import { changePhotoInLocalStorage } from '../../utils';
import { resetActivities } from '../../actions/activities';
import { resetCohort } from '../../actions/cohort';
import { resetDashboard } from '../../actions/dashboard';
import { resetEvents } from '../../actions/events';
import { resetMemberDetails } from '../../actions/memberDetails';
import { resetNotification } from '../../actions/notification';
import { resetPartner } from '../../actions/partners';
import { resetPeople } from '../../actions/people';
import { resetAll } from '../../actions/performance';
import { resetRequests } from '../../actions/request';
import { resetSP } from '../../actions/serviceProviders';
import { resetServices, setActiveTab } from '../../actions/services';
import { resetSO } from '../../actions/settingsOverview';
import { resetStartups } from '../../actions/startups';
import { resetUsers } from '../../actions/users';
import { resetWallet } from '../../actions/wallet';

function * checkIsThereNotice() {
  const notice: NoticeType = yield select(selectUserNotice);
  if (!identity.isObjWithChildren(notice)) {
    return;
  }

  const noticeTemplate = NOTICE_LEVEL_TO_TEMPLATE_MAP[notice.level];
  if (!identity.isObjWithChildren(noticeTemplate)) {
    return;
  }

  const data: NoticeModalConfig = {
    ...noticeTemplate,
    text: notice.text,
  }

  yield put(setNoticeModalTemplate({ data }));
  yield put(setIsNoticeModalOpen({ isOpen: true }));
}

function * checkIsUserAuthenticated() {
  const user = getUserFromLocal();
  if (identity.isObjWithChildren(user)) {
    yield put(loginSuccess({ user: user! }));
  }

  yield put(setAuthenticationChecked());
  const userType: UserRoleType = yield select(selectUserType);

  if (!isEitherAdmin(userType)) {
    yield call(handleGetOrganizationInfo);
  } else {
    yield put(setActiveTab({ activeTab: 'service-providers' }));
  }

  if (isStartup(userType)) {
    yield call(checkIsThereNotice); // for angel investor also create checkIsThereNotice
  }
}

function * handleResetPassword(action: PayloadAction<ResetPasswordPayload | ResetPasswordEmail>) {
  yield put(setIsLoading({ isLoading: true }));
  const { error, httpStatus } = yield call(resetPassword, action.payload);

  if (identity.isObjWithChildren(error)) {
    notification.error({
      message: 'Reset Password Error',
      description: error.message,
    });
  } else if (httpStatus === 202) {
    showNotification('Password reset link sent to email');
  } else {
    showNotification('Successfully reset password');
    yield call(sleep, 1000);
    window.location.replace(routes.loginUrl);
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleLogin(action: PayloadAction<LoginPayload>) {
  yield put(setIsLoading({ isLoading: true }));

  const { data, error } = yield call(login, action.payload);

  if (identity.isObjWithChildren(error)) {
    notification.error({
      message: 'Login error',
      description: error.message,
    });
  } else {
    const { accessToken, sessionId, user } = data;
    (user as UserType).photo = getFullPhotoUrl(user?.photo);
    saveUserTokens(accessToken, sessionId);
    saveUserToLocal(user);
    yield call(checkIsUserAuthenticated);
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleLogout() {
  if (isProduction) {
    yield call(logout);
  }

  /* Should invalidate local storage regardless of BE success or failure */
  clearUserTokens();
  removeUserFromLocal();
  yield all([
    put(resetAuth()),
    put(resetActivities()),
    put(resetCohort()),
    put(resetDashboard()),
    put(resetEvents()),
    put(resetMemberDetails()),
    put(resetNotification()),
    put(resetPartner()),
    put(resetPeople()),
    put(resetAll()),
    put(resetRequests()),
    put(resetSP()),
    put(resetServices()),
    put(resetSO()),
    put(resetStartups()),
    put(resetUsers()),
    put(resetWallet()),
    put(resetWorkspace()),
  ]);
  history.push(routes.loginUrl);
}

function * handleChangePassword(action: PayloadAction<ChangePasswordPayload>) {
  yield put(setIsLoading({ isLoading: true }));
  yield put(resetForm({ resetForm: false }));

  const { error } = yield call(changePassword, action.payload);

  yield put(setIsLoading({ isLoading: false }));

  if (identity.isObjWithChildren(error)) {
    notification.error({
      message: 'Change Password Error',
      description: error.message,
    });
  } else {
    yield put(resetForm({ resetForm: true }));
    showNotification('Password Changed successfully');
  }
}

function * getStartupInfo(startupId: number) {
  const { error, httpStatus, data } = yield call(getStartupById, startupId, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setStartup({ data }));
    yield put(setStartupStatus({ data: data?.status?.code }))
  }
}

function * getAngelInvestorInfo(angelInvestorId: number) {
  const { error, httpStatus, data } = yield call(getAngelInvestorById, angelInvestorId, true);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setAngelInvestor({ data }));
    yield put(setAngelInvestorStatus({ data: data?.status?.code }))
  }
}

function * getServiceProviderInfo(serviceProviderId: number) {
  const { error, httpStatus, data } = yield call(getServiceProvider, serviceProviderId, false);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setServiceProvider({ data }));
  }
}

function * getPartnerInfo(partnerId: number) {
  const { error, httpStatus, data } = yield call(getPartnerById, partnerId);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    yield put(setPartner({ data }));
  }
}

function * handleGetOrganizationInfo() {
  const user: UserType = yield select(selectUser);
  const {
    startupId,
    serviceProviderId,
    partnerId,
    angelInvestorId,
  } = user;
  yield put(setIsLoading({ isLoading: true }));
  if (identity.isTruthyNumber(startupId)) {
    yield call(getStartupInfo, startupId);
  } else if (identity.isTruthyNumber(angelInvestorId)) {
    yield call(getAngelInvestorInfo, angelInvestorId!);
  } else if (identity.isTruthyNumber(serviceProviderId)) {
    yield call(getServiceProviderInfo, serviceProviderId!);
  } else if (identity.isTruthyNumber(partnerId)) {
    yield call(getPartnerInfo, partnerId!);
  }
  yield put(setIsLoading({ isLoading: false }));
}

function * handleGetMyProfileData() {
  const userId: number = yield select(selectUserId);
  const userType: UserRoleType = yield select(selectUserType);

  yield put(setIsLoading({ isLoading: true }));

  let response: ResponseType<MyProfileType> = {
    error: { message: '', code: '', status: '' }, data: { id: 0 }, httpStatus: 0,
  };

  switch (userType) {
    case ROLES.startupFounder:
    case ROLES.startupTeamMember: {
      response = yield call(getStartupMemberByUserId, userId);
      break;
    }
    case ROLES.angelInvestor: {
      response = yield call(getAngelInvestorMemberByUserId, userId);
      break;
    }
    case ROLES.partner: {
      response = yield call(getPartnerMemberByUserId, userId);
      break;
    }
    case ROLES.serviceProvider: {
      response = yield call(getSPMemberByUserId, userId);
      break;
    }
    case ROLES.superAdmin: {
      response = yield call(getUserById, userId);
      break;
    }
    case ROLES.admin: {
      response = yield call(getHub71ByUserId, userId);
      break;
    }
    default: {
      break;
    }
  }

  const { data = { id: 0 }, error, httpStatus } = response;

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error?.message}`, true, httpStatus);
  } else {
    yield put(setMyProfileData({ data }));
    const photo = isStartup(userType) || isAngelInvestor(userType)
      ? (data as MemberType)?.imageLink : (data as SPMemberType).photo;
    changePhotoInLocalStorage(photo as string);
    const user = getUserFromLocal();
    yield put(loginSuccess({ user: user! }))
  }

  yield put(setIsLoading({ isLoading: false }));
}

function * handleUpdatePartnerMember(data: PartnerMemberType) {
  const id: number = yield select(selectMyProfileId);
  const { error, httpStatus } = yield call(updatePartnerMember, id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Data successfully updated.');
    yield call(handleGetMyProfileData);
  }
}

function * handleUpdateSPMember(data: SPMemberType) {
  const id: number = yield select(selectMyProfileId);
  const { error, httpStatus } = yield call(updateSPMember, id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Data successfully updated.');
    yield call(handleGetMyProfileData);
  }
}

function * handleUpdateSuperAdmin(data: UserAuthType) {
  const id: number = yield select(selectMyProfileId);
  const { error, httpStatus } = yield call(updateUserById, id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Data successfully updated.');
    yield call(handleGetMyProfileData);
  }
}

function * handleUpdateHub71Admin(data: Hub71Employee) {
  const id: number = yield select(selectMyProfileId);
  const { error, httpStatus } = yield call(updateHub71EmployeeById, id, data);

  if (identity.isObjWithChildren(error)) {
    showNotification(`Something went wrong: ${error.message}`, true, httpStatus);
  } else {
    showNotification('Data successfully updated.');
    yield call(handleGetMyProfileData);
  }
}

function * handleEditMyProfileData(action: PayloadAction<DataPayload<MyProfileType>>) {
  const userType: UserRoleType = yield select(selectUserType);
  const { data } = action.payload;

  yield put(setIsLoading({ isLoading: true }));

  switch (userType) {
    case ROLES.partner: {
      yield call(handleUpdatePartnerMember, data as PartnerMemberType);
      break;
    }
    case ROLES.serviceProvider: {
      yield call(handleUpdateSPMember, data as SPMemberType);
      break;
    }
    case ROLES.superAdmin: {
      yield call(handleUpdateSuperAdmin, data as UserAuthType);
      break;
    }
    case ROLES.admin: {
      yield call(handleUpdateHub71Admin, data as Hub71Employee);
      break;
    }
    default: {
      break;
    }
  }

  yield put(setIsLoading({ isLoading: false }));
}

export default function * authSagas() {
  yield all([
    takeLatest(types.CHECK_IS_USER_AUTHENTICATED, checkIsUserAuthenticated),
    takeLatest(types.GET_RESET_PASSWORD_ACTION, handleResetPassword),
    takeLatest(types.LOGIN, handleLogin),
    takeLatest(types.LOGOUT, handleLogout),
    takeLatest(types.CHANGE_PASSWORD, handleChangePassword),
    takeLatest(types.GET_MY_PROFILE_DATA, handleGetMyProfileData),
    takeLatest(types.EDIT_MY_PROFILE_DATA, handleEditMyProfileData),
  ]);
}
