import httpClient, {
  ApiResponseType,
  GetResourceSuccessResponseType,
  HttpRequestConfig,
  ResponseType,
  UpdateResourceSuccessResponseType,
} from 'packages/http_client';
import { FORM_DATA_CONFIG } from 'packages/http_client/utils';
import {
  CreateTeamMemberApiResponseType,
  CreateTeamMemberPayload,
  GetTeamMembersParams,
  ServiceProviderMemberType,
  TeamMember,
  AngelInvestorMember,
  MemberApiType,
  AngelInvestorMemberApiType,
  EditMemberApiType,
  EditAngelInvestorMemberApiType,
  MemberCount,
  CreateTeamMemberResponse,
  GetInvolvementTypesApiResponseType,
  GetInvolvementTypesListResponse,
  CreateHub71EmployeeApiResponseType,
  CreateHub71EmployeePayload,
  CreateHub71EmployeeResponse,
} from './types';
import {
  applyFilters,
  convertCreateTeamMemberRequestPayload,
  convertCreateAngelInvestorTeamMemberRequestPayload,
  convertGetTeamMemberRequestPayload,
  convertGetAngelInvestorMemberRequestPayload,
  convertServiceProviderMemberRequestPayload,
  convertTeamMemberRequestBody,
  convertAngelInvestorMemberRequestBody,
  convertTeamMembers,
  convertAngelInvestorMembers,
  HUB_71_EMPLOYEE_API,
  INVOLVEMENT_TYPE_API,
  SERVICE_PROVIDER_MEMBER_API,
  STARTUP_TEAM_MEMBER_API,
  ANGEL_INVESTOR_MEMBER_API,
  PARTNER_MEMBER_API,
  convertCreateHub71EmployeeRequestPayload,
  convertGetInvolvementTypesListResponse,
  convertSelectedData,
  convertPartnerMemberRequestPayload,
} from './utils';
import { identity } from '../../utils';
import { PartnerMemberType } from './types/partner_member_type';

// APIS

const createServiceProviderMemberApi = async (serviceProviderId: number, payload: ServiceProviderMemberType) => {
  const url = SERVICE_PROVIDER_MEMBER_API;
  const requestPayload = convertServiceProviderMemberRequestPayload(serviceProviderId, payload);
  return httpClient.post<ApiResponseType<CreateTeamMemberApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const createStartupTeamMemberApi = (payload: CreateTeamMemberPayload) => {
  const url = STARTUP_TEAM_MEMBER_API;
  const requestPayload = convertCreateTeamMemberRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateTeamMemberApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const createAngelInvestorTeamMemberApi = (payload: CreateTeamMemberPayload) => {
  const url = ANGEL_INVESTOR_MEMBER_API;
  const requestPayload = convertCreateAngelInvestorTeamMemberRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateTeamMemberApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const getStartupTeamMembersApi = (params: GetTeamMembersParams) => {
  const url = STARTUP_TEAM_MEMBER_API;
  const config = applyFilters(params, true);
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<TeamMember[]>>>(url, config);
}

const getAngelInvestorMembersApi = (params: GetTeamMembersParams) => {
  const url = ANGEL_INVESTOR_MEMBER_API;
  const config = applyFilters(params, true);
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<TeamMember[]>>>(url, config);
}

const getAngelInvestorMemberByIdApi = (id: number) => {
  const url = `${ANGEL_INVESTOR_MEMBER_API}/${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: '1',
    },
  };
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<AngelInvestorMember>>>(url, config);
}

const getStartupTeamMemberByIdApi = (id: number) => {
  const url = `${STARTUP_TEAM_MEMBER_API}/${id}`;
  const config: HttpRequestConfig = {
    params: {
      $preload: '1',
    },
  };
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<TeamMember>>>(url, config);
}

const putStartupTeamMemberApi = (id: number, body: EditMemberApiType) => {
  const url = `${STARTUP_TEAM_MEMBER_API}/${id}`;
  const requestBody = convertTeamMemberRequestBody(body);
  return httpClient.put<ApiResponseType<UpdateResourceSuccessResponseType>>(url, requestBody, FORM_DATA_CONFIG);
}

const putAngelInvestorMemberApi = (id: number, body: EditAngelInvestorMemberApiType) => {
  const url = `${ANGEL_INVESTOR_MEMBER_API}/${id}`;
  const requestBody = convertAngelInvestorMemberRequestBody(body);
  return httpClient.put<ApiResponseType<UpdateResourceSuccessResponseType>>(url, requestBody, FORM_DATA_CONFIG);
}

const getStartupTeamMemberCountApi = (params: GetTeamMembersParams) => {
  if (identity.isFullArray(params.fieldSelection)) {
    params.fieldSelection!.push({ field: 'id', aggregation: 'count' });
  } else {
    params.fieldSelection = [{ field: 'id', aggregation: 'count' }];
  }
  const config: HttpRequestConfig = applyFilters(params);
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<MemberCount[]>>>(
    STARTUP_TEAM_MEMBER_API,
    config,
  );
}

const getAngelInvestorMemberCountApi = (params: GetTeamMembersParams) => {
  if (identity.isFullArray(params.fieldSelection)) {
    params.fieldSelection!.push({ field: 'id', aggregation: 'count' });
  } else {
    params.fieldSelection = [{ field: 'id', aggregation: 'count' }];
  }
  const config: HttpRequestConfig = applyFilters(params);
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<MemberCount[]>>>(
    ANGEL_INVESTOR_MEMBER_API,
    config,
  );
}

const getInvolvementTypesApi = () => {
  const url = INVOLVEMENT_TYPE_API;
  return httpClient.get<ApiResponseType<GetInvolvementTypesApiResponseType>>(url);
}

const createHub71EmployeeApi = (payload: CreateHub71EmployeePayload) => {
  const url = HUB_71_EMPLOYEE_API;
  const requestPayload = convertCreateHub71EmployeeRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateHub71EmployeeApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const getStartupMemberByUserIdApi = (id: number) => {
  const url = STARTUP_TEAM_MEMBER_API;
  const config = { params: { user_id: id, $preload: '1' } };
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<TeamMember[]>>>(url, config);
}

const getAngelInvestorMemberByUserIdApi = (id: number) => {
  const url = ANGEL_INVESTOR_MEMBER_API;
  const config = { params: { user_id: id, $preload: '1' } };
  return httpClient.get<ApiResponseType<GetResourceSuccessResponseType<AngelInvestorMember[]>>>(url, config);
}

const createPartnerMemberApi = async (partnerId: number, payload: PartnerMemberType) => {
  const url = PARTNER_MEMBER_API;
  const requestPayload = convertPartnerMemberRequestPayload(partnerId, payload);
  return httpClient.post<ApiResponseType<CreateTeamMemberApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

// SERVICES

export const createHub71Employee = async (payload: CreateHub71EmployeePayload): Promise<
CreateHub71EmployeeResponse> => {
  const response = await createHub71EmployeeApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
}

export const createStartupTeamMember = async (payload: CreateTeamMemberPayload): Promise<CreateTeamMemberResponse> => {
  const response = await createStartupTeamMemberApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
}

export const createAngelInvestorTeamMember = async (
  payload: CreateTeamMemberPayload,
): Promise<CreateTeamMemberResponse> => {
  const response = await createAngelInvestorTeamMemberApi(payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
}

export const getStartupTeamMembers = async (params: GetTeamMembersParams):
  Promise<ResponseType<MemberApiType[]>> => {
  const response = await getStartupTeamMembersApi(params);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertTeamMembers(data) };
}

export const getAngelInvestorMembers = async (params: GetTeamMembersParams):
  Promise<ResponseType<AngelInvestorMemberApiType[]>> => {
  const response = await getAngelInvestorMembersApi(params);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertAngelInvestorMembers(data) };
}

export const getAngelInvestorMemberById = async (id: number): Promise<ResponseType<AngelInvestorMemberApiType>> => {
  const response = await getAngelInvestorMemberByIdApi(id);
  const { data: member, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAngelInvestorMemberRequestPayload(member) };
}

export const getStartupTeamMemberById = async (id: number): Promise<ResponseType<MemberApiType>> => {
  const response = await getStartupTeamMemberByIdApi(id);
  const { data: member, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetTeamMemberRequestPayload(member) };
}

export const putStartupTeamMember = async (
  id: number,
  body: EditMemberApiType,
) => await putStartupTeamMemberApi(id, body);

export const putAngelInvestorMember = async (
  id: number,
  body: EditAngelInvestorMemberApiType,
) => await putAngelInvestorMemberApi(id, body);

export const createServiceProviderMember = async (serviceProviderId: number, payload: ServiceProviderMemberType)
  : Promise<ResponseType<number[]>> => {
  const response = await createServiceProviderMemberApi(serviceProviderId, payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
}

export const getStartupTeamMemberCount = async (params: GetTeamMembersParams = {})
  : Promise<ResponseType<MemberCount[]>> => {
  const response = await getStartupTeamMemberCountApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.result! };
}

export const getAngelInvestorMemberCount = async (params: GetTeamMembersParams = {})
  : Promise<ResponseType<MemberCount[]>> => {
  const response = await getAngelInvestorMemberCountApi(params);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.result! };
}

export const getInvolvementTypesList = async (): Promise<GetInvolvementTypesListResponse> => {
  const response = await getInvolvementTypesApi();
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertGetInvolvementTypesListResponse(data) };
}

export const getStartupTeamMemberSelectedData = async (params: GetTeamMembersParams) => {
  const response = await getStartupTeamMembersApi(params);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertSelectedData(params.fieldSelection![0] as string, data) };
}

export const getStartupMemberByUserId = async (id: number): Promise<ResponseType<MemberApiType>> => {
  const response = await getStartupMemberByUserIdApi(id);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertTeamMembers(data)[0] }
}

export const getAngelInvestorMemberByUserId = async (id: number): Promise<ResponseType<AngelInvestorMemberApiType>> => {
  const response = await getAngelInvestorMemberByUserIdApi(id);
  const { error, data, httpStatus } = response;
  return { error, httpStatus, data: convertAngelInvestorMembers(data)[0] }
}

export const createPartnerMember = async (partnerId: number, payload: PartnerMemberType)
  : Promise<ResponseType<number[]>> => {
  const response = await createPartnerMemberApi(partnerId, payload);
  const { error, httpStatus, data } = response;
  return { error, httpStatus, data: data?.id };
};
