import React from 'react';

import { identity } from 'utils';
import { CardInfoRowProps } from 'types/componentTypes';
import CardInfoRowContent from '../CardInfoRowContent';
import CardArrayRowContent from '../CardArrayRowContent';
import { getServicePriceText } from './utils/helpers';
import { NOT_SPECIFIED } from '../../../../CohortDetails/utils/constants';

const CardInfoRow = (props: CardInfoRowProps) => {
  const { infoRowKey, data } = props;

  switch (infoRowKey) {
    case 'name':
    case 'imageLink':
    case 'founderEmail':
    case 'nationality':
    case 'phoneNumber':
    case 'about':
    case 'provider':
    case 'serviceProvider': {
      return null;
    }
    case 'cohort': {
      return <CardInfoRowContent title={infoRowKey} description={data.cohort.name} />;
    }
    case 'skills': {
      return (
        <CardInfoRowContent
          isRounded
          title={infoRowKey}
          description={identity.isFullArray(data.skills) ? data.skills[0] : NOT_SPECIFIED}
        />
      );
    }
    case 'primarySector': {
      return (
        <CardInfoRowContent
          isRounded
          title={infoRowKey}
          description={identity.isTruthyString(data.name) ? data.primarySector.name : NOT_SPECIFIED}
        />
      );
    }
    case 'secondarySector': {
      return (
        <CardInfoRowContent
          isRounded
          title={infoRowKey}
          description={identity.isTruthyString(data.name) ? data.secondarySector.name : NOT_SPECIFIED}
        />
      );
    }
    case 'category':
    case 'type':
    case 'status': {
      return (
        <CardInfoRowContent
          isRounded
          title={infoRowKey}
          description={identity.isEmptyString(data[`${infoRowKey}`])
            ? NOT_SPECIFIED : data[`${infoRowKey}`]}
        />
      );
    }
    case 'categories': {
      return (
        <CardArrayRowContent
          isRounded
          title={infoRowKey}
          description=""
          data={data[`${infoRowKey}`]}
        />
      );
    }
    case 'offerPrice': {
      return (
        <CardInfoRowContent
          title="Price"
          description={getServicePriceText(data)}
        />
      );
    }
    case 'peopleTableName': {
      return (
        <CardInfoRowContent
          description={(identity.isTruthyString(data.firstName) || identity.isTruthyString(data.lastName)) ? `${data.firstName} ${data.lastName}` : NOT_SPECIFIED}
          title="Name"
        />
      )
    }
    case 'peopleTableStartup': {
      return (
        <CardInfoRowContent
          description={data.company || NOT_SPECIFIED}
          title="Startup"
        />
      )
    }
    case 'peopleTableAngelInvestor': {
      return (
        <CardInfoRowContent
          description={data.company || NOT_SPECIFIED}
          title="Angel Investor"
        />
      )
    }
    default: {
      return (
        <CardInfoRowContent
          title={infoRowKey as string}
          description={identity.isEmptyString(data[`${infoRowKey}`])
            ? NOT_SPECIFIED : data[`${infoRowKey}`]}
        />
      );
    }
  }
}

export default CardInfoRow;
