import { CohortType } from '../../../../types/cohorts/cohortOverview';
import { FieldType } from '../../../../types/reduxTypes/ActionTypes/CohortTypes';

export const types = {
  SET_FIELD: 'SET_FIELD',
  SET_ALL: 'SET_ALL',
}

export type SetAllType = {
  cohort: CohortType,
};

export type IncentiveTemplateValueLabel = {
  value: number,
  label: string,
}

export type SetFieldType = {
  field: FieldType,
  value: string,
}

export const setAll = (payload: SetAllType) => ({
  type: types.SET_ALL,
  payload,
});

export const setField = (payload: SetFieldType) => ({
  type: types.SET_FIELD,
  payload,
});
