import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

import { identity } from 'utils';
import { Input } from 'components/components';
import { ADGMLicenseDetailsDrawerProps } from 'types/angelInvestors/editAngelInvestor';
import { NOT_SPECIFIED } from '../../../CohortDetails/utils/constants';
import { formatSlashMDY } from '../../../utils/constants/formatters';
import { getAngelInvestorFile } from '../../utils';
import { DetailsDrawerRow, AIADGMLicenseUploadForm } from '..';

import './ADGMLicenseDetailsDrawer.scss';

const AIADGMLicenseDetailsDrawer = (props: ADGMLicenseDetailsDrawerProps) => {
  const { adgm, userAngelInvestorId, id } = props;
  const isCreateOrEditMode = useMemo(() => !identity.isTruthyString(adgm?.aDGMLicenseUrl)
    || (identity.isZero(adgm?.approvedRejectedStatus) && identity.isTruthyString(adgm?.approvedRejectedDate)), [adgm])

  const angelInvestorId = useMemo(
    () => (identity.isTruthyNumber(userAngelInvestorId) ? userAngelInvestorId : id),
    [id, userAngelInvestorId],
  );

  useEffect(() => {
    props.actions.getADGMLicenseByAngelInvestorId({ id: angelInvestorId });
  }, [angelInvestorId]);

  if (isCreateOrEditMode) {
    return <AIADGMLicenseUploadForm adgm={adgm} angelInvestorId={angelInvestorId} />;
  }

  return (
    <div className="adgm-license-details-drawer">
      <DetailsDrawerRow label="ADGM License No." item={adgm.aDGMLicenseNo || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Name" item={adgm.legalADGMName || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Name" item={adgm.legalSignatoryName || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Title" item={adgm.legalSignatoryTitle || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Legal Signatory Email" item={adgm.legalSignatoryEmail || NOT_SPECIFIED} />
      <DetailsDrawerRow label="Registered WeWork Address" item={adgm.address || NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Issue Date" item={identity.isTruthyString(adgm.issueDate) ? moment(adgm.issueDate).format(formatSlashMDY) : NOT_SPECIFIED} />
      <DetailsDrawerRow label="ADGM Expiry Date" item={identity.isTruthyString(adgm.expiryDate) ? moment(adgm.expiryDate).format(formatSlashMDY) : NOT_SPECIFIED} />
      <Input.DraggerUpload
        previewType="image"
        defaultFileList={[...getAngelInvestorFile(adgm?.aDGMLicenseUrl!)]}
        isRoundableButton
      />
    </div>
  );
}

export default AIADGMLicenseDetailsDrawer;
