import { createSelector } from 'reselect';

import { StateType } from 'types';
import { AngelInvestorsStateType } from 'types/reduxTypes/StateTypes';
import { AngelInvestorType } from 'types/angelInvestors';
import { RequestType } from 'types/requests';
import routes from 'routes';
import { isProduction } from 'utils';

const selectAngelInvestorsState = (state: StateType) => state.angelInvestorsReducer;

export const selectIncentiveAgreement = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.incentiveAgreement,
)

export const selectAngelInvestors = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.angelInvestors,
);

export const selectAngelInvestorCount = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.angelInvestorCount,
);

export const selectAngelInvestorDetails = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.details.data,
);

export const selectAngelInvestorRequests = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.details.requests,
)

export const selectAngelInvestorDetailsRequests = createSelector(
  selectAngelInvestorRequests,
  (state: RequestType[]) => state.slice(0, 3),
);

export const selectAngelInvestorId = createSelector(
  selectAngelInvestorDetails,
  (state: AngelInvestorType) => state.id,
);

export const selectAboutAngelInvestor = createSelector(
  selectAngelInvestorDetails,
  (state: AngelInvestorType) => state.about,
);

export const selectAngelInvestorName = createSelector(
  selectAngelInvestorDetails,
  (state: AngelInvestorType) => state.name,
);

export const selectStatCards = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => {
    const stats = [
      { title: 'service providers', count: state.details.serviceProvidersCount || 0, path: routes.servicesAndSpsOverviewUrl },
    ];

    if (!isProduction) {
      stats.push({ title: 'upcoming events', count: state.details.data.upcomingEventsCount || 0, path: routes.eventsUrl });
    }

    return stats;
  },
);

export const selectPartnersStatCards = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => {
    const stats = [
      { title: 'founders', count: state.details.teamMembers.filter((member) => member.founder)?.length, path: '' },
    ];

    if (!isProduction) {
      stats.push({ title: 'upcoming events', count: state.details.data?.upcomingEventsCount || 0, path: routes.eventsUrl });
    }

    return stats;
  },
);

export const selectIncentiveAgreementStatus = createSelector(
  selectAngelInvestorDetails,
  (state: AngelInvestorType) => state.incentiveAgreementApprovedStatus,
);

export const selectAngelInvestorTeamMembers = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.details.teamMembers.filter(
    (member: any) => member.company === state.details.data.name,
  ),
);

export const selectAngelInvestorServiceProviders = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.details.serviceProviders,
);

export const selectAngelInvestorLocations = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.locations,
);

export const selectStageOptions = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.stageOptions,
);

export const selectInvestmentStageOptions = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.investmentStageOptions,
);

export const selectIsLoading = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.isLoading,
);

export const selectAngelInvestorTeamMembersCount = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.details.teamMembersCount,
);

export const selectAdgmLicenseByAngelInvestorId = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.adgmLicenseByAngelInvestorId,
);

export const selectSelectedAngelInvestorId = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.angelInvestorId!,
);

export const selectAngelInvestorForReview = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.selectedAngelInvestorForReview,
);

export const selectAngelInvestorFilters = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.filters,
);

export const selectAngelInvestorFiltersActive = createSelector(
  selectAngelInvestorsState,
  (state: AngelInvestorsStateType) => state.filtersActive,
);
