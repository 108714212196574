import { combineReducers } from 'redux';

import workspaceReducer from './workspace';
import authReducer from './auth';
import cohortReducer from './cohort';
import startupsReducer from './startups';
import settingsOverviewReducer from './settingsOverview';
import activitiesReducer from './activities';
import membersReducer from './memberDetails';
import angelInvestorMembersReducer from './angelInvestorMemberDetails';
import peopleReducer from './people';
import requestReducer from './request';
import serviceProvidersReducer from './serviceProviders';
import walletReducer from './wallet';
import aiWalletReducer from './ai_wallet';
import servicesReducer from './services';
import eventsReducer from './events';
import partnersReducer from './partners';
import dashboardReducer from './dashboard';
import notificationsReducer from './notifications';
import performanceReducer from './performance';
import userReducer from './users';
import angelInvestorsReducer from './angelInvestors';

export default combineReducers({
  workspaceReducer,
  authReducer,
  cohortReducer,
  startupsReducer,
  settingsOverviewReducer,
  activitiesReducer,
  membersReducer,
  angelInvestorMembersReducer,
  peopleReducer,
  requestReducer,
  serviceProvidersReducer,
  walletReducer,
  aiWalletReducer,
  servicesReducer,
  eventsReducer,
  partnersReducer,
  dashboardReducer,
  notificationsReducer,
  performanceReducer,
  userReducer,
  angelInvestorsReducer,
});
