import React from 'react';
import classnames from 'classnames';

import { AmountProps } from 'types/angelInvestors';

import './Amount.scss';

const Amount = (props: AmountProps) => {
  const { name, amount } = props;

  return (
    <div className={classnames('amount', name)}>
      <div className="amount__container">
        <h1 className="amount__value">{amount}</h1>
        <p className="amount__name">{`amount ${name}`}</p>
      </div>
    </div>
  );
}

export default Amount;
