import React from 'react';
import { Redirect, Route } from 'react-router';

import { isStartupDataMissing } from 'utils/helpers/checkStartupStatus';
import { isAngelInvestorDataMissing } from 'utils/helpers/checkAngelInvestorStatus';
import PrivateRouteProps from 'types/routes/PrivateRouteProps';
import routes from 'routes';
import { isStartup, isAngelInvestor } from '../../../../utils';
import StartupRoute from '../StartupRoute';
import AngelInvestorRoute from '../AngelInvestorRoute';

export const PrivateRoute = (props: PrivateRouteProps) => {
  const {
    userType,
    isAuthenticationChecked,
    permittedUserTypes,
    path,
    exact,
    component,
    isAuthenticated,
    startup,
    angelInvestor,
  } = props;

  if (!isAuthenticationChecked) {
    return null;
  }

  if (isStartup(userType) && isStartupDataMissing(startup!)) {
    return <Redirect to={routes.addMissingDetailsUrl} />
  }

  if (isAngelInvestor(userType) && isAngelInvestorDataMissing(angelInvestor!)) {
    return <Redirect to={routes.addMissingDetailsUrl} />
  }

  if (isStartup(userType) && permittedUserTypes.includes(userType)) {
    return <StartupRoute path={path} exact={exact} component={component} />
  }

  if (isAngelInvestor(userType) && permittedUserTypes.includes(userType)) {
    return <AngelInvestorRoute path={path} exact={exact} component={component} />
  }

  if (!isAuthenticated || !permittedUserTypes.includes(userType)) {
    return <Redirect to={routes.loginUrl} />;
  }

  return <Route path={path} exact={exact} component={component} />
};

PrivateRoute.defaultProps = {
  permittedUserTypes: [],
};

export default PrivateRoute;
