import { AxiosRequestConfig } from 'axios';

import httpClient, { ApiResponseType, FORM_DATA_CONFIG, queryBuilder } from '../../http_client';
import {
  ADGMLicenseType,
  CreateADGMLicenseApiResponseType,
  CreateADGMLicensePayload,
  ResponseADGMLicenseType,
  UpdateAdgmLicenseByAngelInvestorIdApiResponseType,
} from '../types';
import {
  convertCreateADMGLicenseRequestPayload,
  convertCreateAngelInvestorApiId,
  convertEditAdgmLicenseByAngelInvestorIdRequestPayload,
  convertGetAdGMLicenseResponse,
  ANGELINVESTOR_ADGM_LICENSE,
} from '../utils';

const createAngelInvestorADGMLicenseApi = (payload: CreateADGMLicensePayload) => {
  const url = ANGELINVESTOR_ADGM_LICENSE;
  const requestPayload = convertCreateADMGLicenseRequestPayload(payload);
  return httpClient.post<ApiResponseType<CreateADGMLicenseApiResponseType>>(url, requestPayload, FORM_DATA_CONFIG);
}

const getAngelInvestorADGMLicenseByAngelInvestorIdApi = (id: number, isPreload: boolean) => {
  const url = `${ANGELINVESTOR_ADGM_LICENSE}`;
  const params = queryBuilder({ angel_investor_id: id });
  if (isPreload) {
    params.preload();
  }
  const config: AxiosRequestConfig = { params: params.toObject() };
  return httpClient.get<ApiResponseType<ResponseADGMLicenseType>>(url, config);
}

const updateAdgmLicenseByAngelInvestorIdApi = (id: number, payload: ADGMLicenseType) => {
  const url = `${ANGELINVESTOR_ADGM_LICENSE}/${id}`;
  const requestPayload = convertEditAdgmLicenseByAngelInvestorIdRequestPayload(payload);
  return httpClient.put<ApiResponseType<UpdateAdgmLicenseByAngelInvestorIdApiResponseType>>(url, requestPayload);
};

export const updateAdgmLicenseByAngelInvestorId = async (id: number, payload: ADGMLicenseType) => {
  const response = await updateAdgmLicenseByAngelInvestorIdApi(id, payload);
  const { error, httpStatus } = response;
  return { error, httpStatus };
};

export const getAngelInvestorADGMLicenseByAngelInvestorId = async (id: number, isPreload: boolean): Promise<
  ApiResponseType<ADGMLicenseType>> => {
  const response = await getAngelInvestorADGMLicenseByAngelInvestorIdApi(id, isPreload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertGetAdGMLicenseResponse(data?.result![0]) };
};

export const createAngelInvestorADGMLicense = async (payload: CreateADGMLicensePayload) => {
  const response = await createAngelInvestorADGMLicenseApi(payload);
  const { data, error, httpStatus } = response;
  return { error, httpStatus, data: convertCreateAngelInvestorApiId(data?.id) };
}
