import { ADGMLicenseType, AngelInvestorType } from 'packages/angel_investors_repository';
import { DataPayload } from '../../../../types/reduxTypes/ActionTypes';

export const types = {
  SET_FIELD: 'SET_FIELD',
  SET_ALL: 'SET_ALL',
  SET_ADGM_FIELD: 'SET_ADGM_FIELD',
  SET_ADGM_ALL: 'SET_ADGM_ALL',
  SET_ACCEPTANCE_LETTER: 'SET_ACCEPTANCE_LETTER',
  SET_HAS_LICENSE: 'SET_HAS_LICENSE',
  SET_FILE_NAME: 'SET_FILE_NAME',
}

export type SetADGMFieldType = { field: keyof ADGMLicenseType, data: string | Blob }

export type SetFieldType = { field: keyof Omit<AngelInvestorType, 'id'>, data: string | Blob | number }

export const setAll = (payload: DataPayload<AngelInvestorType>) => ({
  type: types.SET_ALL,
  payload,
});

export const setField = (payload: SetFieldType) => ({
  type: types.SET_FIELD,
  payload,
});

export const setHasLicense = (payload: DataPayload<boolean>) => ({
  type: types.SET_HAS_LICENSE,
  payload,
});

export const setFileName = (payload: DataPayload<string>) => ({
  type: types.SET_FILE_NAME,
  payload,
});

export const setADGMAll = (payload: DataPayload<ADGMLicenseType>) => ({
  type: types.SET_ADGM_ALL,
  payload,
});

export const setADGMField = (payload: SetADGMFieldType) => ({
  type: types.SET_ADGM_FIELD,
  payload,
});
