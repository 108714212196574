import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Spin } from 'antd';
import routes from 'routes';
import { composeEditAngelInvestorUrl } from 'routes/routeComposers';
import StaticNotification from 'components/components/Notification/StaticNotification/View';
import {
  getAngelInvestorById as getStatusAngelInvestor,
  AngelInvestorStatusType,
  AngelInvestorType,
} from 'packages/angel_investors_repository';
import {
  AboutAI,
  Breadcrumb,
  RenderIf,
  // Requests,
  AngelInvestorWallet,
  ExpandableTable,
  TitleWithActions,
  ProfileOverview,
} from 'components/components';
import {
  identity,
  getRowKey,
  isPartner,
  getImageLink,
  isEitherAdmin,
  isAngelInvestor,
  isExpired,
} from 'utils';
import { AngelInvestorDetailsProps } from 'types/angelInvestors/angelInvestorDetails';
import { NOT_SPECIFIED } from 'components/CohortDetails/utils/constants';
import { startupTeamMembersColumns } from '../utils/constants/columns';

import './AIDetails.scss';

const AngelInvestorDetails = (props: AngelInvestorDetailsProps) => {
  const {
    aboutAngelInvestor,
    info,
    teamMembers,
    // requests,
    userType,
    isLoading,
    userAngelInvestorId,
  } = props;
  const {
    getAngelInvestorById,
    getAngelInvestorMembers,
    getRequestsByOwnerId,
  } = props.actions

  const params: { id?: string } = useParams();
  const { id } = params;

  const [angelInvestorStatus, setAngelInvestorStatus] = useState<AngelInvestorStatusType>();
  const [angelInvestorDetails, setAngelInvestorDetails] = useState<AngelInvestorType>();

  const {
    facebook,
    instagram,
    twitter,
    linkedIn,
    id: sId,
    name,
    imageLink,
    location,
    wallet,
  } = info;

  const rightContent = useMemo(() => ([
    {
      label: 'industry',
      value: 'Investors',
    },
    {
      label: 'Primary Email',
      value: identity.isTruthyString(teamMembers[0]?.email) ? teamMembers[0]?.email : NOT_SPECIFIED,
    },
    { label: 'employees', value: teamMembers?.length },
  ]), [teamMembers]);

  const socialMediaLinks = useMemo(() => ({
    facebook, instagram, twitter, linkedIn,
  }), [facebook, instagram, twitter, linkedIn]);

  const angelInvestorId = useMemo(
    () => (identity.isTruthyNumber(userAngelInvestorId) ? userAngelInvestorId : parseInt(id!, 10)),
    [id],
  );

  useEffect(() => {
    async function getAngelInvestorDetails() {
      if (identity.isTruthyNumber(angelInvestorId)) {
        const res = await getStatusAngelInvestor(angelInvestorId!, true);
        setAngelInvestorStatus(res?.data?.status)
        setAngelInvestorDetails(res?.data)
      }
    }
    getAngelInvestorDetails()

    if (identity.isTruthyNumber(angelInvestorId)) {
      getAngelInvestorById({ id: angelInvestorId, owner: 'angel-investor', isUpdate: true });
      getAngelInvestorMembers({ id: angelInvestorId });
      getRequestsByOwnerId({ id: angelInvestorId, owner: 'angel-investor' });
    }
  }, [angelInvestorId]);

  return (
    <div className="content-container">
      <Spin spinning={isLoading}>
        <div className="angelinvestor-details">
          <RenderIf condition={angelInvestorId !== userAngelInvestorId}>
            <Breadcrumb navigateTo={routes.angelInvestorsUrl} routeName="Angel Investors" />
          </RenderIf>
          <RenderIf condition={isExpired(angelInvestorStatus?.code!)}>
            <StaticNotification
              message="The incentives period for this Angel investor has ended and they no longer can apply for any incentives."
              type="warning"
            />
          </RenderIf>
          <TitleWithActions
            title="Angel Investor overview"
            buttonTitle="profile"
            link={
              angelInvestorId === userAngelInvestorId
                ? routes.editCompanyProfileUrl
                : composeEditAngelInvestorUrl(sId)
            }
            isEditButton
            showButton={
              isEitherAdmin(userType)
              || (isAngelInvestor(userType) && angelInvestorId === userAngelInvestorId)
            }
          />
          <ProfileOverview
            heading={name}
            imageUrl={getImageLink(imageLink!)}
            subheadingIsLocation={identity.isTruthyString(location)}
            subheading={location}
            rightContent={rightContent}
            socialMediaLinks={socialMediaLinks}
          />
          <RenderIf condition={identity.isObjWithChildren(wallet) && isEitherAdmin(userType)}>
            <AngelInvestorWallet
              wallet={wallet}
              includeGraph
              showInfo
              showTransactionButton
              angelInvestorId={angelInvestorId!}
            />
          </RenderIf>
          <RenderIf condition={!isPartner(userType)}>
            <AboutAI
              title={`About ${name}`}
              isAngelInvestor={!isPartner(userType)}
              description={aboutAngelInvestor}
              angelInvestorDetails={angelInvestorDetails}
            />
          </RenderIf>
          <RenderIf condition={isPartner(userType)}>
            <AboutAI title={`About ${name}`} description={aboutAngelInvestor} />
          </RenderIf>
          {/* <RenderIf condition={isEitherAdmin(userType)}>
            <Requests component="startup" requests={angelInvestorRequests} />
          </RenderIf> */}
          <ExpandableTable
            collapseHeader={`Team Members (${teamMembers.length})`}
            dataSource={teamMembers}
            columns={startupTeamMembersColumns}
            rowKey={getRowKey(teamMembers, 'angelinvestor-details-team-member', 'id')}
          />
        </div>
      </Spin>
    </div>
  );
}

export default AngelInvestorDetails;
