import { connect } from 'react-redux';
import { bindActionCreators, Dispatch, Action } from 'redux';

import {
  selectAdgmLicenseByAngelInvestorId,
  selectStageOptions,
  selectIsLoading,
} from 'redux/selectors/angelInvestors';
import {
  selectIsAuthenticated,
  selectAngelInvestorStatus,
  selectUserAngelInvestor,
  selectUserAngelInvestorId,
  selectUserType,
} from 'redux/selectors/auth';
import { StateType } from 'types';
import {
  getAngelInvestorById,
  updateAngelInvestorById,
  getAngelInvestorStages,
  getADGMLicenseByAngelInvestorId,
  updateADGMLicenseByAngelInvestorId,
  createAngelInvestorADGMLicense,
} from 'redux/actions/angelInvestors';

const mapStateToProps = (state: StateType) => ({
  details: selectUserAngelInvestor(state),
  angelInvestorId: selectUserAngelInvestorId(state),
  stageOptions: selectStageOptions(state),
  adgmLicenseByAngelInvestorId: selectAdgmLicenseByAngelInvestorId(state),
  isLoading: selectIsLoading(state),
  userType: selectUserType(state),
  isAuthenticated: selectIsAuthenticated(state),
  angelInvestorStatus: selectAngelInvestorStatus(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  actions: bindActionCreators({
    getAngelInvestorById,
    getAngelInvestorStages,
    updateAngelInvestorById,
    createAngelInvestorADGMLicense,
    getADGMLicenseByAngelInvestorId,
    updateADGMLicenseByAngelInvestorId,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
